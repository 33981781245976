import React from 'react';
import Pill from './Pill';

const PillPresenter = ({
  rows,
  selection,
  caption,
  handleDelete,
  colourField,
  pickerName,
  defaultPillColour
}) => {
  let pills = [];

  if (rows != null) {
    pills = selection
      .map((item, index) => {
        const pillItem = rows.find((row) => row.id === item);
        if (!pillItem) return false;

        const displayValue = `${caption ? `${caption}: ` : ''}${pillItem.name}`;

        let colour = (defaultPillColour ? defaultPillColour : 'pink');
        if (colourField && pillItem[colourField]) {
          colour = pillItem[colourField];
        }
        const classes = `bg-${colour} d-flex float-left mt-1`;

        return (
          <Pill
            onDelete={handleDelete}
            key={index}
            id={pillItem.id}
            caption={displayValue}
            classes={classes}
            pickerName={pickerName}
          />
        );
      })
      .filter(Boolean);
  }

  return <>{pills}</>;
};

export default PillPresenter;

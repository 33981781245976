import { useState } from 'react';
import { Collapse, Row, Col } from 'reactstrap';
// import moment from 'moment';

const TrackingVehicleRow = (props) => {
  const {
    renderRows,
    headers,
    row,
    onClick,
    // toggleTails,
    // vehiclesWithTails,
    selectedVehicle,
    settings
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  if (selectedVehicle === row.id && isCollapsed === true) {
    setIsCollapsed(false);
  }

  let vehicleType;
  if (row.type) {
    vehicleType = row.type.name;
  }

  let lastCollected;
  let velocity = '-';
  let heading = '-';
  let elevator = '-';
  let imei = '-';
  let status = 'idle';
  let external_voltage = '0v';

  if (row?.status) {
    status = row?.status?.name;
  }

  imei = row?.logger?.imei;

  if (row.last_track) {
    lastCollected = row.last_track.created;

    if (row.last_track.attributes) {
      velocity = `${row.last_track.attributes.speed} km/h`;
      heading = row.last_track.attributes.direction;
      elevator = (parseFloat(row.last_track.attributes.analog-3) < 1 ? 'Down/Cutting' : 'Raised');
      external_voltage = `${row.last_track.attributes['external-analog']}v`;
    }
  };

  // Temp place holder
  let group_name = '-';
  let inspector = '-';

  if(row.group) {
    group_name = row.group.name;

    if (row.group?.inspector_assoc && row.group.inspector_assoc?.inspector) {
      inspector = `${row.group.inspector_assoc.inspector.firstname} ${row.group.inspector_assoc.inspector.lastname}`;
    }
  }

  // const showTrack = (vehiclesWithTails.includes(row.id));

  const hours = (settings.tailMinutes / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  // const showTrackLabel = `Yes - ${rhours}h ${rminutes}m track tail`;

  // Placeholder attributes
  const attributes = [
    {name: 'Vehicle Name', value: row?.name},
    {name: 'Vehicle Type', value: vehicleType},
    {name: 'Harvest Group', value: group_name},
    {name: 'Speed', value: velocity},
    {name: 'Heading', value: heading},
    {name: 'Elevator', value: elevator},
    {name: 'Cane Inspector', value: inspector},
    {name: 'Status', value: status},
    {name: 'External Voltage', value: external_voltage},
    {name: 'IMEI', value: imei},
    {name: 'Last Seen', value: lastCollected},
    // {name: 'Show Track History', value: (showTrack ? showTrackLabel : 'No (Click to toggle)'), onClick: () => toggleTails(row.id), colour: (showTrack ? 'green' : 'red')}
  ];

  const attributesRows = attributes.map((attribute, index) => 
    <Row key={index} className="m-0">
      <Col className="p-1 border-bottom border-corporate bg-lightgray"><small>{attribute.name}</small></Col>
      <Col onClick={attribute.onClick} className="p-1 border-bottom border-corporate" style={{color: attribute.colour}}><small>{attribute.value}</small></Col>
    </Row>
  );

  const selectedClass = (isCollapsed ? '' : 'border-left border-corporate');

  return (
    <>
      <tr onClick={() => {setIsCollapsed(!isCollapsed); onClick(row);}} style={{cursor: 'pointer'}} className={selectedClass}>
        {renderRows(headers, row)}
      </tr>
      {!isCollapsed && (
        <tr className="bg-light text-black">
          <td colSpan={headers.length} className="p-0 " style={{borderTop: '3px solid green'}}>
            <Collapse isOpen={!isCollapsed}>
              {attributesRows}
            </Collapse>
          </td>
      </tr>
      )}
    </>
  );
};

export default TrackingVehicleRow;

import { Button } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

const SeasonsToolbar = (props) => {
  const {
    onFilter
  } = props;

  return (
    <div className="bg-light rounded p-1 m-2 d-flex justify-content-end border border-lightgray">
      <Button disabled size="sm" color="success ml-2">Clone Season</Button>
      <Button disabled size="sm" color="pink ml-2">Export Season</Button>
    </div>
  );
};

export default SeasonsToolbar;

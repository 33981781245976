import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormInputSelect from '../../../core/form/components/FormInputSelect';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls as orgControls } from '../forms/org';

import Pill from 'jsx/components/core/form/components/Pill';
import PillPicker from 'jsx/components/core/form/components/PillPicker';

import {
   fetchOrg,
   updateOrg,
   createOrg,
   fetchOrgs,
   fetchOrgCategories
} from '../../../manage/actions';

class OrgModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Org',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(orgControls),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleRoleDelete = this.handleRoleDelete.bind(this);
  }

  async componentDidMount() {
    await this.props.dispatch(fetchOrgCategories());
  }

  async componentDidUpdate() {
    const { fetching, mills } = this.props.season;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Organisation';
      controls = initControls(cloneDeep(orgControls));

      id = null;
      data = {};

      // Load combos
      controls.parent_id.options = [{id: null, name: 'None Selected'}].concat(mills.map(mill => ({id: mill.id, name: mill.name})));

      // load row if exists
      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Organisation';

        data = await this.props.dispatch(fetchOrg(id));
        controls = updateControls(controls, data);
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      });
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;
    const formData = saveControls(controls, data);

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createOrg(formData));
    } else {
      success = await this.props.dispatch(updateOrg(formData));
    }

    if (success) {
      this.props.dispatch(fetchOrgs(this.props.params));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      'Removing Organisation permanently. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
    //   const success = await this.props.dispatch(removeOrg(data.id));
      if (success) {
        this. props.dispatch(fetchOrgs(this.props.params));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  async handleRoleChange(event) {
    const {
      data
    } = this.state;

    console.log("event", event.target.value, data.categories);

    const exists = data.categories.find(category => category.category_id === event.target.value);
    if (!exists) {
      data.categories.push(event.target.value);
    }

    this.setState({data});
  }

  async handleRoleDelete(id) {
    const {
      data
    } = this.state;

    const idx = data.categories.findIndex(fid => fid === id)
    data.categories.splice(idx, 1);

    this.setState({data});
  }

  render() {
    const { controls, isOpen, title, isNew, data } = this.state;
    const { responseMessage, orgCategories } = this.props.manage;

    const iconName = 'leaf';

    const categoryIds = data?.categories?.map(category => category.category_id) || [];

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'parent_id'}  })
              }
              control={controls.parent_id}
            />
            <FormInput handleChange={this.handleChange} control={controls.name} />
          </Form>

          {/* <div className="mt-2">
            <PillPicker
              caption="Org Categories"
              name="category_id"
              handleChange={this.handleRoleChange}
              handleDelete={this.handleRoleDelete}
              rows={orgCategories}
              selection={categoryIds}
              showPills
              defaultPillColour="primary"
            />
          </div> */}

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" disabled onClick={this.onRemove} >Delete</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season, manage }) => ({
  vehicles,
  loggers,
  season,
  manage
});

export default connect(mapStoreToProps)(OrgModal);

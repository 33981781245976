/* eslint-disable id-length */
import Home from '../containers/Home';
import Dashboard from '../containers/Dashboard';
import User from '../components/manage/containers/User';
import Invite from '../components/core/authentication/containers/Invite';
import Settings from '../components/manage/containers/Settings';
import Maintenance from '../components/core/authentication/containers/Maintenance';
import Masquerade from '../components/core/authentication/containers/Masquerade';
import Login from '../components/core/authentication/containers/Login';
import Logout from '../components/core/authentication/containers/Logout';
import ResetPassword from '../components/core/authentication/containers/ResetPassword';
import Forgot from '../components/core/authentication/containers/Forgot';
import Tracking from '../components/modules/vehicles/containers/Tracking';
import Seasons from '../components/modules/season/containers/Seasons';
import Crops from '../components/modules/cropping/containers/Crops';
import Vehicles from '../components/modules/vehicles/containers/Vehicles';

export const routes = [
  {
    path: '/',
    component: Dashboard,
    title: 'Dashboard',
    exact: true,
    authRequired: true,
  },
  {
    path: '/tracking',
    component: Tracking,
    title: 'Tracking',
    exact: true,
    authRequired: true,
  },
  {
    path: '/crops',
    component: Crops,
    title: 'Crops',
    exact: true,
    authRequired: true,
  },
  {
    path: '/seasons',
    component: Seasons,
    title: 'Seasons',
    exact: true,
    authRequired: true,
  },
  {
    path: '/vehicles',
    component: Vehicles,
    title: 'Vehicles',
    exact: true,
    authRequired: true,
  }, 
  {
    path: '/settings',
    component: Settings,
    title: 'Settings',
    exact: true,
    authRequired: true,
  },
  {
    path: '/users/:id',
    component: User,
    title: 'User',
    exact: false,
    authRequired: true,
  },
];

export const rootRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
    title: 'Login',
    authRequired: true,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    title: 'Login',
    authRequired: false,
  },
  {
    path: '/masquerade',
    exact: true,
    component: Masquerade,
    title: 'Masquerade',
    authRequired: false,
  },
  {
    path: '/logout',
    component: Logout,
    title: 'Logout',
    exact: true,
    authRequired: true,
  },
  {
    path: '/maintenance',
    exact: true,
    component: Maintenance,
    title: 'Maintenance',
    authRequired: false,
  },
  {
    path: '/reset/:hash',
    exact: true,
    component: ResetPassword,
    title: 'ResetPassword',
    authRequired: false,
  },
  {
    path: '/forgot',
    exact: true,
    component: Forgot,
    title: 'Forgot Password',
    authRequired: false,
  },
  {
    path: '/invite/:id',
    exact: true,
    component: Invite,
    title: 'Invite',
    authRequired: false,
  },
  {
    path: '/home',
    component: Home,
    title: 'Home',
    exact: false,
    authRequired: true,
  },

];

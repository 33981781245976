import React from 'react';
import { Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';

const SingleSearch = ({
  handleSearchChange = noop,
  onSearch,
  value,
  placeholder = '',
  inputGroupClassName = 'mr-2',
  width = 300,
  buttonColour = "success"
}) => (
  <InputGroup style={{ width }} className={inputGroupClassName} size="sm">
    <InputGroupAddon addonType="prepend">
      <InputGroupText>
        <Icon name="magnifying-glass" />
      </InputGroupText>
    </InputGroupAddon>
    <Input
      className="h-auto"
      placeholder={placeholder}
      onChange={handleSearchChange}
      onKeyDown={handleSearchChange}
      value={value}
    />
    {onSearch && (
      <InputGroupAddon addonType="append">
        <Button color={buttonColour} onClick={onSearch}>
          Go
        </Button>
      </InputGroupAddon>
    )}
  </InputGroup>
);

export default SingleSearch;

import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInputCheckbox from 'jsx/components/core/form/components/FormInputCheckbox';
import FormInput from '../../../core/form/components/FormInput';
import FormInputSelect from '../../../core/form/components/FormInputSelect';
import VehicleMap from './VehicleMap';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls as vehicleControls } from '../forms/vehicle';

import {
  createVehicle,
  fetchVehicle,
  updateVehicle,
  removeVehicle,
  fetchVehicles
} from '../actions';

import {
  fetchLoggers,
  fetchTrackOrphanCount,
  fetchTrackOrphanCountByLogger
} from '../actions/logger';

class Vehicle extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Vehicle',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(vehicleControls),
      orphanTrackCount: 0
    };

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {

    const { fetching, types, owners } = this.props.vehicles;
    const { harvestgroups } = this.props.season;
    let { loggers } = this.props.loggers;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data, orphanTrackCount } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Vehicle';
      controls = initControls(cloneDeep(vehicleControls));

      id = null;
      data = {};

      // Load combos
      controls.type_id.options = controls.type_id.options.concat(types.map(type => ({id: type.id, name: type.name})));
      // controls.owner_id.options = controls.owner_id.options.concat(owners.map(owner => ({id: owner.id, name: owner.name})));
      controls.harvestgroup_id.options = controls.harvestgroup_id.options.concat(harvestgroups.map(harvestgroup => ({id: harvestgroup.id, name: harvestgroup.name})));

      if (loggers.length === 0) loggers = await this.props.dispatch(fetchLoggers());
      controls.logger_id.options = controls.logger_id.options.concat(loggers.map(logger => ({id: logger.id, name: logger.imei})));

      // load row if exists
      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Vehicle';

        data = await this.props.dispatch(fetchVehicle(id));
        controls = updateControls(controls, data);

        // Count Orphans
        const params = {logger_id: data.logger_id, vehicle_id: null};
        // orphanTrackCount = await this.props.dispatch(fetchTrackOrphanCount(params));
        // controls.attach_orphaned.caption += ` - (${orphanTrackCount})`;
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
        orphanTrackCount
      });
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;
    const { filters, params} = this.props.vehicles;
    const formData = saveControls(controls, data);

    if (formData.logger_id === '') formData.logger_id = null;
    if (formData.type_id === '') formData.type_id = null;
    if (formData.harvestgroup_id === '') formData.harvestgroup_id = null;

    // validation
    if (!controls.logger_id.value) {
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm(
        'WARNING - Saving a vehicle without a logger attached will orphan the vehicle and not associate any track data to the vehicle. Continue?',
      );
  
      if (!confirmed) {
        return;
      }
    }

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createVehicle(formData));
    } else {
      success = await this.props.dispatch(updateVehicle(formData));
    }

    if (success) {
      // this.props.dispatch(fetchTrackOrphanCountByLogger());
      this.props.dispatch(fetchVehicles(params, filters));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;
    const { filters, params} = this.props.vehicles;

    const confirmed = window.confirm(
      'Removing Vehicle permanently. This action can not be undone. Continue?',
    );

    if (confirmed) {
      const success = await this.props.dispatch(removeVehicle(data.id));
      if (success) {
        this.props.dispatch(fetchVehicles(params, filters));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew, data } = this.state;

    const { responseMessage } = this.props.vehicles;

    const iconName = 'tractor';

    return (
      <Modal isOpen={isOpen} className="modal-xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Row>
            <Col>
              <Form>
                <FormInput handleChange={this.handleChange} control={controls.name} />
                <FormInputSelect
                  handleChange={({ value, label }) =>
                    this.handleChange({target: {value, label, name: 'harvestgroup_id'}  })
                  }
                  control={controls.harvestgroup_id}
                />
                <FormInputSelect
                  handleChange={({ value, label }) =>
                    this.handleChange({target: {value, label, name: 'type_id'}  })
                  }
                  control={controls.type_id}
                />
                <FormInputSelect
                  handleChange={({ value, label }) =>
                    this.handleChange({target: {value, label, name: 'logger_id'}  })
                  }
                  control={controls.logger_id}
                />

                <div className="d-flex justify-content-between">
                  <FormInputCheckbox handleChange={this.handleChange} control={controls.enabled} />
                  {controls.logger_id.value && (
                    <FormInputCheckbox handleChange={this.handleChange} control={controls.attach_orphaned} />
                  )}
                </div>

              </Form>
            </Col>

            <Col className="m-2">
              {!isNew && (
                <VehicleMap vehicle={data} />
              )}
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>Delete</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season }) => ({
  vehicles,
  loggers,
  season
});

export default connect(mapStoreToProps)(Vehicle);

import { connect } from 'react-redux';
import { } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import FormBase from 'jsx/components/core/form/components/FormBase';

import SeasonConfigMillPanel from './SeasonConfigMillPanel';
import { faMoneyBillSimple } from '@fortawesome/pro-light-svg-icons';

class SeasonConfigPanel extends FormBase {
  constructor(props) {
    super(props);
  }

  renderAttributeLsvs() {
    const { mills } = this.props.season;

    return mills.map((mill, index) => {
      return (
        <SeasonConfigMillPanel key={index} mill={mill} />
      )
    })
  }

  render() {
    return (
      <div>
        {this.renderAttributeLsvs()}
      </div>
    );
  }
}

const mapStoreToProps = ({ season }) => ({ season });
export default connect(mapStoreToProps)(withContainerError(SeasonConfigPanel));

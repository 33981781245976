import React from 'react';
import { connect } from "react-redux";

import DashboardCanecutter from './DashboardCanecutter';

class Dashboard extends React.Component {
  constructor() {
    super();

    this.renderDashboard = this.renderDashboard.bind(this);
  }

  renderDashboard(app) {
    switch (app.id) {
      case 'canecutter':
        return (<DashboardCanecutter />);
      default: break;
    }
  }

  render() {
    const {
      currentApp
    } = this.props.realm

    const app = (currentApp ? currentApp : {});

    return (
      <>
        {this.renderDashboard(app)}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
  }
}

export default connect(mapStoreToProps)(Dashboard);

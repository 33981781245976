import React from 'react';
import { Button, Label, Input, CustomInput } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import DatePicker from 'react-datepicker';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

const HarvestActivityToolbar = (props) => {
  const {
    onRun,
    onFilterChange,
    onDateFilterChange,
    toggleMap,
    expandMap,
    params,
    statistics,
    inspectors
  } = props;

  let options = {
    className: 'form-control-sm form-control border-lightgray',
    dateFormat: 'yyyy-MM-dd',
  };

  const toggleCaption = (expandMap ? 'Collapse Map' : 'Expand Map');

  return (
    <React.Fragment>
      <div className="bg-light p-1 d-flex justify-content-between">
        <div>
          <Label size="sm" className="bg-success p-1 pl-2 pr-2 text-white border border-success rounded-left">Block Activity Count</Label>
          <Label size="sm" className="text-success bg-white p-1 pl-3 pr-3 border border-success rounded-right mr-3">{parseInt(statistics?.rows)}</Label>
          <Button color="orange text-white" size="sm" onClick={toggleMap} className="ml-1 mr-1 ">{toggleCaption}</Button>
        </div>

        <span className="">
          <Label size="sm" className="ml-2 mr-2 float-left font-bolder">Activity:</Label>
          <div className="float-left">
            <DatePicker placeholderText="From Date" className="bg-danger" selected={new Date(params.filter_from_date)} onChange={(date) => onDateFilterChange('filter_from_date', date)} {...options} />
          </div>
          <Label size="sm" className="ml-2 mr-2 float-left">to</Label>
          <div className="float-left">
            <DatePicker placeholderText="To Date" className="mr-2" selected={new Date(params.filter_to_date)} onChange={(date) => onDateFilterChange('filter_to_date', date)} {...options} />
          </div>

          <Label size="sm" className="ml-2 mr-2 float-left">Filter:</Label>
          <Input size="sm" className="mr-2 float-left" id="filter_mill_id" placeholder='Mill' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_group_no" placeholder='Group No' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_inspector" placeholder='Inspector (First name)' onKeyUp={onFilterChange} style={{width: 200}}/>

          <Button color="success" size="sm" onClick={onRun} className="ml-1 mr-1 float-left">Query</Button>
        </span>

      </div>
    </React.Fragment>
  );
};

export default HarvestActivityToolbar;

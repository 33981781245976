import React from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';

const FormInputSwitch = ({ control, groupClassName, handleChange, inputRef }) => {
  const { caption, name, value, descriptionStatus, id } = control;

  const applyColor = (inputState) => {
    if (inputState === 'is_locked') return `${value ? 'text-danger' : 'text-secondary'}  mr-3`;
    return `${value ? 'text-primary' : 'text-secondary'}  mr-3`;
  };

  return (
    <FormGroup row className={`mt-0 mb-0 ${groupClassName}`}>
      {caption && (
        <Label for={name} sm={12} className="font-weight-bold">
          {caption}
        </Label>
      )}
      <div className="ml-2 d-flex w-100 p-1">
        <CustomInput
          type="switch"
          role="switch"
          id={id ?? name}
          name={name}
          checked={value}
          onChange={handleChange}
          className="ml-3"
          innerRef={inputRef || null}
        />

        <div className={applyColor(name)}>
          {value ? descriptionStatus.switchOn : descriptionStatus.switchOff}
        </div>
      </div>
      <small className="ml-3 mt-1 text-success">{control.description}</small>
    </FormGroup>
  );
};

export default FormInputSwitch;

import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  owner_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'owner_id',
    type: 'select',
    caption: 'Grower Business',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Grower Business. This is a required field'
  },
  farm_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'farm_id',
    type: 'select',
    caption: 'Farm',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Farm Entity. This is a required field'
  },
  harvestgroup_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'harvestgroup_id',
    type: 'select',
    caption: 'Harvester Group',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Harvester Group. This is a required field'
  },
  assigned_mill_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'assigned_mill_id',
    type: 'select',
    caption: 'Assigned Mill',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Assigned Mill. This is a required field'
  },
  harvest_ha_fee: {
    ...controlDefaults,
    placeholder: '',
    name: 'harvest_ha_fee',
    type: 'number',
    caption: 'Harvest/Ha Fee',
    validationRules: {
      isRequired: true
    },
    description: 'Fee for Harvesting per Ha. Default value is zero'
  },
  haulage_ton_fee: {
    ...controlDefaults,
    placeholder: '',
    name: 'haulage_ton_fee',
    type: 'number',
    caption: 'Haulage/Tonne Fee',
    validationRules: {
      isRequired: true
    },
    description: 'Fee for Haulage per Tonne. Default value is zero'
  },
};

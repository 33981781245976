import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormInputSelect from '../../../core/form/components/FormInputSelect';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls as loggerControls } from '../forms/farm_assoc';

import {
   fetchFarmAssoc,
   updateFarmAssoc,
   fetchFarmAssocs,
   createFarmAssoc
} from '../actions';

class FarmAssocModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Farm Associations',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(loggerControls),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    const { fetching, farms, harvestgroups, mills } = this.props.season;
    const { orgs } = this.props.manage;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Logger';
      controls = initControls(cloneDeep(loggerControls));

      id = null;
      data = {};

      // Load combos
      const noneSelected = [{id: null, name: 'None Selected'}];
      controls.owner_id.options = noneSelected.concat(orgs.map(org => ({id: org.id, name: org.name})));
      controls.farm_id.options = noneSelected.concat(farms.map(farm => ({id: farm.id, name: `${farm.farm_no} - ${farm.name}`})));
      controls.harvestgroup_id.options = noneSelected.concat(harvestgroups.map(harvestgroup => ({id: harvestgroup.id, name: harvestgroup.name})));
      controls.assigned_mill_id.options = noneSelected.concat(mills.map(mill => ({id: mill.id, name: mill.name})));

      // load row if exists
      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Farm Association';

        data = await this.props.dispatch(fetchFarmAssoc(id));
        controls = updateControls(controls, data);
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      });
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;
    const formData = saveControls(controls, data);

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createFarmAssoc(formData));
    } else {
      success = await this.props.dispatch(updateFarmAssoc(formData));
    }

    if (success) {
      this. props.dispatch(fetchFarmAssocs(this.props.params));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      'Removing Farm Association permanently. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
    //   const success = await this.props.dispatch(removeFarmAssoc(data.id));
      if (success) {
        this. props.dispatch(fetchFarmAssocs(this.props.params));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;
    const { responseMessage } = this.props.season;

    const iconName = 'leaf';
  
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'owner_id'}  })
              }
              control={controls.owner_id}
            />
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'farm_id'}  })
              }
              control={controls.farm_id}
            />
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'harvestgroup_id'}  })
              }
              control={controls.harvestgroup_id}
            />
            <FormInputSelect
              handleChange={({ value, label }) =>
                this.handleChange({target: {value, label, name: 'assigned_mill_id'}  })
              }
              control={controls.assigned_mill_id}
            />
            <FormInput handleChange={this.handleChange} control={controls.harvest_ha_fee} />
            <FormInput handleChange={this.handleChange} control={controls.haulage_ton_fee} />
          </Form>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" disabled onClick={this.onRemove} >Delete</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season, manage }) => ({
  vehicles,
  loggers,
  season,
  manage
});

export default connect(mapStoreToProps)(FarmAssocModal);

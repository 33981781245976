import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import classnames from 'classnames';
import { HalfCircleSpinner } from 'react-epic-spinners';
import withFormTabNavigation from './FormTabNavigation';

const FormTab = (props) => {
  const {
    toggle,
    activeTab,
    tabId,
    tabTag,
    caption,
    disabled,
    iconName,
    isSpinning,
    iconRightName,
    iconRightClass,
  } = props;

  const icon = iconName ? (
    <Icon name={iconName} className="mr-2 mt-1 text-primary" />
  ) : null;
  const iconRight = iconRightName ? (
    <Icon name={iconRightName} className={`ml-2 mt-1 text-${iconRightClass}`} />
  ) : null;

  return (
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === tabId })}
        onClick={() => {
          toggle(tabId, tabTag);
        }}
        disabled={disabled}
      >
        <div className="d-flex flex-row">
          {icon}
          {caption}
          {isSpinning && (
            <HalfCircleSpinner className="align-self-center ml-2" color="#34a853" size={14} />
          )}
          {iconRight}
        </div>
      </NavLink>
    </NavItem>
  );
};

// export default withFormTabNavigation(FormTab);
export default FormTab;

import {
  apiFarmBlocks
} from 'jsx/constants/api';

import { get, post } from 'jsx/lib/genericAction';

export function fetchMunch(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_MUNCH', `${apiFarmBlocks}/munch`, params);
}

export function sliceCropPolygon(data) {
  return async (dispatch) => post(dispatch, 'SLICE_BLOCK', `${apiFarmBlocks}/crops/slice`, data);
}

export function fetchCropStatistics(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCK_CROP_STATISTICS', `${apiFarmBlocks}/crops/statistics`, params);
}

import { useState } from 'react';

import PillPicker from 'jsx/components/core/form/components/PillPicker';
import FormInputSmall from 'jsx/components/core/form/components/FormInputSmall';

import { controls } from '../forms/filter';

const TrackingFilterPanel = (props) => {
  const { 
    vehicleTypes,
    handleFilterChange,
    handleDelete,
    filters,
    vehicleStatuses,
    vehicleOwners,
    mills,
    harvestGroups,
    inspectors,
    storeSettings,
    settings
  } = props;

  const [sectionControls, setSectionControls] = useState(controls);

  sectionControls.enabled.value = filters.enabled;
  sectionControls.show_vehicle_labels.value = settings.showVehicleLabels;
  sectionControls.zoom_to_boundary.value = settings.zoomToBoundary;
  sectionControls.tail_minutes.value = settings.tailMinutes;
  sectionControls.auto_load.value = settings.autoLoad;

  return (
    <div className="bg-light h-100 p-2">
      <div className="border-bottom border-lightgray mt-2">
        <PillPicker
          caption="Vehicle Types"
          name="type_id"
          handleChange={handleFilterChange}
          handleDelete={handleDelete}
          rows={vehicleTypes}
          selection={filters.type_id}
          showPills
          defaultPillColour="pink"
        />
      </div>
      <div className="border-bottom border-lightgray mt-2">
        <PillPicker
          caption="Vehicle Status"
          name="status_id"
          handleChange={handleFilterChange}
          handleDelete={handleDelete}
          rows={vehicleStatuses}
          selection={filters.status_id}
          showPills
          defaultPillColour="green"
        />
      </div>
      <div className="border-bottom border-lightgray mt-2">
        <PillPicker
          caption="Harvest Groups"
          name="harvestgroup_id"
          handleChange={handleFilterChange}
          handleDelete={handleDelete}
          rows={harvestGroups}
          selection={filters.harvestgroup_id}
          showPills
          defaultPillColour="yellow"
        />
      </div>
      <div className="border-bottom border-lightgray mt-2">
        <PillPicker
          caption="Mills"
          name="assigned_mill_id"
          handleChange={handleFilterChange}
          handleDelete={handleDelete}
          rows={mills}
          selection={filters.assigned_mill_id}
          showPills
          defaultPillColour="purple"
        />
      </div>
      <div className="border-bottom border-lightgray mt-2">
        <PillPicker
          caption="Cane Inspectors"
          name="inspector_id"
          handleChange={handleFilterChange}
          handleDelete={handleDelete}
          rows={inspectors}
          selection={filters.inspector_id}
          showPills
        />
      </div>
      <div className="p-1">
        <div>
          <FormInputSmall
            handleChange={handleFilterChange}
            control={sectionControls.enabled}
            className="d-flex justify-content-between m-0"
            hideCaption
          />
          <small className="ml-4 mt-2">Show Enabled Vehicles only</small>
        </div>
        <div >
          <FormInputSmall
            handleChange={(event) => storeSettings('showVehicleLabels', event.target.checked)}
            control={sectionControls.show_vehicle_labels}
            className="d-flex justify-content-between m-0"
            hideCaption
          />
          <small className="ml-4 mt-1">Show Vehicle Labels</small>
        </div>
        <div >
          <FormInputSmall
            handleChange={(event) => storeSettings('autoLoad', event.target.checked)}
            control={sectionControls.auto_load}
            className="d-flex justify-content-between m-0"
            hideCaption
          />
          <small className="ml-4 mt-1">{sectionControls.auto_load.caption}</small>
        </div>
        {/* <div >
          <FormInputSmall
            handleChange={(event) => storeSettings('zoomToBoundary', event.target.checked)}
            control={sectionControls.zoom_to_boundary}
            className="d-flex justify-content-between m-0"
            hideCaption
          />
          <small className="ml-4 mt-1">Zoom To Boundary on Vehicle update</small>
        </div> */}
        {/* <div className="mt-2">
          <small >Track/Tail Length (minutes)</small>
          <FormInputSmall
            handleChange={(event) => storeSettings('tailMinutes', event.target.value)}
            control={sectionControls.tail_minutes}
            className="d-flex justify-content-between m-0"
          />
        </div> */}
      </div>
    </div>
  );
};

export default TrackingFilterPanel;

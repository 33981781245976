import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import Pill from 'jsx/components/core/form/components/Pill';

const OrgsLsv = (props) => {
  const {
    rows,
    onClick
  } = props;

  const renderRows = (headers, row) => {

    const categories = row.categories.map((category, index) => {
      let pillBg;
      switch (category.category?.tag) {
        case 'grower': 
          pillBg = 'bg-primary';
          break;
        case 'mill':
          pillBg = 'bg-orange';
          break;
        default:
          pillBg = 'bg-success';
          break
      }

      const classes = `${pillBg} float-left d-flex`;
      return (
        <Pill size="sm" caption={category.category?.name} key={index} classes={classes} />
      )
    });

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'farm_count': return  (
          <td key={index} className={header.classes}>{row.owner_farms?.length}</td>
        );
        case 'vehicle_count': return  (
          <td key={index} className={header.classes}>{row.vehicles?.length}</td>
        );
        case 'assoc_count': return  (
          <td key={index} className={header.classes}>{row.group_farms?.length}</td>
        );
        case 'parent': return  (
          <td key={index} className={header.classes}>{row.parent_org?.name}</td>
        );
        case 'categories': return  (
          <td key={index} className={header.classes}>{categories}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Name', field: 'name', classes:'text-left'},
    {caption: 'Belongs To', field: 'parent', classes:'text-left'},
    // {caption: 'Contact', field: 'tag', classes:'text-left'},
    {caption: 'Categories', field: 'categories', classes:'text-left', style: {width: 250}}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onClick(row)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='user-group';
  const emptyCaption='No Organisations/Contacts found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default OrgsLsv;

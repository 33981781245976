import { allowed, defaults } from 'jsx/lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    activeSettingsTab: null,
    authorised: true,
    authResponseMessage: null,
    error: null,
    responseMessage: null,
    orphansByLogger: [],
    loggers: [],
    stats: [],
    types: [],
    params: {},
    filters: {enabled: true}
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'TRACK',
    'LOGGER'
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_TRACK_ORPHAN_COUNT_BYLOGGER_FULFILLED': {
      return {
        ...updatedState,
        orphansByLogger: action.payload,
      };
    }
    case 'FETCH_LOGGERS_FULFILLED': {
      return {
        ...updatedState,
        loggers: action.payload,
      };
    }
    case 'FETCH_TRACK_STATS_FULFILLED': {
      return {
        ...updatedState,
        stats: action.payload
      };
    }
    case 'FETCH_LOGGER_TYPES_FULFILLED': {
      return {
        ...updatedState,
        types: action.payload,
      };
    }
    case 'SET_LOGGER_FILTERS': {
      return {
        ...updatedState,
        filters: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}

import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'jsx/components/core/icons/Icon';

const AppMenuItem = (props) => {
  const { url, iconName, caption, location, iconsOnly, disabled } = props;

  var className = `menu-item`;

  var isActive = false;
  if (location.pathname === url) {
    className += ' menu-item-active';
    isActive = true;
  }

  return (
    <React.Fragment>
      <li
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {!disabled && (
          <Link to={url} className="d-flex justify-content-between">
            <span>
              <Icon name={iconName} className="mt-1 mr-3" style={{ width: 16 }} />
              {!iconsOnly && <span>{caption}</span>}
            </span>
            {isActive && !iconsOnly && <Icon name="chevron-right" className="mt-1 appForeTint" />}
          </Link>
        )}
        {disabled && (
          <span className="text-secondary">
            <Icon name={iconName} className="mt-1 mr-3" style={{ width: 16 }} />
            <span className="text-secondary">{caption}</span>
          </span>
        )}
      </li>
    </React.Fragment>
  );
};

export default AppMenuItem;

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';

const InspectorsLsv = (props) => {
  const {
    rows,
    defaults
  } = props;

  const renderRows = (headers, row) => {
    const { inspector } = row;

    const tableTd = headers.map((header, index) => {

      switch (header.field) {
        case 'firstname': return  (
          <td key={index} className={header.classes}>{inspector.firstname}</td>
        );
        case 'lastname': return  (
          <td key={index} className={header.classes}>{inspector.lastname}</td>
        );
        case 'season': return  (
          <td key={index} className={header.classes}>{row.season.name}</td>
        );
        case 'mill': return  (
          <td key={index} className={header.classes}>{row.mill.tag.toUpperCase()}</td>
        );
        case 'group': return  (
          <td key={index} className={header.classes}>{row.group?.name}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'First Name', field: 'firstname', classes:'text-left'},
    {caption: 'Last Name', field: 'lastname', classes:'text-left'},
    {caption: 'Season', field: 'season', classes:'text-left'},
    {caption: 'Mill', field: 'mill', classes:'text-left'},
    {caption: 'Group', field: 'group', classes:'text-left'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='people-group';
  const emptyCaption=`No Inspectors found for Season ${defaults?.active_season?.name}`;

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default InspectorsLsv;

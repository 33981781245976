import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';

const RakesLsv = (props) => {
  const {
    rows,
    onRowClick,
    darkTable
  } = props;

  const renderRows = (headers, row) => {

    const block_name = row?.farm_block?.name;
    const crush_mill = row?.crush_mill?.name;
    const group = row?.harvest_group?.name;
    const sample_time = `${moment(row.sample_time).format('YYYY-MM-DD HH:mm')} (${moment(row.sample_time).fromNow()})`;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'sample_time': return  (
          <td key={index} className={header.classes}>{sample_time}</td>
        );
        case 'group': return  (
            <td key={index} className={header.classes}>{group}</td>
        );
        case 'block_name': return  (
          <td key={index} className={header.classes}>{block_name}</td>
        );
        case 'crush_mill': return  (
          <td key={index} className={header.classes}>{crush_mill}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Block', field: 'block_name', classes:'text-left'},
    {caption: 'Crush Mill', field: 'crush_mill', classes:'text-left'},
    {caption: 'Crush Week', field: 'week_no', classes:'text-center'},
    {caption: 'Crush Day', field: 'crushday', classes:'text-center'},
    {caption: 'Pay Week', field: 'payment_week_no', classes:'text-center'},
    {caption: 'Pay Day', field: 'payment_day_no', classes:'text-center'},
    {caption: 'Sample No', field: 'sample_no', classes:'text-right'},
    {caption: 'Sample Time', field: 'sample_time', classes:'text-left'},
    {caption: 'Group', field: 'group', classes:'text-left'},
    {caption: 'Connote', field: 'connote', classes:'text-left'},
    {caption: 'Class Code', field: 'class_code', classes:'text-left'},
    {caption: 'Variety Code', field: 'variety_code', classes:'text-left'},
    {caption: 'Tonnes', field: 'tonnes', classes:'text-right'},
    {caption: 'Bins', field: 'bins', classes:'text-right'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='line-height';
  const emptyCaption='No Rakes found';

  return (
    <React.Fragment>
      <Listview darkTable={darkTable} rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default RakesLsv;

import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Row, Col } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import FormTab from '../../core/form/components/FormTab';
import FormBase from '../../core/form/components/FormBase';
import ResponseMessage from '../../core/form/components/ResponseMessageTab';
import UsersLsv from '../components/UsersLsv';
import RolesLsv from '../components/RolesLsv';
import InvitationsLsv from '../components/InvitationsLsv';

import PageTitleH5 from '../../core/form/components/PageTitleH5';
import ActivitiesLsv from '../../core/activity/components/ActivitiesLsv';
import { fetchActivities } from '../../core/activity/actions';

import {
  fetchUsers,
  fetchRoles,
  fetchInvitations,
  removeInvite,
  setUserParams,
} from '../actions';

import UsersToolbar from '../components/UsersToolbar';
import Invitation from './Invitation';
import UsersFilter from '../components/UsersFilter';

class Settings extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      activities: [],
      isFilterOpen: false,
      isInvitationModalOpen: false,
      searchValue: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
    this.onChange = this.onChange.bind(this);
    this.removeInvitation = this.removeInvitation.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.setInvitationModal = this.setInvitationModal.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleDefaultTab = this.toggleDefaultTab.bind(this);
  }

  componentDidMount() {
    const { manage } = this.props.realm.currentApp;
    if (manage?.length > 0) this.toggleDefaultTab();
  }

  componentDidUpdate() {
    const { activeSettingsTab } = this.props.manage;
    const { dispatch } = this.props;
    const { userResponseMessage } = this.props.manage;
    const { manage } = this.props.realm.currentApp;

    if (activeSettingsTab === null && manage?.length > 0) this.toggleDefaultTab();

    if (userResponseMessage) {
      setTimeout(() => {
        dispatch({ type: 'RESET_USER_RESPONSE_MESSAGE' });
      }, 3000);
    }
  }

  setSearchValue(searchValue) {
    this.setState({ searchValue });
  }

  loadMoreActivities() {
    const { params } = this.props.activities;

    let limit = 30;
    if (params.limit) {
      limit = params.limit + limit;
    }

    const new_params = {
      associate_with: 'user',
      show_dependencies: false,
      limit,
    };
    this.props.dispatch(fetchActivities(new_params, null));
  }

  async toggleTab(tab, tag) {
    switch (tag) {
      case 'users':
        const { filters, params } = this.props.manage;
        let userParams = {};

        // Handle search value
        if (params?.search_value) {
          const { search_value } = params;
          userParams = { search_value };
          this.setState({ searchValue: search_value });
        }

        // Handle filters
        this.props.dispatch(fetchUsers(userParams, filters));
        break;
      case 'invites':
        this.props.dispatch(fetchInvitations());
        break;
      case 'roles':
        this.props.dispatch(fetchRoles());
        break;
      case 'activity':
        this.loadMoreActivities();
        break;
      default: {
        break;
      }
    }

    if (this.props.manage.activeSettingsTab !== tab) {
      await this.props.dispatch({ type: 'SET_SETTINGS_ACTIVE_TAB', payload: tab });
    }
  }

  toggleDefaultTab() {
    const { manage } = this.props.realm.currentApp;

    // Find Settings config
    const { tabs } = manage.find(({ tag }) => tag === 'settings');
    if (tabs?.length > 0) {
      const { activeSettingsTab } = this.props.manage;
      const activeTab = tabs.find(({ tabId }) => tabId === activeSettingsTab) || tabs[0];
      const { tabId, tabTag } = activeTab;

      this.toggleTab(tabId, tabTag);
    }
  }

  removeInvitation(id) {
    this.props.dispatch(removeInvite(id));
    this.props.dispatch(fetchInvitations());
  }

  renderTabs() {
    const { currentApp } = this.props.realm;

    // Look for the settings tab
    const settings = currentApp.manage.find(({ tag }) => tag === 'settings');

    // Add only tabs for realm settings tabs
    if (settings) {
      return settings.tabs.map(({ caption, iconName, tabId, tabTag }) => (
        <FormTab
          key={tabTag}
          iconName={iconName}
          tabTag={tabTag}
          caption={caption}
          tabId={tabId}
          activeTab={this.props.manage.activeSettingsTab}
          toggle={this.toggleTab}
        />
      ));
    }

    return [];
  }

  setInvitationModal(value) {
    this.setState({ isInvitationModalOpen: value });
  }

  onChange() {
    const { searchValue } = this.state;
    const { filters } = this.props;
    const params = { search_value: searchValue };

    this.props.dispatch(setUserParams(params)).then(({ payload }) => {
      this.props.dispatch(fetchUsers(payload, filters));
    });
  }

  handleSearchChange(event) {
    // Update search value
    const searchValue = event.target.value;
    this.setState({ searchValue });

    const enter_button_code = 13;
    if (event.keyCode === enter_button_code) this.onChange();
  }

  toggleFilter() {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  render() {
    const { isFilterOpen, isInvitationModalOpen, searchValue } = this.state;

    const {
      users,
      roles,
      authorised,
      authResponseMessage,
      userResponseMessage,
      invitations,
      // orgs,
      activeSettingsTab,
    } = this.props.manage;

    const { activities } = this.props.activities;

    return (
      <div className="p-0 h-100 border-bottom border-corporate overflow-auto">
        <div className="bg-light p-0 h-100">
          <ResponseMessage responseMessage={authResponseMessage} />

          {authorised && (
            <Row className="p-0 m-0 h-100">
              <Col className="p-0 m-0 h-100 verticalnav settings">
                <Nav vertical className="mt-2">
                  {this.renderTabs()}
                </Nav>
              </Col>
              <Col className="h-100 overflow-auto">
                <TabContent activeTab={activeSettingsTab} className="border-bottom border-primary">
                  <TabPane tabId="1" className="mb-2 mt-2">
                    <ResponseMessage responseMessage={userResponseMessage} colour="text-success" />
                    <UsersToolbar
                      filterClick={this.toggleFilter}
                      handleSearchChange={this.handleSearchChange}
                      openModal={this.setInvitationModal}
                      searchValue={searchValue}
                      users={users || []}
                    />
                    <UsersFilter isFilterOpen={isFilterOpen} />
                    <Invitation setModal={this.setInvitationModal} isOpen={isInvitationModalOpen} />
                    <UsersLsv
                      history={this.props.history}
                      responseMessage={userResponseMessage}
                      rows={users || []}
                    />
                  </TabPane>

                  <TabPane tabId="2" className="mb-2 p-1">
                    <InvitationsLsv
                      removeInvitation={this.removeInvitation}
                      rows={invitations || []}
                    />
                  </TabPane>

                  <TabPane tabId="3" className="mb-2 p-1">
                    <RolesLsv rows={roles || []} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ manage, activities, realm, office, atrributes }) => ({
  manage,
  activities,
  realm,
  office,
  atrributes,
});

export default connect(mapStoreToProps)(withContainerError(Settings));

import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';

import { Row, Col } from 'reactstrap';

import { Route, Switch, Link } from 'react-router-dom';
import { routes } from '../constants/routes';
import AppMenuItem from '../components/core/menu/components/AppMenuItem';

import canecutter_logo from '../../images/canecutter/logo_horizontal.png';

class HomeHorizontal extends React.Component {
  constructor() {
    super();

    this.state = {
      narrowSidebar: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState({ narrowSidebar: !this.state.narrowSidebar });
  }

  render() {
    const { match, location } = this.props;
    const { narrowSidebar } = this.state;
    const { currentApp, fullMapScreen } = this.props.realm;
    const { isMasquerade } = this.props.auth;
    const { loggedInAs } = this.props;
    const { defaults } = this.props.season;

    let logo = null;
    switch (currentApp.id) {
      case 'canecutter':
      default:
        logo = canecutter_logo;
        break;
    }

    let manageItems = <small className="p-2 text-secondary text-center">No Manage Items</small>;
    if (currentApp.manage.length > 0) {
      manageItems = currentApp.manage.map((manageItem, index) => (
        <AppMenuItem
          key={`app-menu-item-${index}`}
          url={manageItem.url}
          caption={manageItem.caption}
          iconName={manageItem.iconName}
          location={location}
          disabled={manageItem.disabled}
          iconsOnly={narrowSidebar}
        />
      ));
    }

    let menuItems = <small className="p-2 text-secondary text-center">No Realm Items</small>;
    if (currentApp.manage.length > 0) {
      const canViewSamplingPlans = currentApp.features.includes('samplingPlansView');
      menuItems = currentApp.menus
        .filter((menu) => {
          if (menu.caption.includes('Sampling Plans')) {
            return canViewSamplingPlans;
          }
          return true;
        })
        .map((menu, index) => (
          <AppMenuItem
            key={`app-menu-item-${index}`}
            url={menu.url}
            caption={menu.caption}
            iconName={menu.iconName}
            location={location}
            disabled={menu.disabled}
            iconsOnly={narrowSidebar}
          />
        ));
    }

    let className = 'listview';
    if (narrowSidebar) {
      className += ' listview-narrow';
    }
    const routesToRender = routes.flatMap(({ tabs = [], exact, component, ...route }, index) => {
      const ComponentToRender = component;
      const tabSubroutes = tabs.map((currentTab) => ({
        exact,
        key: `tab-${index}-${currentTab}`,
        path: `${match.path}${route.path}/${currentTab}`,
        render: (routeProps) => <ComponentToRender {...routeProps} tabRoutes={tabs} />,
      }));

      return [
        ...tabSubroutes,
        {
          exact,
          key: `tab-${index}`,
          path: `${match.path}${route.path}`,
          render: (routeProps) => <ComponentToRender {...routeProps} tabRoutes={tabs} />,
        },
      ];
    });

    const appTitle = `CANE CUTTER ${defaults?.active_season?.name}`;

    return (
      <>
        {isMasquerade && <div className="masquerade-tooltip">Masquerade</div>}
        <Row className="m-0 p-0 d-flex flex-row flex-grow-1">
          <Col className={className} onClick={this.toggleSidebar} style={fullMapScreen}>
            <div className="header text-center border-bottom p-2">
              {!narrowSidebar && (
                <Link to="/home">
                  {logo && (
                    <>
                      <img
                        src={logo}
                        alt={currentApp.title}
                        style={{ maxWidth: '200px' }}
                        className="p-1"
                      />
                      <div><small style={{color:'#30B673'}}>{appTitle}</small></div>
                    </>
                  )}
                  {!logo && (
                    <div className="d-flex justify-content-center p-1 app-menu-header">
                      <Icon className="mt-2" name={currentApp.icon} />
                      <span className="text-white ml-3">{currentApp.title}</span>
                    </div>
                  )}
                </Link>
              )}
              {narrowSidebar && (
                <div style={{ height: 32 }}>
                  <Icon size="1x" name={currentApp.icon} />
                </div>
              )}
            </div>

            <div>
              {!narrowSidebar && (
                <div className="section-header mt-3">
                  <span className="section-title">MODULES</span>
                </div>
              )}
              <ul className="list-group">{menuItems}</ul>
            </div>
            <div className="section-header">
              {!narrowSidebar && <span className="section-title">Manage</span>}
            </div>

            <ul className="list-group">{manageItems}</ul>

            {!narrowSidebar && (
              <div className="text-center p-2">
                <small className="text-corporate">{loggedInAs}</small>
              </div>
            )}
          </Col>
          <Col className="m-0 p-0 overflow-auto">
            <Switch>
              {routesToRender.map((props) => (
                <Route {...props} />
              ))}
            </Switch>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = (store) => ({
  loading: store.ajaxRequests.calls > 0,
  realm: store.realm,
  manage: store.manage,
  profile: store.profile,
  auth: store.auth,
  season: store.season
});

export default connect(mapStoreToProps)(HomeHorizontal);

import React from 'react';
import AnimatedNumber from "animated-number-react";
import Icon from 'jsx/components/core/icons/Icon';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer,YAxis } from 'recharts';

const StatsBarChart = (props) => {
  const {
    title,
    value,
    unit,
    bottomClasses,
    data,
    iconName,
    lineColour
  } = props;

  const formatValue = (value) => value.toLocaleString(undefined, { minimumFractionDigits: 0} );
  const bottomClassName = `text-white ${bottomClasses}`;

  let tileValue = value;
  if (typeof value !== 'string') {
    tileValue = (<AnimatedNumber value={value} formatValue={formatValue} />);
  }

  const className = `text-center bg-light text-${lineColour}`

  return (
    <div className="bg-light border border-secondary rounded p-0 m-1 mt-2">
      <div className="p-1 text-center">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            width={500}
            height={300}
            dataKey="collected"
          >
            <Bar name="Track Count" dataKey="count" fill="#8884d8" />
            <Tooltip />
            <CartesianGrid />
            <XAxis
              dataKey="collected"
            //   hide={true}
            />
            <YAxis />
          </BarChart>
        </ResponsiveContainer>


      </div>

      <div className="text-corporate m-2">{title}</div>
    </div>
  )
}

export default StatsBarChart;

import axios from 'axios';

import { apiMode, apiRealm } from '../constants/api';
import { get } from '../lib/genericAction';
import errorResponse from '../lib/errorResponse';

export function setRealm() {
  return (dispatch) => {
    dispatch({ type: 'SET_REALM_PENDING' });

    axios({
      method: 'GET',
      url: apiRealm,
    })
      .then((response) => {
        const realm = response.data.result;

        // Define browser title and meta. Should be able to set favicon from here somehow
        window.document.title = realm.title;

        // Set Favicon
        if (realm.favicon) {
          const favicon = document.getElementById('favicon');
          favicon.href = realm.favicon;
        }

        if (realm.metaDescription) {
          const realmDescription = document.getElementById('realm-description');
          realmDescription.content = realm.metaDescription;
        }

        switch (realm.id) {
          case 'canecutter':
            import('../../styles/Canecutter.css').then(() => {
              dispatch({ type: 'SET_REALM_FULFILLED', payload: realm });
            });
            break;
          default:
            dispatch({ type: 'SET_REALM_FULFILLED', payload: realm });
            break;
        }
      })
      .catch((err) => {
        console.error('ERROR', err, err.status);
      });
  };
}

export function fetchMode() {
  const dispatch_prefix = 'FETCH_MODE';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiMode}`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: response.data.result });
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
    }
  };
}

export function fetchRouteNames(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ROUTE_NAMES', `${apiRealm}/routes/translate`, params, null, true);
}

import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import InspectorsLsv from '../components/InspectorsLsv';
import InspectorGroupsLsv from '../components/InspectorGroupsLsv';

// import { fetchSeasonInspectors } from '../actions';

class CaneInspectors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // this.props.dispatch(fetchSeasonInspectors());
  }

  render() {
    const { defaults, inspectors } = this.props.season;

    const seasonInspectors=`Season Inspectors for ${defaults?.active_season?.name}`;
    const inspectorGroups=`Inspector Harvest Groups for ${defaults?.active_season?.name}`;

    return (
      <>
        <Row>           
          <Col className="d-flex justify-content-between">
            <small className="text-corporate">{seasonInspectors}</small>
            <small>
              <Button size="sm" color="link" className="p-0">Add New</Button>
            </small>
          </Col>
          {/* <Col className="d-flex justify-content-between" >
            <small className="text-corporate">{inspectorGroups}</small>
            <small>
              <Button size="sm" color="link" className="p-0 mr-2">Add New</Button>
              <Button size="sm" color="link" className="p-0">Import</Button>
            </small>
          </Col> */}
        </Row>
        <Row>           
          <Col className="m-0 ml-2 mr-2 border border-lightgray bg-light rounded">
            <InspectorsLsv rows={inspectors} defaults={defaults} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(CaneInspectors));


/**
 * Get Vehicle colour from reducer
 */
export const getVehicleColour = (component, status_tag) => {
  const featureAttributes = component.props.mapster.attributes;

  switch(status_tag) {
    case 'moving': return featureAttributes.find(attribute => attribute.id === 'vehicle_moving_outline').value;
    case 'cutting': return featureAttributes.find(attribute => attribute.id === 'vehicle_cutting_outline').value;
    case 'transport': return featureAttributes.find(attribute => attribute.id === 'vehicle_transport_outline').value;
    case 'turning': return featureAttributes.find(attribute => attribute.id === 'vehicle_turning_outline').value;
    case 'off': return featureAttributes.find(attribute => attribute.id === 'vehicle_off_outline').value;
    case 'on':
    default: return featureAttributes.find(attribute => attribute.id === 'vehicle_on_outline').value;
  }
}

/**
 * Abort Requests
 */
export const abortRequests = async (component, minZoomLevel) => {
  const { map, abortController } = component.state;

  if (map.getZoom() < minZoomLevel) {
    console.log("Zoomed but not cancelled")
    return;
  }

  // Cancel pending axios requests
  await abortController.abort();

  component.setState({abortController: new AbortController()});
  console.log("Aborting request", map.getZoom() , abortController);

  component.setState({featureFetching: 0});
}

import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { fetchOrgs } from 'jsx/components/manage/actions';
import OrgsLsv from '../components/OrgsLsv';
import OrgsToolbar from '../components/OrgsToolbar';
import OrgModal from './OrgModal';

class OrgsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {},
      filters: {},
      searchValue: null,
      isModalOpen: false,
      id: null,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  componentDidMount() {}

  handleSearchChange(event) {
    this.setState({searchValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params,
      searchValue
    } = this.state;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchOrgs(params, filters));

    this.setState({params})
  }

  onAdd() {
    console.log("add");
    this.setModal();
  }

  setModal(row) {
    this.setState({isModalOpen: !this.state.isModalOpen, id: row?.id});
  }

  render() {
    const { orgs } = this.props.manage;
    const { searchValue, id, isModalOpen, params } = this.state;

    return (
      <div>
        <OrgsToolbar
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          searchValue={searchValue}
          onAdd={this.onAdd}
        />
        <OrgModal params={params} setModal={this.setModal} id={id} isOpen={isModalOpen}/>

        <OrgsLsv rows={orgs} onClick={this.setModal} />
      </div>
    );
  }
}

const mapStoreToProps = ({ realm, manage }) => ({ realm, manage });
export default connect(mapStoreToProps)(withContainerError(OrgsPanel));

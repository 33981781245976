/* eslint-disable jsx-a11y/no-autofocus */ // See ES-252
import React, { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import moment from 'moment';
import { numberRuleFormat } from '../lib/fieldFormat';
import { isNil } from 'lodash';

const FormInput = ({
  autoFocus,
  control,
  disabled,
  handleChange,
  handleKeyDown,
  handleBlur,
  hideCaption,
  innerRef,
  inputColStyle,
  labelStyle,
  ...props
}) => {
  let { className } = props;
  const createOptions = (options) => {
    if (Array.isArray(options) && options.length) {
      return options.map((option, index) => (
        <option key={index} value={option.id} disabled={option.disabled}>
          {option.name}
        </option>
      ));
    } else {
      return null;
    }
  };

  const textRows = control.textRows || 3;
  let { value } = control;

  switch (control.type) {
    case 'date':
      if (!isNil(value)) value = moment(value).format('YYYY-MM-DD');
      break;
    case 'datetime-local':
      if (value !== null) value = moment(value).format('YYYY-MM-DDTHH:mm');
      break;
    case 'time':
      if (value !== null) value = moment(value).format('HH:mm');
      break;
    case 'display': {
      const { formattingRules } = control;
      if (formattingRules) value = numberRuleFormat(value, formattingRules);
      break;
    }
    case 'number':
    case 'text':
      value = !value && value === 0 ? '0' : value;
      break;
    default:
      break;
  }

  const [copyIcon, setCopyIcon] = useState('copy');
  const onCopy = () => {
    setCopyIcon('check');

    const tempElement = document.createElement('textarea');
    tempElement.value = value;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    setTimeout(() => {
      setCopyIcon('copy');
    }, 750);
  };

  className = `${className ?? ''}${control.warning ? ' input-warning-border' : ''}`;

  return (
    <FormGroup row className="mt-0 mb-0">
      {control.caption && !hideCaption && (
        <Label for={control.name} sm={12} className="font-weight-bold" style={labelStyle}>
          {control.caption}
        </Label>
      )}
      <Col sm={12} style={inputColStyle}>
        <div className="d-flex">
          <Input
            className={className}
            autoFocus={autoFocus}
            innerRef={innerRef}
            type={control.type}
            name={control.name}
            id={control.id ? control.id : control.name}
            value={value || ''}
            placeholder={control.placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            rows={textRows}
            disabled={disabled || control.disabled}
            checked={control.value ?? null}
          >
            {createOptions(control.options)}
          </Input>
          {control.allow_copy && (
            <Icon
              size="2x"
              name={copyIcon}
              role="button"
              className="mt-1 p-1 ml-n4-half"
              onClick={onCopy}
            />
          )}
          {control.loading && (
            <HalfCircleSpinner size={20} color="grey" className="mt-1 p-1 ml-n4-half" />
          )}
        </div>
        {!control.valid && <small className="text-danger">{control.message}</small>}
        {control.valid && control.warning && (
          <small className="text-warning">{control.message}</small>
        )}
        {control.description && <small className="text-success">{control.description}</small>}
      </Col>
    </FormGroup>
  );
};

export default FormInput;

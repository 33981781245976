import { Button, Input, Label } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

const AssociationsToolbar = (props) => {
  const {
    onFilter,
    onFilterChange,
    onAdd 
  } = props;

  return (
    <div className="bg-light rounded p-1 m-0 d-flex justify-content-end">
      <div className="d-flex ml-2">
        <Label className="m-1">Group:</Label>
        <Input id="filter_group" placeholder='Filter' onKeyUp={onFilterChange} />
      </div>
      <div className="d-flex ml-2">
        <Label className="m-1">Farm:</Label>
        <Input id="filter_farm" placeholder='Filter' onKeyUp={onFilterChange} />
      </div>
      <div className="d-flex ml-2 mr-2">
        <Label className="m-1">Owner:</Label>
        <Input id="filter_owner" placeholder='Partial Filter' onKeyUp={onFilterChange} />
      </div>
      <div className="d-flex ml-2 mr-2">
        <Label className="m-1">Mill:</Label>
        <Input id="filter_mill" placeholder='Filter' onKeyUp={onFilterChange} />
      </div>

      <Button size="sm" color="success ml-2" onClick={onAdd}>Add Assoc</Button>
    </div>
  );
};

export default AssociationsToolbar;

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    zoomCoords: [],
    styles: {
      standard: 'mapbox://styles/mapbox/standard',
      streets: 'mapbox://styles/mapbox/streets-v12',
      outdoors: 'mapbox://styles/mapbox/outdoors-v12',
      light: 'mapbox://styles/mapbox/light-v10',
      dark: 'mapbox://styles/mapbox/dark-v10',
      satellite: 'mapbox://styles/mapbox/satellite-v9',
      satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11'
    },
    spare: ['#BD978F'],
    attributes: [
      { id: 'farm_outline', caption: 'Farm Polygon Outline', value:'#232323', type:'colour' },
      { id: 'farm_fill', caption: 'Farm Fill', value:'#beb297', type:'colour' },
      { id: 'block_outline', caption: 'Block Polygon Outline', value:'#000000', type:'colour' },
      { id: 'block_fill', caption: 'Block Fill', value:'#865C95', type:'colour' },
      { id: 'block_fill_crop', caption: 'Block Fill - Crop', value:'#865C95', type:'colour' },
      { id: 'block_fill_harvested', caption: 'Block Fill - Harvested', value:'#865C95', type:'colour' },
      { id: 'block_fill_fallow', caption: 'Block Fill - Fallow', value:'#865C95', type:'colour' },
      { id: 'vehicle_label_colour', caption: 'Vehicle Label Colour', value:'red', type:'colour' },
      { id: 'vehicle_moving_outline', caption: 'Vehicle/Track MOVING Status', value:'orange', type:'colour' },
      { id: 'vehicle_cutting_outline', caption: 'Vehicle/Track CUTTING Status', value:'green', type:'colour' },
      { id: 'vehicle_turning_outline', caption: 'Vehicle/Track TURNING Status', value:'blue', type:'colour' },
      { id: 'vehicle_transport_outline', caption: 'Vehicle/Track TRANSPORT Status', value:'purple', type:'colour' },
      { id: 'vehicle_on_outline', caption: 'Vehicle ON Status', value:'black', type:'colour' },
      { id: 'vehicle_off_outline', caption: 'Vehicle OFF Status', value:'gray', type:'colour' }
    ],
    colours: {
      tracks: {
        fill: '#487bb6',
        outline: 'darkblue',
      },
      vehicle_tracks: {
        outline: 'red'
      },
      vehicles: {
        iconColour: '#f6993f',
        labelColour: '#cc0000'
      },
      farms: {
        fill: '#beb297',
        outline: '#232323',
        labelColour: '#cc0000'
      },
      blocks: {
        fill: '#865C95',
        outline: '#000000',
        labelColour: '#cc0000'
      },
    }
  },
  action
) {
  switch (action.type) {
    case 'SET_ZOOM_COORDS': {
      return {
        ...state,
        fetching: true,
        fetched: false,
        zoomCoords: action.payload,
      };
    }
    case 'SET_MAP_STYLES': {
      return {
        ...state,
        fetching: true,
        fetched: false,
        attributes: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}

import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { rootRoutes, rootRedirects } from '../constants/routes';
import Loader from '../components/core/loader/components/Loader';
import PrivateRoute from '../components/core/authentication/containers/PrivateRoute';

const AppRoutes = (props) => {
  const { loading } = props;
  const isStaging = JSON.parse(localStorage.getItem('staging'));

  return (
    <Router>
      <div className={`container-fluid p-0 d-flex flex-column h-100 ${isStaging ? 'staging' : ''}`}>
        <Loader loading={loading} />
        <Switch>
          {rootRoutes.map((route, index) => (
            <PrivateRoute
              exact={route.exact}
              key={index}
              path={route.path}
              route={route}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
};

export default AppRoutes;

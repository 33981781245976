import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Mapster from 'jsx/components/core/form/components/Mapster';
import {
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  zoomToBounds
} from 'jsx/components/core/form/lib/mapster';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { getVehicleColour, abortRequests } from '../../vehicles/lib/tracking';
import { getCropColour, getFarmBlockCrops, sliceBlock } from '../../season/lib/season';
import { sliceCropPolygon} from '../actions/harvesting';

import FormInputSmall from 'jsx/components/core/form/components/FormInputSmall';

import arrowUpIcon from '../../../../../images/canecutter/arrow-up.png';

class HarvestActivityMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      map: null,
      farm: {},
      abortController: new AbortController(),
      featureFetching: 0,
      timestampBlocks: new Date().getTime(),
      showLegend: true
    };

    this.resetGeometry = this.resetGeometry.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.setFarmBlockMap = this.setFarmBlockMap.bind(this);
    this.slicePolygon = this.slicePolygon.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {selectedBlock} = this.props.season;

    if (prevProps.season.selectedBlock.id !== selectedBlock.id) {
      this.setFarmBlockMap();
      this.setLoggerTracksMap();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  resetGeometry() {
    const mapSources = [
      {
        id: 'farms',
        layer_id: 'farm-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farms',
        style: 'Polygon',
        onClick: null,
        minzoom: 5,
        maxzoom: 22
      },
      {
        id: 'crops',
        layer_id: 'crops-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farm Block Crops',
        style: 'Polygon',
        onClick: null,
        minzoom: 11,
        maxzoom: 22
      },
      {
        id: 'splits',
        layer_id: 'splits-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farm Block Crop Splits',
        style: 'Polygon',
        onClick: this.selectCropFeature,
        minzoom: 11,
        maxzoom: 22
      },
      {
        id: 'blocks',
        layer_id: 'block-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Selected Block',
        style: 'Polygon',
        onClick: null,
        minzoom: 11,
        maxzoom: 22
      },
      {
        id: 'block-labels',
        layer_id: 'block-labels',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Block Labels',
        style: 'PointLabel',
        onClick: null,
        minzoom: 13,
        maxzoom: 22
      },
      {
        id: 'tracks',
        layer_id: 'track-symbols',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Tracks',
        style: 'PointSymbol',
        maxzoom: 24,
        minzoom: 14,
        // onClick: () => console.log("hover"),
        // onMouseEnter: () => console.log("hover")
      },
    ];

    return mapSources;
  }

  async refetch(event) {
    await abortRequests(this, 12);
    getFarmBlockCrops(this, event.type);
  }

  setCropStatus(status_tag) {
    const featureAttributes = this.props.mapster.attributes;
    const { selectedFeature, map } = this.state;

    // map.setFeatureState(
    //   {source: selectedFeature.source, id: selectedFeature.id},
    //   {
    //     status_tag,
    //     crop_fill: featureAttributes.find(attribute => attribute.id === 'block_fill_crop').value,
    //     harvest_fill: featureAttributes.find(attribute => attribute.id === 'block_fill_harvested').value
    //   }
    // )
  }

  async selectCropFeature(feature, map) {
    console.log("selected feature", feature);
    map.setFeatureState({source: feature.source, id: feature.id}, {clicked: true})
    // this.setState({selectedFeature: feature})
  }

  async slicePolygon(event) {
    const {selectedBlock} = this.props.season;

    const data = {
      id: selectedBlock.id,
      geometry: selectedBlock.geom,
      blade: event.features[0].geometry
    }

    const polygons = await this.props.dispatch(sliceCropPolygon(data));
    this.drawSplitPolygons(polygons);
    console.log("result", event);
  }

  async drawSplitPolygons(polygons) {
    const { mapSources, map } = this.state;
    const featureAttributes = this.props.mapster.attributes;

    const idx = mapSources.findIndex((source) => source.id === 'splits');
    const featureCollection = buildFeatureCollection();
    polygons[0][0].st_split.geometries.map((geometry, index) => {
      const feature = buildFeature(geometry, {
        colour: featureAttributes.find(attribute => attribute.id === 'block_fill_crop').value,
        outline: 'red',
        strokeWidth: 5,
        id: index + 1
      });
      featureCollection.features.push(feature);
    });

    mapSources[idx].source = featureCollection;
    map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
  }

  async onMapLoad(map) {
    const mapSources = await this.resetGeometry();
  
    map.loadImage(arrowUpIcon, (error, image) => {
      if (error) throw error;
      map.addImage('arrowUpIcon', image, { 'sdf': true });
    });

    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' }
      });

      mapSource.layers = [];
      mapSource.markers = [];

      map.on('zoomend', (event) => {
        this.refetch(event);
      });
  
      map.on('dragend', (event) => {
        this.refetch(event);
      });
    
      // Set layers
      const layer = getDefaultLayer(mapSource);
      map.addLayer(layer);

      mapSource.layers.push(layer);

      if (mapSource.onClick) {
        map.on('click', mapSource.layer_id, (event) => {
          mapSource.onClick(event.features[0], map);
        });
      }

      // Load onMouseEnter event if defined
      if (mapSource.onMouseEnter) {
        map.on('mouseenter', mapSource.layer_id, (event) => {
          mapSource.onMouseEnter(event.features[0]);
        });
      }

      // Load onMouseLeave event if defined
      if (mapSource.onMouseLeave) {
        map.on('mouseleave', mapSource.layer_id, (event) => {
          mapSource.onMouseLeave(event.features[0]);
        });
      }
    });

    map.moveLayer('block-boundaries', 'crops-boundaries' );
    map.moveLayer('crops-boundaries', 'block-labels');
    this.setState({ mapSources, map });
  };

  async createLegend() {
    const { selectedBlock } = this.props.season;
    const { munch } = this.props.crop;
    const selectedMunch = munch.find(row => row.id === selectedBlock.id);

    return (
      [
        // <div>Mark selected</div>,
        // <Button className="m-1" size="sm" color="success" onClick={() => this.setCropStatus('h')}>Harvest</Button>,
        // <Button className="m-1" size="sm" color="warning" onClick={() => this.setCropStatus('c')}>Crop</Button>,
        // <div className="d-flex justify-content-start m-1 mb-2 border-bottom border-lightgray">
        //   <input
        //     type="checkbox"
        //     handleChange={() => {}}
        //     checked
        //     // control={sectionControls.auto_load}
        //     // className="d-flex justify-content-start m-0"
        //     hideCaption
        //   />
        //   <div className="ml-1 mt-1">Show Tracks</div>
        // </div>,
        <div key={1}>Total Block (ha): <span>{selectedMunch.block_area_ha}</span></div>,
        <div key={2}>Harvested (ha): <span>{selectedMunch.munch_ha_harvested}</span></div>,
        <div key={3}>Harvested (t): <span>{selectedMunch.tonnes_harvested?.toFixed(2)}</span></div>,
        <div key={4}>Remaining (ha): <span>{selectedMunch.munch_ha_crop}</span></div>,
        <div key={5}>Remaining (t): <span>{selectedMunch.munch_tonnes_remaining?.toFixed(2)}</span></div>
      ]
    );

  }

  async setFarmBlockMap() {
    const { selectedBlock } = this.props.season;
    const { mapSources, map } = this.state;
    const { munch } = this.props.crop;

    // const featureColour = this.props.mapster.colours.farms;
    const featureAttributes = this.props.mapster.attributes;

    if (selectedBlock ) {
      const idx = mapSources.findIndex((source) => source.id === 'blocks');
      const labelIdx = mapSources.findIndex((source) => source.id === 'block-labels');

      const featureCollection = buildFeatureCollection();
      const labelCollection = buildFeatureCollection();

      const feature = buildFeature(selectedBlock.geom, {
        title: `${selectedBlock.name}`,
        colour: featureAttributes.find(attribute => attribute.id === 'block_fill').value,
        outline: featureAttributes.find(attribute => attribute.id === 'block_outline').value,
        strokeWidth: 5,
        labelCaption: selectedBlock.name,
        labelColour: 'red'
      });
      featureCollection.features.push(feature);

      // Label
      const featureLabel = buildFeature(selectedBlock.geom, {
        labelCaption: `${selectedBlock.name}\n${selectedBlock.variety_code}/${selectedBlock.class_code}`,
        labelColour: 'red',
        labelSize: 16
      });
      labelCollection.features.push(featureLabel);
 
      // Set legend
      mapSources[idx].legends = await this.createLegend();

      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);

      mapSources[labelIdx].source = labelCollection;
      map.getSource(mapSources[labelIdx].id).setData(mapSources[labelIdx].source);

      if (selectedBlock.geom.type === 'Polygon') {
        zoomToBounds(map, feature.geometry.coordinates[0], 100);
      } else {
        zoomToBounds(map, feature.geometry.coordinates[0][0], 100);
      }

      this.setState({mapSources});
    }
  }

  async setLoggerTracksMap() {
    const { tracks } = this.props.season.selectedBlock;
    const { mapSources, map } = this.state;
    const featureColour = this.props.mapster.colours.tracks;
    
    const idx = mapSources.findIndex((source) => source.id === 'tracks');
    const featureCollection = buildFeatureCollection();

    if (tracks && tracks.length > 0) {
      tracks.forEach((track) => {
          const feature = buildFeature(track.coords, {
            // id: track.id,
            // outline: featureColour?.outline,
            // fill: getVehicleColour(this, track.status_tag)
            icon: 'arrowUpIcon',
            iconRotation: track?.attributes?.direction,
            iconSize: 0.2,
            iconColour: getVehicleColour(this, track.status_tag),
          });

          featureCollection.features.push(feature);
      });
    }

    mapSources[idx].source = featureCollection;
    map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
  }

  render() {
    const { mapSources } = this.state;

    return (
      <Mapster
        mapSources={mapSources}
        onMapLoad={this.onMapLoad}
        drawCreate={this.slicePolygon}
        showDraw
        showLegend={this.props.showLegend}
      />
    );
  }
}

const mapStoreToProps = ({ realm, season, mapster, crop }) => ({ realm, season, mapster, crop });
export default connect(mapStoreToProps)(withContainerError(HarvestActivityMap));

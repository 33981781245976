import {
  apiTracks,
  apiLoggers,
  apiLoggerTypes
} from 'jsx/constants/api';

import { get, put, remove, post } from 'jsx/lib/genericAction';

export function fetchTracks(params) {
  return async (dispatch) => get(dispatch, 'FETCH_TRACKS', apiTracks, params);
}

export function fetchTrackStats(params) {
  return async (dispatch) => get(dispatch, 'FETCH_TRACK_STATS', `${apiTracks}/stats`, params);
}

export function fetchTrackOrphanCount(params) {
  return async (dispatch) => get(dispatch, 'FETCH_TRACK_COUNT', `${apiLoggers}/track/orphan/count`, params);
}

export function fetchTrackOrphanCountByLogger(params) {
  return async (dispatch) => get(dispatch, 'FETCH_TRACK_ORPHAN_COUNT_BYLOGGER', `${apiLoggers}/track/orphan/count/bylogger`, params);
}

export function fetchLoggerTypes() {
  return async (dispatch) => get(dispatch, 'FETCH_LOGGER_TYPES', apiLoggerTypes);
}

export function fetchLoggers(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_LOGGERS', apiLoggers, params);
}

export function fetchLogger(id) {
  return async (dispatch) => get(dispatch, 'FETCH_LOGGER', `${apiLoggers}/${id}`);
}

export function createLogger(data) {
  return async (dispatch) => post(dispatch, 'CREATE_LOGGER', `${apiLoggers}`, data);
}

export function updateLogger(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_LOGGER', `${apiLoggers}/${data.id}`, data);
}

export function removeLogger(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_LOGGER', `${apiLoggers}/${id}`);
}

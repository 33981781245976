import React from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { accessToken, mapStyle } from 'jsx/constants/map';
import Icon from 'jsx/components/core/icons/Icon';
import { getCollectionBoundary, zoomToBounds, getBoundary } from 'jsx/components/core/form/lib/mapster';

import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { HollowDotsSpinner } from 'react-epic-spinners';

mapboxgl.accessToken = accessToken;

class Mapster extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.state = {
      lng: 145.7333,
      lat: -16.1333,
      zoom: 6,
      map: null,
      mapSources: null,
      mapIsLoaded: false,
      mapStyle,
      showLegend: false,
      responseMessage: null,
      geocoderAddress: '',
      layerMenuItems: []
    };

    this.zoomToBounds = this.zoomToBounds.bind(this);
    this.toggleLegend = this.toggleLegend.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.dropDownLayerItems = this.dropDownLayerItems.bind(this);
    this.mapResize = this.mapResize.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: this.state.mapStyle,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metrix'
    });
    map.addControl(scale, 'bottom-right');

    const nav = new mapboxgl.NavigationControl({
      showCompass: true
    });
    map.addControl(nav, 'top-right');

    // Draw have a number of tools that are enabed in controls. Enabling only linestrings and trash here
    // Commented out until we have editability for farmblock_crops in cloud schema
    // if (this.props.showDraw) {
    //   var draw = new MapboxDraw({
    //     displayControlsDefault: false,
    //     controls: {
    //       // polygon: true,
    //       line_string: true,
    //       combine_features: true,
    //       trash: true
    //     },
    //     defaultMode: 'draw_line_string'
    //   });
    //   map.addControl(draw, 'bottom-left');

    //   if (this.props.drawCreate) {
    //     map.on('draw.create',(event) => {
    //       this.props.drawCreate(event);
    //       draw.deleteAll();
    //     })
    //   }

    //   if (this.props.drawDelete) {
    //     map.on('draw.delete', this.props.drawDelete);
    //     // map.on('draw.update', this.updateArea);
    //   }
    // }

    map.on('style.load', () => {
      console.log('map styleload');

      if (this.props.onMapLoad) {
        this.props.onMapLoad(map);
      }
    });

    map.on('load', () => {
      console.log('map onload')
      map.resize();
      // if (this.props.onMapLoad) {
      //   this.props.onMapLoad(map);
      // }
    });

    map.on('sourcedata', (event) => {
      if (event.sourceDataType === 'content' && event.isSourceLoaded) {
        this.dropDownLayerItems();
      }
    });

    map.on('resize', (event) => {
      console.log("resize")
    });

    this.setState({
      map
    });
  }

  zoomToBounds() {
    const {
      map
    } = this.state;

    let bounds = []
    const mapSources = this.props.mapSources;

    mapSources.forEach(mapSource => {
      if (map.getSource(mapSource.id)) {
        if (mapSource.source.type === 'Feature') {
          bounds = bounds.concat(getBoundary(mapSource.source));
        }
        if (mapSource.source.type === 'FeatureCollection') {
          bounds = bounds.concat(getCollectionBoundary(mapSource.source));
        }
      }
    });

    if (bounds.length > 0) {
      try {
        zoomToBounds(map, bounds);
      } catch (error) {
        if (!this.state.responseMessage) {
          this.setState({ responseMessage: "Error: Cannot zoom to features. Invalid geometry" })
        }
      }
    }
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  setStyle(style) {
    const { map } = this.state;
    const {
      mapStyle
    } = this.state;

    if (mapStyle !== style) {
      map.setStyle(style);

      this.setState({
        mapStyle: style
      });
    }
  }

  toggleLegend() {
    this.setState({
      showLegend: !this.state.showLegend
    });
  }

  handleLayerVisibility(idx) {
    const { mapSources } = this.props;
    const { map } = this.state;

    const layer = map.getLayer(mapSources[idx].layer_id);
    map.setLayoutProperty(mapSources[idx].layer_id, 'visibility', (layer.visibility === 'none' ? 'visible' : 'none'));

    this.dropDownLayerItems();
  }

  dropDownLayerItems () {
    const {
      map
    } = this.state;

    const {
      mapSources
    } = this.props;

    const layerMenuItems = mapSources.map((source, index) => {
      const name = source.title;
      let check;

      if (map) {
        const mapLayer = map.getLayer(mapSources[index].layer_id);

        if (mapLayer && mapLayer.visibility !== 'none') {
          check = (<Icon name="check" className="text-success mt-1" />);
        }
      }

      return (
        <DropdownItem
          onClick={() => this.handleLayerVisibility(index)}
          className="d-flex justify-content-between"
          key={index}
        ><span>{name}</span>{check
          }
        </DropdownItem>
      );
    });

    this.setState({layerMenuItems});
  }

  mapResize() {
    this.state.map.resize();
  }

  toggleMap() {
    const { fullMapScreen } = this.props.realm;
  
    if (fullMapScreen.display) {
      this.props.dispatch({ type: 'SET_MAP_FULLSCREEN', payload: {}});
    } else {
      this.props.dispatch({ type: 'SET_MAP_FULLSCREEN', payload: {display: 'none'} });
    }

    this.state.map.resize();
  }
  render() {
    const {
      mapStyle,
      responseMessage,
      showLegend,
      layerMenuItems
    } = this.state;

    const {
      fullMapScreen
    } = this.props.realm;

    const {
      mapSources
    } = this.props;

    const { defaults } = this.props.season;
    const mapStyles = this.props.mapster.styles;
    const expandIcon = (this.props.expandMap || fullMapScreen.display ? 'arrows-minimize' : 'arrows-maximize');

    const keys = Object.keys(mapStyles);
    const styles = keys.map((key, index) => {
      const name = key[0].toUpperCase() + key.slice(1);
      let check;
      if (mapStyles[key] === mapStyle) {
        check = (<Icon name="check" className="text-success mt-1" />);
      }
      return (
        <DropdownItem
          onClick={() => this.setStyle(mapStyles[key])}
          className="d-flex justify-content-between"
          key={index}
        ><span>{name}</span>{check
          }</DropdownItem>
      );
    });

    const legends = mapSources.map((source, index) => {
      if (source.legends ) return (
        <div key={index}>
          <h3>{source.title}</h3>
          {source.legends}
          <DropdownItem divider />
        </div>
      )
      else return false;
    }).filter(Boolean);

    const appTitle = `CANE CUTTER ${defaults?.active_season?.name}`;

    return (
      <div >
        <div className='mapsterBlock rounded d-flex justify-content-between'>
          <div className="d-flex">
            {/* {mapMessages} */}
            {responseMessage && (
              <div className="d-flex mt-3">
                <span>{`${responseMessage}`}</span>
              </div>
            )}
            {fullMapScreen.display && (appTitle)}
          </div>
          <div className='text-black d-flex justify-content-end'>
            {this.props.featureFetching > 0 && (<HollowDotsSpinner color="red" size={8} style={{marginTop: 6, marginRight: 40}} />)}

            <Icon style={{ cursor: 'pointer' }} name="rotate-right" onClick={this.mapResize} className="mr-2 mt-1" />
            {this.props.expandMap && (<Icon style={{ cursor: 'pointer' }} name="key" onClick={this.toggleLegend} className="mr-2 mt-1" />)}

            <UncontrolledDropdown className="p-0 m-0">
              <DropdownToggle
                className="p-0 m-0"
                tag="div"
                data-toggle="dropdown"
                aria-expanded
              >
                <Icon style={{ cursor: 'pointer' }} name="layer-group" className="ml-2 mr-2" />
              </DropdownToggle>
              <DropdownMenu right>
                {styles}
                <DropdownItem divider />
                {layerMenuItems}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Icon style={{ cursor: 'pointer' }} name="expand" onClick={this.zoomToBounds} className="mt-1 ml-2 mr-2" />
            <Icon style={{ cursor: 'pointer' }} name={expandIcon} onClick={this.toggleMap} className="mt-1 ml-2 mr-2" />

          </div>
        </div>

        <div ref={this.mapRef} className="mapster compass-only" />

        {this.props.showLegend && (
          <div className="p-2 mapsterLegend">
            {legends}
          </div>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    mapster: store.mapster,
    realm: store.realm,
    season: store.season
  }
}

export default connect(mapStoreToProps)(Mapster);

import axios from 'axios';
import {
  apiLogin,
  apiLogout,
  apiRegister,
  apiAssociate,
  apiVerify,
  apiInvitations,
  apiReset,
  apiForgot,
  apiRealm,
  apiMasquerade,
  apiUsers,
  apiRefresh,
} from '../../../../constants/api';
import errorResponse from '../../../../lib/errorResponse';
import { loginRequest, protectedResources } from '../constants/authConfigMicrosoft';
import { setAuthCookie } from '../lib/auth';

import { get, post, put } from '../../../../lib/genericAction';

import { fetchCurrentUser } from '../../../manage/actions/profile';

export function authenticated(valid) {
  return function (dispatch) {
    dispatch({ type: 'SET_AUTHENTICATED_FULFILLED', payload: valid });
  };
}

export function loginAzure(isAuthenticated, instance, accounts) {
  return async (dispatch) => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: protectedResources.canecutter.scopes,
      account: accounts[0],
    });

    if (response && response.accessToken) {
      axios.defaults.headers.common['ms-access-token'] = true;

      // Build Bearer from token
      const bearer = `Bearer ${response.accessToken}`;
      axios.defaults.headers.common['Authorization'] = bearer;
    }

    await dispatch(updateRealm());
    await dispatch(fetchCurrentUser());

    dispatch({ type: 'SET_AUTHENTICATION_TYPE', payload: 'azure' });
    dispatch({ type: 'SET_AUTHENTICATED_FULFILLED', payload: isAuthenticated });
  };
}

export function masqueradeRequest(id, target_app) {
  return async (dispatch) =>
    post(dispatch, 'REQUEST_MASQUERADE', `${apiMasquerade}/request`, {
      requestedfor_id: id,
      target_app,
    });
}

export function masquerade(id) {
  return function (dispatch) {
    dispatch({ type: 'FETCH_TOKEN_PENDING' });

    setAuthCookie();
    axios({
      method: 'POST',
      url: `${apiMasquerade}/${id}`,
    })
      .then(async (response) => {
        dispatch({ type: 'FETCH_TOKEN_FULFILLED', payload: { authenticated: true } });
        dispatch({ type: 'SET_MASQUERADE', payload: true });
        await dispatch(updateRealm());
        await dispatch(fetchCurrentUser());

        dispatch({ type: 'SET_AUTHENTICATION_TYPE', payload: 'local' });
      })
      .catch((err) => {
        errorResponse(dispatch, err, 'FETCH_TOKEN_REJECTED');
      });
  };
}

export function login(user) {
  return function (dispatch) {
    dispatch({ type: 'FETCH_TOKEN_PENDING' });

    setAuthCookie();
    axios({
      method: 'POST',
      url: apiLogin,
      data: user,
    })
      .then(async (response) => {
        dispatch({ type: 'FETCH_TOKEN_FULFILLED', payload: { authenticated: true } });
        await dispatch(updateRealm());
        await dispatch(fetchCurrentUser());

        dispatch({ type: 'SET_AUTHENTICATION_TYPE', payload: 'local' });
      })
      .catch((err) => {
        console.log("err", err)
        errorResponse(dispatch, err, 'FETCH_TOKEN_REJECTED');
      });
  };
}

export function refresh() {
  return async (dispatch) => get(dispatch, 'FETCH_REFRESH', apiRefresh);
}

export function updateRealm() {
  return async (dispatch) => get(dispatch, 'SET_REALM', `${apiRealm}/authenticated`);
}

export function verify(hash) {
  return async (dispatch) => put(dispatch, 'SET_VERIFY', apiVerify, { hash });
}

export function register(user) {
  return async (dispatch) => post(dispatch, 'REGISTRATION', apiRegister, user);
}

export function associate(invite) {
  return async (dispatch) => post(dispatch, 'ASSOCIATE_USER', `${apiAssociate}`, invite);
}

export function fetchInvite(id) {
  return async (dispatch) => get(dispatch, 'FETCH_INVITE', `${apiInvitations}/${id}`);
}

export function fetchHashUser(reset_hash) {
  return async (dispatch) => get(dispatch, 'FETCH_HASH_USER', apiReset, { reset_hash });
}

export function updatePassword(user) {
  return async (dispatch) => put(dispatch, 'UPDATE_PASSWORD', apiReset, user);
}

export function logout() {
  return function (dispatch) {
    dispatch({ type: 'SET_LOGOUT_PENDING' });

    document.cookie = 'x-access-token=; Max-Age=0';
    document.cookie = 'ms-access-token=; Max-Age=0';

    axios({
      method: 'DELETE',
      url: apiLogout,
    })
      .then(async (response) => {
        dispatch({ type: 'FETCH_TOKEN_FULFILLED', payload: { authenticated: false } });
      })
      .catch((err) => {
        errorResponse(dispatch, err, 'FETCH_TOKEN_REJECTED');
      });

    axios.defaults.headers.common = {};

    dispatch({ type: 'SET_LOGOUT_FULFILLED' });
  };
}

export function forgot(email, isChildAppResetPassword = false) {
  return async (dispatch) =>
    post(dispatch, 'FORGOT', apiForgot, { email, isChildAppResetPassword });
}

export function generatePasswordResetLink(email) {
  return async (dispatch) =>
    post(dispatch, 'GENERATE_PASSWORD_RESET_LINK', `${apiUsers}/password/reset_link`, email);
}

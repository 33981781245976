import React from 'react';
import { Button, Label, Input } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import Icon from 'jsx/components/core/icons/Icon';

const LoggerToolbar = (props) => {
  const {
    onRefresh,
    toolbarLabel,
    onAdd,
    handleSearchChange,
    onSearch,
    handleCheckboxChange,
    checked
  } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-between">
        <Label >{toolbarLabel}</Label>
        <div className="d-flex ">
          <div className="mt-1"><Input  type="checkbox" onChange={handleCheckboxChange} checked={checked}/></div>
          <Label className="m-0 p-0 mt-1 mr-4" >Hide Disabled</Label>

          <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch}/>
          {/* <Button color="success" size="sm" onClick={() => onAdd(null)} className="ml-1 mr-1"><Icon name="plus" className="m-0" disabled /></Button> */}
          <Button color="success" size="sm" onClick={onRefresh} className="ml-1 mr-1">Refresh</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoggerToolbar;

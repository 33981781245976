import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  firstname: {
    ...controlDefaults,
    name: 'firstname',
    type: 'text',
    caption: 'First Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  lastname: {
    ...controlDefaults,
    name: 'lastname',
    type: 'text',
    caption: 'Last Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  email: {
    ...controlDefaults,
    name: 'email',
    type: 'text',
    caption: 'Email',
    disabled: false,
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
  },
  // mobile: {
  //   ...controlDefaults,
  //   name: 'mobile',
  //   type: 'text',
  //   caption: 'Mobile',
  //   disabled: false,
  //   validationRules: {
  //     isRequired: false,
  //     minLength: 10,
  //     isNumeric: true,
  //   },
  // },
  default_org_id: {
    ...controlDefaults,
    name: 'default_org_id',
    type: 'select',
    caption: 'Invite to',
    disabled: false,
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
};

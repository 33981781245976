import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';

const SeasonConfigLsv = (props) => {
  const {
    rows,
    handleChange,
    saveChange,
    cancelChange
  } = props;

  const renderInput = (row) => {
    return (
      <div>
        <input name={row.tag} onChange={handleChange} style={{maxWidth: 100, textAlign: 'right'}} value={row.value}/> 
        {row.editing && (
          <>
            <Icon size="1x" name="check" className="ml-2 text-success" onClick={() => saveChange(row)} />
            <Icon size="1x" name="xmark" className="ml-2 text-danger" onClick={() => cancelChange(row)} />
          </>
        )}

      </div>
    );
  }

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'value': return (
          <td key={index} className={header.classes}>{renderInput(row)}</td>
        )
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Field', field: 'caption', classes:'text-left'},
    {caption: 'Value', field: 'value', classes:'text-right'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='tags';
  const emptyCaption='No Attributes found';

  return (
    <React.Fragment>
      <Listview hideHeader rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default SeasonConfigLsv;

import { allowed, defaults } from 'jsx/lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    activeSettingsTab: null,
    authorised: true,
    authResponseMessage: null,
    error: null,
    responseMessage: null,
    rakes: [],
    statistics: [],
    cropStatistics: [],
    munch: []
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'RAKE',
    'MUNCH'
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };
  
  switch (action.type) {
    case 'FETCH_RAKES_FULFILLED': {
      return {
        ...updatedState,
        rakes: action.payload,
      };
    }
    
    case 'FETCH_FARM_BLOCK_CROP_STATISTICS': {
      return {
        ...updatedState,
        cropStatistics: action.payload,
      };
    }

    case 'FETCH_RAKE_STATISTICS_FULFILLED': {
      return {
        ...updatedState,
        statistics: action.payload
      };
    }

    case 'FETCH_MUNCH_FULFILLED': {
      return {
        ...updatedState,
        munch: action.payload,
      };
    }
    
    default:
      break;
  }

  return updatedState;
}

import {
  apiRakes,
} from 'jsx/constants/api';

import { get } from 'jsx/lib/genericAction';

export function fetchRakes(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_RAKES', apiRakes, params);
}

export function fetchRakeStatistics(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_RAKE_STATISTICS', `${apiRakes}/statistics`, params);
}
import { allowed, defaults } from 'jsx/lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    activeSettingsTab: null,
    authorised: true,
    authResponseMessage: null,
    error: null,
    responseMessage: null,
    vehicles: [],
    tracks: [],
    vehicle_tracks: [],
    types: [],
    statuses: [],
    owners: [],
    filters: {
      type_id: [],
      status_id: [],
      harvestgroup_id: [],
      inspector_id: [],
      enabled: null,
      assigned_mill_id: []
    },
    params: {},
    inspectors: [],
    searchValue: null,
    settings: {
      tailMinutes: 30,
      showVehicleLabels: true,
      zoomToBoundary: false
    }
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'VEHICLE', 'INSPECTOR', 'TRACK', 'SETTINGS'
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UPDATE_SETTINGS_FULFILLED': {
      return {
        ...updatedState,
        settings: action.payload,
      };
    }
    case 'UPDATE_VEHICLE_SEARCHVALUE_FULFILLED': {
      return {
        ...updatedState,
        searchValue: action.payload,
      };
    }
    case 'FETCH_VEHICLES_FULFILLED': {
      return {
        ...updatedState,
        vehicles: action.payload,
      };
    }
    case 'FETCH_VEHICLE_TYPES_FULFILLED': {
      return {
        ...updatedState,
        types: action.payload,
      };
    }
    case 'FETCH_VEHICLE_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        statuses: action.payload,
      };
    }
    case 'FETCH_VEHICLE_OWNERS_FULFILLED': {
      return {
        ...updatedState,
        owners: action.payload,
      };
    }
    case 'FETCH_INSPECTORS_FULFILLED': {
      return {
        ...updatedState,
        inspectors: action.payload,
      };
    }
    case 'FETCH_TRACKS_FULFILLED': {
      return {
        ...updatedState,
        tracks: action.payload,
      };
    }
    case 'FETCH_VEHICLE_TRACKS_FULFILLED': {
      const { vehicle_tracks } = state;
      vehicle_tracks.concat(action.payload);
      return {
        ...updatedState,
        vehicle_tracks
      };
    }
    case 'SET_VEHICLE_FILTERS_FULFILLED': {
      return {
        ...updatedState,
        filters: action.payload,
      };
    }
    case 'UPDATE_CURRENT_TRACK_TOPIC': {
      const { vehicles } = state;
      const vehicleIdx = state.vehicles.findIndex( v => v.id === action.payload.track.vehicle_id);
      // const { tracks } = state;

      if (vehicleIdx !== -1) {
        const vehicle = vehicles[vehicleIdx];

        vehicle.last_track = action.payload.track;
        vehicle.last_track.status = action.payload.status;
        vehicle.current_track_id = action.payload.track.id;
        vehicle.status_id = action.payload.status.id;
        vehicle.status = action.payload.status;

        vehicles[vehicleIdx] = vehicle;
        // tracks.push(vehicle.last_track);
      }

      return {
        ...updatedState,
        vehicles,
        // tracks
      };
    }
    default:
      break;
  }

  return updatedState;
}

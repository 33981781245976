import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  enabled: {
    ...controlDefaults,
    placeholder: '',
    name: 'enabled',
    type: 'checkbox',
    caption: 'Active Vehicle',
    checked: false
  },
  show_vehicle_labels: {
    ...controlDefaults,
    placeholder: '',
    name: 'show_vehicle_labels',
    type: 'checkbox',
    caption: 'Show Vehicle Labels'
  },
  zoom_to_boundary: {
    ...controlDefaults,
    placeholder: '',
    name: 'zoom_to_boundary',
    type: 'checkbox',
    caption: 'Zoom to Boundary on Update'
  },
  tail_minutes: {
    ...controlDefaults,
    placeholder: '',
    name: 'tail_minutes',
    type: 'text',
    caption: 'Track Length (Minutes)'
  },
  auto_load: {
    ...controlDefaults,
    placeholder: '',
    name: 'auto_load',
    type: 'checkbox',
    caption: 'Auto Load every 20min'
  },
};

import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { fetchFarmAssocs, fetchAssoc } from '../actions';
import GroupFarmsLsv from '../components/GroupFarmsLsv';
import GroupFarmsToolbar from '../components/GroupFarmsToolbar';
import FarmAssocModal from './FarmAssocModal';

class GroupFarms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {},
      isModalOpen: false,
      id: null,
      searchValue: null
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  setModal(row) {
    this.setState({isModalOpen: !this.state.isModalOpen, id: row?.id});
  }

  // eslint-disable-next-line class-methods-use-this
  onFilterChange(event) {
    const { params } = this.state;

    params[event.target.id] = event.target.value;

    if (event.keyCode === 13) {
      this.props.dispatch(fetchFarmAssocs(params));
    }

    this.setState({params});
  }

  render() {
    const {farmassocs} = this.props.season;
    const {isModalOpen, id, params} = this.state;

    return (
      <div>
        <GroupFarmsToolbar onAdd={this.setModal} onFilterChange={this.onFilterChange} />
        <FarmAssocModal setModal={this.setModal} id={id} isOpen={isModalOpen} params={params}/>

        <GroupFarmsLsv rows={farmassocs} onClick={this.setModal} />
      </div>
    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(GroupFarms));

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';

const GroupFarmsLsv = (props) => {
  const {
    rows,
    onClick
  } = props;


  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'group': return  (
          <td key={index} className={header.classes}>{row.harvestgroup?.name}</td>
        );
        case 'farm': return  (
          <td key={index} className={header.classes}>{row.farm?.name}</td>
        );
        case 'owner': return  (
          <td key={index} className={header.classes}>{row.owner?.name}</td>
        );
        case 'farm_no': return  (
          <td key={index} className={header.classes}>{row.farm?.farm_no}</td>
        );
        case 'mill': return  (
          <td key={index} className={header.classes}>{row.assigned_mill?.tag?.toUpperCase()}</td>
        );
        case 'season': return  (
          <td key={index} className={header.classes}>{row.season?.name}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Harvest Group', field: 'group', classes:'text-left'},
    {caption: 'Farm No', field: 'farm_no', classes:'text-left'},
    {caption: 'Grower', field: 'owner', classes:'text-left'},
    {caption: 'Season', field: 'season', classes:'text-left'},
    {caption: 'Assigned Mill', field: 'mill', classes:'text-left'},
    {caption: 'Fee/ha', field: 'harvest_ha_fee', classes:'text-right', style: {width: 80}},
    {caption: 'Fee/Tonne', field: 'haulage_ton_fee', classes:'text-right', style: {width: 80}}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onClick(row)}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='tractor';
  const emptyCaption='No Group/Farm Associations found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default GroupFarmsLsv;

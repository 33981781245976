import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  imei: {
    ...controlDefaults,
    placeholder: 'IMEI',
    name: 'imei',
    type: 'text',
    caption: 'Device IMEI',
    validationRules: {
      isRequired: true
    },
    description: 'Uniqiue device identifier for logger. This is a required field'
  },
  mobile_no: {
    ...controlDefaults,
    placeholder: 'Mobile No for Logger',
    name: 'mobile_no',
    type: 'text',
    caption: 'Mobile No',
    validationRules: {
      isRequired: false
    }
  },
  enabled: {
    ...controlDefaults,
    placeholder: '',
    name: 'enabled',
    type: 'select',
    caption: 'Enabled Logger',
    options:[
      {id: 0, name: 'No'},
      {id: 1, name: 'Yes'}
    ]
  },
  sim_imei: {
    ...controlDefaults,
    placeholder: 'SIM Imei',
    name: 'sim_imei',
    type: 'text',
    caption: 'Sim IMEI',
    validationRules: {
      isRequired: true
    },
    description: 'Uniqiue device identifier for SIM card. This is a required field'
  },
  pwd: {
    ...controlDefaults,
    placeholder: 'Logger Pin',
    name: 'pwd',
    type: 'text',
    caption: 'Pin',
    validationRules: {
      isRequired: false
    },
    description: 'Device pin prefixed in SMS communications with logger.'
  },
  owner_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'owner_id',
    type: 'select',
    caption: 'Owner',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Owner of Logger. This is a required field'
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_id',
    type: 'select',
    caption: 'Model',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Manufacturer Model. This is a required field'
  },
};

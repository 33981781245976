import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormInputCheckbox from 'jsx/components/core/form/components/FormInputCheckbox';

import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls as loggerControls } from '../forms/logger';

import {
  createLogger,
  fetchLogger,
  updateLogger,
  removeLogger,
  fetchLoggers
} from '../actions/logger';

class LoggerModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Vehicle',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(loggerControls),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    const { fetching } = this.props.vehicles;
    const { mills } = this.props.season;
    const { types } = this.props.loggers;
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false && !fetching) {
      this.setState({isOpen: this.props.isOpen});

      isNew = true;
      title = 'New Logger';
      controls = initControls(cloneDeep(loggerControls));

      id = null;
      data = {};

      // Load combos
      controls.type_id.options = controls.type_id.options.concat(types.map(type => ({id: type.id, name: type.name})));
      controls.owner_id.options = controls.owner_id.options.concat(mills.map(mill => ({id: mill.id, name: mill.name})));

      // load row if exists
      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Logger';

        data = await this.props.dispatch(fetchLogger(id));
        controls = updateControls(controls, data);
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      });
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;
    const { filters, params} = this.props.loggers;
    const formData = saveControls(controls, data);

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createLogger(formData));
    } else {
      success = await this.props.dispatch(updateLogger(formData));
    }

    if (success) {
      this.props.dispatch(fetchLoggers(params, filters));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;
    const { filters, params} = this.props.loggers;

    const confirmed = window.confirm(
      'Removing Logger permanently. If tracks already exist for this logger, this action will abort. Vehicles associated with this logger will NOT be removed. \n\nThis action can not be undone. Continue?',
    );

    if (confirmed) {
      const success = await this.props.dispatch(removeLogger(data.id));
      if (success) {
        this.props.dispatch(fetchLoggers(params, filters));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.loggers;

    const iconName = 'radio';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.imei} />
            <FormInput handleChange={this.handleChange} control={controls.type_id} />
            <FormInput handleChange={this.handleChange} control={controls.owner_id} />
            <FormInput handleChange={this.handleChange} control={controls.mobile_no} />
            <FormInput handleChange={this.handleChange} control={controls.sim_imei} />
            <FormInput handleChange={this.handleChange} control={controls.pwd} />
            <FormInputCheckbox handleChange={this.handleChange} control={controls.enabled} />
          </Form>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>Save</Button>
            <Button size="sm" color="light" onClick={this.onCancel}>Cancel</Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>Delete</Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season }) => ({
  vehicles,
  loggers,
  season
});

export default connect(mapStoreToProps)(LoggerModal);

import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import LoggerToolbar from '../components/LoggerToolbar';
import LoggersLsv from '../components/LoggersLsv';
import LoggerModal from './LoggerModal';

import { fetchLoggers, fetchLoggerTypes } from '../actions/logger';
import { fetchMills } from '../../season/actions';

const HEIGHT_OF_HEADER = '250px';

class LoggerPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      id: null,
      searchValue: null,
      hideDisabled: true
    };

    this.setModal = this.setModal.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.loadLoggers = this.loadLoggers.bind(this);
  }

  async componentDidMount() {
    this.loadLoggers();
    this.props.dispatch(fetchLoggerTypes());
  }

  loadLoggers() {
    const { params, filters } = this.props.loggers;
    this.props.dispatch(fetchLoggers(params, filters));
  }

  setModal(row) {
    this.setState({isModalOpen: !this.state.isModalOpen, id: row?.id});
  }

  handleSearchChange(event) {
    this.setState({searchValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  handleCheckboxChange() {
    const { params, filters } = this.props.loggers;
    let { hideDisabled } = this.state;

    if (hideDisabled) {
      hideDisabled = false;
      delete filters.enabled;
      delete params.filter_enabled;
      
    } else {
      hideDisabled = true;
      filters.enabled = true;
    }

    this.props.dispatch({ type: 'SET_LOGGER_FILTERS', payload: filters });
    this.setState({hideDisabled});
    this.props.dispatch(fetchLoggers(params, filters));
  }

  onSearch() {
    const {
      filters,
      params
    } = this.props.loggers;

    const {
      searchValue
    } = this.state;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchLoggers(params, filters));
  }

  render() {
    const { loggers } = this.props.loggers;

    const { isModalOpen, id, hideDisabled } = this.state;

    const toolbarLabel = `${loggers.length} ${(loggers.length === 1 ? 'logger' : 'loggers')}`;

    return (
      <div className="p-3 form">
        <LoggerToolbar
          toolbarLabel={toolbarLabel}
          onRefresh={this.loadLoggers}
          onAdd={this.setModal}
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          checked={hideDisabled}
          handleCheckboxChange={this.handleCheckboxChange}
        />
        <LoggerModal setModal={this.setModal} id={id} isOpen={isModalOpen} />

        <div style={{overflowY: 'auto', height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} >
          <LoggersLsv rows={loggers} onRowClick={this.setModal} />
        </div> 
      </div>
    );
  }
}

const mapStoreToProps = ({ loggers }) => ({ loggers });
export default connect(mapStoreToProps)(withContainerError(LoggerPanel));

import {
  apiVehicles,
  apiVehicleTypes,
  apiVehicleStatuses,
  apiVehicleOwners,
  apiInspectors
} from 'jsx/constants/api';

import { get, put, remove, post } from 'jsx/lib/genericAction';

export function fetchVehicles(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    });
  }

  return async (dispatch) => get(dispatch, 'FETCH_VEHICLES', apiVehicles, params);
}

export function fetchVehicleTracks(params, inBackground = true, abortController = null) {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE_TRACKS', `${apiVehicles}/tracks`, params, null, inBackground, abortController);
}

export function fetchVehicle(id) {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE', `${apiVehicles}/${id}`);
}

export function fetchVehicleTrackOrphanCount(params) {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE_TRACK_COUNT', `${apiVehicles}/track/orphan/count`, params);
}

export function createVehicle(data) {
  return async (dispatch) => post(dispatch, 'CREATE_VEHICLE', `${apiVehicles}`, data);
}

export function updateVehicle(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_VEHICLE', `${apiVehicles}/${data.id}`, data);
}

export function removeVehicle(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_VEHICLE', `${apiVehicles}/${id}`);
}

export function fetchVehicleTypes() {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE_TYPES', apiVehicleTypes);
}

export function fetchVehicleStatuses() {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE_STATUSES', apiVehicleStatuses);
}

export function fetchVehicleOwners() {
  return async (dispatch) => get(dispatch, 'FETCH_VEHICLE_OWNERS', apiVehicleOwners);
}

export function fetchInspectors() {
  return async (dispatch) => get(dispatch, 'FETCH_INSPECTORS', apiInspectors);
}

export function setVehicleFilters(params, filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_VEHICLE_FILTERS', payload: filters });
    // dispatch(fetchVehicles(params, filters));
  };
}

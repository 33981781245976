import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Component } from 'react';
import { connect } from 'react-redux';
import SessionRefreshModal from 'jsx/components/core/authentication/components/SessionRefreshModal';
import { msalConfig } from '../components/core/authentication/constants/authConfigMicrosoft';
import RealmLoader from '../components/core/loader/components/RealmLoader';
// import SocketReducer from '../lib/socketReducer';
import AppRoutes from './AppRoutes';

import { fetchMode, setRealm } from '../actions';
import { fetchSeasonDefaults } from 'jsx/components/modules/season/actions';

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

class App extends Component {
  componentDidMount() {
    this.refreshRealm();

    // // Start socket client for updating reducers
    // if (process.env.REACT_APP_DISABLE_SOCKET_CONNECTION !== 'true') {
    //   this.socketReducer = new SocketReducer(this.props);
    // }
  }

  componentDidUpdate(prevProps) {
    // Refresh realm if reducer has been reset.
    if (prevProps.realm.currentApp.id && !this.props.realm.currentApp.id) this.refreshRealm();
  }

  refreshRealm() {
    this.props.dispatch(setRealm());
    this.props.dispatch(fetchMode());

    this.configureDefaults();
  }

  async configureDefaults() {
    const { attributes } = this.props.mapster;

    // Fetch defaults
    const defaults = await this.props.dispatch(fetchSeasonDefaults());

    // mapster reducer is master list of attributes. Ensure that defaults are up to date.
    // eslint-disable-next-line array-callback-return
    attributes.map(attribute => {
      const defaultAttribute = defaults.map_styles.find(mapstyle => mapstyle.id === attribute.id);
      if (!defaultAttribute) {
        defaults.map_styles.push(attribute);
      }
    });

    // Set reducers
    this.props.dispatch({ type: 'SET_MAP_STYLES', payload: defaults.map_styles });
    this.props.dispatch({ type: 'FETCH_SEASON_DEFAULTS_FULFILLED', payload: defaults });
  }

  render() {
    const { loading, auth, realm } = this.props;
    const { authenticated } = auth;
    const realmFound = realm.currentApp.id !== null;

    return (
      <QueryClientProvider client={queryClient}>
        <RealmLoader found={realmFound} />
        <SessionRefreshModal isAuthenticated={authenticated} />
        {realmFound && (
          <MsalProvider instance={msalInstance}>
            <AppRoutes authenticated={authenticated} loading={loading} />
          </MsalProvider>
        )}
      </QueryClientProvider>
    );
  }
}

const mapStoreToProps = ({ ajaxRequests, auth, realm, season, mapster }) => ({
  loading: ajaxRequests.calls > 0,
  auth,
  realm,
  season,
  mapster
});

export default connect(mapStoreToProps)(App);

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import FarmBlocksLsv from './FarmBlocksLsv';
import FarmsRow from './FarmsRow';

const FarmsLsv = (props) => {
  const {
    rows,
    onRowClick,
    selectedFarm
  } = props;

  const renderRows = (headers, row) => {

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'owner': return  (
          <td key={index} className={header.classes}>{row.assocs[0]?.owner?.name}</td>
        );
        case 'harvestgroup': return  (
          <td key={index} className={header.classes}>{row.assocs[0]?.harvestgroup?.name}</td>
        );
        case 'season': return  (
          <td key={index} className={header.classes}>{row.assocs[0]?.season?.name}</td>
        );
        case 'mill': return  (
          <td key={index} className={header.classes}>{row.assocs[0]?.assigned_mill?.tag?.toUpperCase()}</td>
        );
        case 'block_count': return  (
          <td key={index} className={header.classes}>{row.blocks?.length}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Farm No', field: 'farm_no', classes:'text-left', style:{width: 100}},
    {caption: 'Farm Name', field: 'name', classes:'text-left'},
    {caption: 'Season', field: 'season', classes:'text-left', style:{width: 100}},
    {caption: 'Mill', field: 'mill', classes:'text-left', style:{width: 100}},
    {caption: 'Grower', field: 'owner', classes:'text-left'},
    {caption: 'Harvest Group', field: 'harvestgroup', classes:'text-left'},
    {caption: 'Block #', field: 'block_count', classes:'text-right', style:{width: 60}},
    {caption: 'Area (ha)', field: 'area_ha', classes:'text-right', style:{width: 100}}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      const rowClass = (selectedFarm.id === row.id ? 'bg-success text-white' : null);
      return (
        <React.Fragment key={index}>
          <tr onClick={() => onRowClick(row)} className={rowClass}>
            {renderRows(headers, row)}
          </tr>

          {selectedFarm.id === row.id && (
            <tr>
              <td colSpan={headers.length} className="p-0">
                <FarmBlocksLsv rows={selectedFarm.blocks} />
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  }

  const iconName='tractor';
  const emptyCaption='No Farms found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default FarmsLsv;

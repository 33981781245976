import React, { useCallback, useMemo } from 'react';
import { Input } from 'reactstrap';
import { noop } from 'lodash';
import PillPresenter from './PillPresenter';
import FormInputSelect from './FormInputSelect';

/**
 * Renders a dropdown list of options, and a list of pills representing the current selection
 */
const PillPicker = ({
  rows,
  selection,
  name,
  handleChange = noop,
  handleDelete = noop,
  caption = '',
  showPills,
  inputSearch = false,
  defaultPillColour
}) => {
  const allRows = useMemo(() => {
    const findBlank = rows.find((row) => row.id === '');
    if (findBlank) {
      return rows;
    } else {
      return rows.concat({ id: '', name: 'Select to Filter' });
    }
  }, [rows]);

  const options = useMemo(
    () =>
      allRows.map(({ id, name: optionName }) => (
        <option key={id} value={id}>
          {optionName}
        </option>
      )),
    [allRows],
  );

  const control = {
    options: allRows,
    type: 'select',
    name,
    id: name,
    value: '',
  };

  const filterCaption = `${caption} - ${selection?.length} active`;

  return (
    <div className="mb-2 clearfix">
      <small className="p-1">{filterCaption}</small>
      {inputSearch ? (
        <FormInputSelect
          handleChange={
            handleChange
              ? ({ value }) => {
                  const event = {
                    target: {
                      name,
                      value,
                    },
                  };
                  handleChange(event);
                }
              : undefined
          }
          control={control}
        />
      ) : (
        <Input
          type={control.type}
          name={control.name}
          id={control.name}
          value={control.value}
          onChange={handleChange}
        >
          {options}
        </Input>
      )}
      {showPills && (
        <div className="mb-2">
          <div className="pb-1">
            <PillPresenter rows={rows} selection={selection} handleDelete={handleDelete} pickerName={name} defaultPillColour={defaultPillColour} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PillPicker;

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';

const FarmBlocksLsv = (props) => {
  const {
    rows
  } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'owner': return  (
          <td key={index} className={header.classes}>{row.owner?.name}</td>
        );
        case 'harvestgroup': return  (
          <td key={index} className={header.classes}>{row.harvestgroup?.name}</td>
        );
        case 'season': return  (
          <td key={index} className={header.classes}>{row.season?.name}</td>
        );
        case 'mill': return  (
          <td key={index} className={header.classes}>{row.assigned_mill?.tag?.toUpperCase()}</td>
        );
        case 'block_count': return  (
          <td key={index} className={header.classes}>{row.blocks?.length}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Block No', field: 'block_no', classes:'text-left', style:{width: 100}},
    {caption: 'SubBlock No', field: 'sub_block_no', classes:'text-left'},
    {caption: 'Bed No', field: 'bed_no', classes:'text-left', style:{width: 100}},
    {caption: 'Class', field: 'class_code', classes:'text-left', style:{width: 100}},
    {caption: 'Variety', field: 'variety_code', classes:'text-left'},
    {caption: 'Area (ha)', field: 'area_ha', classes:'text-right', style:{width: 100}}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      return (
        <tr key={index} >
          {renderRows(headers, row)}
        </tr>
      );
    });
  }

  const iconName='draw-polygon';
  const emptyCaption='No Blocks found';

  return (
    <React.Fragment>
      <Listview darkTable rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default FarmBlocksLsv;

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { Row, Col } from 'reactstrap';

const TracksLsv = (props) => {
  const {
    rows,
    searchValue,
    maxUpdated,
    toggleVisibleAttributes,
    visibleAttributes,
    loggers,
    vehicles
  } = props;

  const renderRowAttributes = (attributes) => {
    const keys = Object.keys(attributes).sort();
    return keys?.map((key, index) => (
        <Row key={index} className="text-success d-flex justify-content-between p-0 m-0 bg-light w-100 border-bottom border-lightgray">
          <Col className="bg-secondary text-white p-1 m-0" style={{maxWidth: 150}}>{key}: </Col>
          <Col className="p-1 text-left text-nowrap overflow-hidden">{JSON.stringify(attributes[key])}</Col>
        </Row>
      )
    );
  };

  const renderRows = (headers, row) => {

    if (!row?.logger && row.logger_id) {
      const device = loggers.find( logger => logger.id === row.logger_id);
      row.logger = device;
    }

    if (!row?.vehicle && row.vehicle_id) {
      const trackVehicle = vehicles.find( vehicle => vehicle.id === row.vehicle_id);
      row.vehicle = trackVehicle;
    }
    
    // let imei = row?.attributes?.imei;
    let imei = 'None';
    if (row.logger) {
      imei = row.logger.imei;
    }

    let vehicle_name = 'Orphaned Logger, missing Vehicle';
    let vehicle_style = {color: 'red'};
    if (row.vehicle) {
      vehicle_name = row.vehicle.name;
      vehicle_style = {color: 'green'};
    }

    let coords = 'None';
    if (row.coords.coordinates[0] === 0) return false;

    if (row.coords) {
      coords = `Lng: ${row.coords.coordinates[0]}, Lat: ${row.coords.coordinates[1]}`;
    }

    const updated_ago = moment.utc(row.created).local().fromNow();
    const created = moment.utc(row.created).local().format('YYYY-MM-DD HH:mm:ss');
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'imei':
          return  (
            <td key={index} className={header.classes}>{imei}</td>
          );
        case 'vehicle_name':
          return  (
            <td key={index} className={header.classes} style={vehicle_style}>{vehicle_name}</td>
          );
        case 'coords':
          return  (
            <td key={index} className={header.classes}>{coords}</td>
          );
        case 'updated': return  (
            <td key={index} className={header.classes} >{updated_ago}</td>
          );
        case 'created': return  (
          <td key={index} className={header.classes} >{created}</td>
          );
        case 'showAttributes': return  (
            <td key={index} className={header.classes} onClick={() => toggleVisibleAttributes(row.id)}><Icon name="tag" className="m-0 text-primary" /></td>
          );
        default: return  (
            <td key={index} className={header.classes}>{row[header.field]}</td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Imei', field: 'imei', classes:'text-left'},
    {caption: 'Vehicle', field: 'vehicle_name', classes:'text-left'},
    {caption: 'Coordinates', field: 'coords', classes:'text-left'},
    {caption: 'Created', field: 'created', classes:'text-left'},
    {caption: 'Collected', field: 'collected', classes:'text-left'},
    {caption: 'Updated', field: 'updated', classes:'text-right'},
    {caption: '-', field: 'showAttributes', classes:'text-right'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
    <>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
      {visibleAttributes?.includes(row.id) && (
        <tr>
          <td colSpan={headers.length} className="bg-light" >{renderRowAttributes(row.attributes)}</td>
        </tr>
      )}
    </>
    );
  }

  const iconName='tower-broadcast';
  const emptyCaption='No Tracks found';

  let filtered = rows;
  if (searchValue) {
    filtered = rows.filter(row => row?.logger?.imei === searchValue || row?.vehicle?.name === searchValue);
  }

  // Trying to stop rendering new tracks coming in on socket io
  if (maxUpdated) {
    filtered = filtered.filter(f => f.created < maxUpdated);
  }

  const sorted = filtered.sort((first, second) => (new Date(first.created) < new Date(second.created) ? 1 : -1));

  return (
    <React.Fragment>
      <Listview
        rows={sorted}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr} 
        iconName={iconName} 
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default TracksLsv;

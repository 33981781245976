import React from 'react';
import { connect } from 'react-redux';
import { Button, Nav, TabContent, TabPane, Row, Col } from 'reactstrap';

import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import FormBase from '../../core/form/components/FormBase';
import FormTab from '../../core/form/components/FormTab';
import {
  updateControls,
  saveControls,
  updateControlOptions,
} from '../../core/form/lib/validateForm';
import FormInput from '../../core/form/components/FormInput';
import { controls } from '../forms/user';
import ResponseMessage from '../../core/form/components/ResponseMessageTab';
import PageTitle from '../../core/form/components/PageTitle';
import {
  fetchUser,
  fetchUsers,
  updateUser,
  fetchRoles,
  createUserRole,
  removeUserRole,
  createUserOrg,
  removeUserOrg,
  generateUserToken,
  fetchOrgs,
  fetchUsersWithRole,
} from '../actions';

import { generatePasswordResetLink, forgot } from '../../core/authentication/actions';
import UserRolesLsv from '../components/UserRolesLsv';
import UserOrgsLsv from '../components/UserOrgsLsv';

class User extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      controls,
      data: {},
    };

    this.displayResetLink = this.displayResetLink.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onResetPwd = this.onResetPwd.bind(this);
    this.onAddRole = this.onAddRole.bind(this);
    this.onRemoveRole = this.onRemoveRole.bind(this);
    this.onAddOrg = this.onAddOrg.bind(this);
    this.onRemoveOrg = this.onRemoveOrg.bind(this);
    this.onGenerateToken = this.onGenerateToken.bind(this);
  }

  async componentDidMount() {
    let { data, controls } = this.state;

    const { id } = this.props.match.params;

    data = await this.props.dispatch(fetchUser(id));

    controls = updateControls(controls, data);

    this.setState({
      data,
      controls,
    });
  }

  toggleTab(tab, tag) {
    const { data } = this.state;

    switch (tag) {
      case 'roles':
        this.props.dispatch(fetchRoles(data.id));
        break;
      case 'custom_attributes':
      case 'orgs':
        this.props.dispatch(fetchOrgs(data.id));
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async onSave() {
    const { data, controls } = this.state;

    const formData = saveControls(controls, data);

    const success = await this.props.dispatch(updateUser(formData));
    if (success) {
      this.props.dispatch(fetchUsers());
      this.props.history.goBack();
    }
  }

  async onRemove() {
    // Can not remove a user
  }

  async onGenerateToken() {
    const { data } = this.state;
    let { controls } = this.state;

    const { id, token } = data;

    let confirmed = true;
    if (token !== '')
      confirmed = window.confirm(
        `Warning! Generating a new API token will invalidate the existing token and revoke access to third-party software using it. Continue?`,
      );
    if (confirmed) {
      const response = await this.props.dispatch(generateUserToken(id));
      if (response.data) {
        data.token = response.data.token;
        controls = updateControls(controls, data);
        this.setState({ data, controls });
      }
    }
  }

  displayResetLink() {
    const { controls } = this.state;
    const { state } = this.props.history.location;

    const isChildAppResetPassword = state?.isChildAppResetPassword
      ? state.isChildAppResetPassword
      : false;

    const email = controls.email.value;
    if (email) {
      // Get confirmation from user
      const confirmed = window.confirm(`Would you like to reset your password?`);
      if (confirmed) {
        // Get and update response message with generated link
        this.props.dispatch(generatePasswordResetLink({ email, isChildAppResetPassword }));
      }
    }
  }

  async onResetPwd() {
    const { controls } = this.state;
    const { state } = this.props.history.location;

    const isChildAppResetPassword = state?.isChildAppResetPassword
      ? state.isChildAppResetPassword
      : false;

    const { dispatch } = this.props;

    const user = {};
    user.email = controls.email.value;

    const confirmed = window.confirm(`Email a password reset link to ${user.email}. Continue?`);
    if (confirmed) {
      dispatch(forgot(user.email, isChildAppResetPassword));
      setTimeout(function () {
        dispatch({ type: 'FORGOT_FULFILLED' });
      }, 3000);
    }
  }

  async onAddRole(data) {
    const user_id = this.state.data.id;

    const updateData = {
      ...data,
      user_id,
    };

    const success = await this.props.dispatch(createUserRole(updateData));
    if (success) {
      this.setState({ data: await this.props.dispatch(fetchUser(user_id)) });
    }
  }

  async onRemoveRole(row) {
    const { data } = this.state;

    const ok = window.confirm('Permanently removing this role for user. Continue?');
    if (ok) {
      const role = data.user_roles.find((user_role) => user_role.id === row.id);
      const success = await this.props.dispatch(removeUserRole(role.id));
      if (success) {
        this.setState({ data: await this.props.dispatch(fetchUser(data.id)) });
      }
    }
  }

  async onAddOrg(org_id) {
    let { data, controls } = this.state;

    const { id } = data;

    const updateData = {
      user_id: id,
      org_id,
    };

    const success = await this.props.dispatch(createUserOrg(updateData));
    if (success) {
      data = await this.props.dispatch(fetchUser(id));
      controls = await updateControls(controls, data);
      this.setState({ data, controls });
    }
  }

  async onRemoveOrg(row) {
    let { data, controls } = this.state;

    const ok = window.confirm(
      'Permanently removing this Organisation association for user. Continue?',
    );
    if (ok) {
      const user_org = data.user_orgs.find((user_org) => user_org.id === row.id);
      const success = await this.props.dispatch(removeUserOrg(user_org.id));
      if (success) {
        data = await this.props.dispatch(fetchUser(data.id));
        controls = await updateControls(controls, data);
        this.setState({ data, controls });
      }
    }
  }

  render() {
    const { controls, data } = this.state;
    const { authenticated, responseMessage, responseMessageColour } = this.props.auth;
    const { roles, orgs } = this.props.manage;

    const lookup_options = [];
    if (data.user_roles) {
      roles.map((role) => {
        const idx = data.user_roles.findIndex((user_role) => user_role.role_id === role.id);
        if (idx === -1) lookup_options.push(role);
        return false;
      });
    }

    const lookup_orgs = [];
    if (data.user_orgs) {
      orgs.forEach((org) => {
        const existIdx = lookup_orgs.findIndex((lookup_org) => lookup_org.org_id === org.id);
        if (existIdx === -1) {
          const idx = data.user_orgs.findIndex((user_org) => user_org.org_id === org.id);
          if (idx === -1) lookup_orgs.push(org);
        }
      });
      lookup_orgs.push({ id: '', name: 'Associate Organisation' });
    }

    const iconName = 'user';

    // Display authentication error
    if (!authenticated) {
      return (
        <div className="p-4">
          <ResponseMessage colour={responseMessageColour} responseMessage={responseMessage} />
        </div>
      );
    }

    return (
      <div>
        <div className="p-4">
          <PageTitle title={`${data.name}`} iconName={iconName} />

          {responseMessage && (
            <ResponseMessage colour={responseMessageColour} responseMessage={responseMessage} />
          )}

          <Nav tabs className="mt-2">
            <FormTab
              caption="User"
              tabTag="user"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Roles"
              tabTag="roles"
              tabId="2"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Organisations"
              tabTag="orgs"
              tabId="3"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 mt-2">
              <Row>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.firstname} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.lastname} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.email} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.mobile} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInputSwitch handleChange={this.handleChange} control={controls.enabled} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.token} />
                </Col>
                <Col>
                  <FormInputSwitch handleChange={this.handleChange} control={controls.is_locked} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.login_attempts} />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2" className="mb-2 mt-2">
              <UserRolesLsv
                rows={data.user_roles || []}
                lookupOptions={roles || []}
                userOrgs={data.user_orgs || []}
                onAdd={this.onAddRole}
                onRemove={this.onRemoveRole}
              />
            </TabPane>

            <TabPane tabId="3" className="mb-2 mt-2">
              <UserOrgsLsv
                rows={data.user_orgs || []}
                lookupOptions={lookup_orgs || []}
                onAdd={this.onAddOrg}
                onRemove={this.onRemoveOrg}
              />
            </TabPane>
          </TabContent>

          <div className="d-flex justify-content-center p-2">
            <Button size="sm" color="success" onClick={this.onSave}>
              Save Changes
            </Button>
            <Button size="sm" className="ml-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" className="ml-2" color="primary" onClick={this.onResetPwd}>
              Send Password Reset
            </Button>
            <Button disabled={true} size="sm" className="ml-2" color="primary" onClick={this.displayResetLink}>
              Generate Password Reset Link
            </Button>
            <Button
              size="sm"
              className="ml-2"
              color="warning"
              onClick={this.onGenerateToken}
              disabled={true}
            >
              Generate API Token
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ manage, auth }) => ({ manage, auth });

export default connect(mapStoreToProps)(User);

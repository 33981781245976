import { connect } from 'react-redux';
import { } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import FormBase from 'jsx/components/core/form/components/FormBase';
import SeasonConfigLsv from '../components/SeasonConfigLsv';

import {updateOrg} from '../../../manage/actions';

class SeasonConfigMillPanel extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      attributeDefs: [
        {caption: 'Mill Capacity', tag: 'mill_capacity', value: 0, editing: false, oldValue: 0}
      ]
    };

    this.saveChange = this.saveChange.bind(this);
    this.cancelChange = this.cancelChange.bind(this);
  }

  componentDidMount() {
    const {attributeDefs} = this.state;

    const updatedDefs = attributeDefs.map(attribute => {
      const millAttribute = this.props.mill?.attributes?.find(millAttribute => millAttribute.tag === attribute.tag);
      attribute.value = millAttribute?.value || 0;
      return attribute;
    })

    this.setState({attributeDefs: updatedDefs});
  }

  handleChange(event) {
    let { attributeDefs } = this.state;

    const idx = attributeDefs.findIndex(def => def.tag === event.target.name);
    attributeDefs[idx].value = event.target.value;
    attributeDefs[idx].editing = true;

    this.setState({attributeDefs});
  }

  async saveChange(row) {
    let { attributeDefs } = this.state;

    const formData = {id: this.props.mill.id, attributes: attributeDefs};
    const success = await this.props.dispatch(updateOrg(formData));

    if (success) {
      const idx = attributeDefs.findIndex(def => def.tag === row.tag);
      attributeDefs[idx].oldValue = attributeDefs[idx].value;
      attributeDefs[idx].editing = false;

      this.setState({attributeDefs})
    }
  }

  cancelChange(row) {
    let { attributeDefs } = this.state;

    const idx = attributeDefs.findIndex(def => def.tag === row.tag);
    attributeDefs[idx].value = attributeDefs[idx].oldValue;
    attributeDefs[idx].editing = false;

    this.setState({attributeDefs})
  }

  render() {
    const { attributeDefs } = this.state;


    return (
      <div>
        <small style={{fontFamily: 'Roboto, Arial, sans-serif', fontWeight: 700}}>{this.props.mill.name}</small>
        <SeasonConfigLsv
          rows={attributeDefs} 
          handleChange={this.handleChange} 
          cancelChange={this.cancelChange}
          saveChange={this.saveChange}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ season, manage }) => ({ season, manage });
export default connect(mapStoreToProps)(withContainerError(SeasonConfigMillPanel));

import React from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import {
  buildFeatureCollection,
  buildFeature,
  buildGeometryFromCoordinates,
  getDefaultLayer,
  zoomToBounds
} from 'jsx/components/core/form/lib/mapster';

import Icon from 'jsx/components/core/icons/Icon';

import { Row, Col } from 'reactstrap';
import Mapster from 'jsx/components/core/form/components/Mapster';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

import TrackingVehiclesLsv from '../components/TrackingVehiclesLsv';
import TrackingFilterPanel from '../components/TrackingFilterPanel';

import SocketReducer from 'jsx/lib/socketReducer';

import {
  fetchTracks
} from '../actions/logger';

import { 
  fetchVehicles,
  fetchVehicleTracks,
  fetchVehicleTypes,
  fetchVehicleStatuses,
  fetchVehicleOwners,
  setVehicleFilters,
  fetchInspectors
} from '../actions';

import { fetchFarms, fetchFarmBlocks, fetchHarvestGroups, fetchMills } from '../../season/actions';

import markerIcon from '../../../../../images/canecutter/map-marker-2-64.png';
import stoppedIcon from '../../../../../images/canecutter/empty-circle-64.png';
import chevronIcon from '../../../../../images/canecutter/chevron.png';
import arrowUpIcon from '../../../../../images/canecutter/arrow-up.png';

import { getVehicleColour, abortRequests } from '../../vehicles/lib/tracking';
import { getCropColour, getFarmBlockCrops } from '../../season/lib/season';
import Statistics from 'jsx/components/modules/cropping/containers/Statistics';

import { fetchTrackStats } from 'jsx/components/modules/vehicles/actions/logger';

class Tracking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      map: null,
      showFilterPanel: false,
      showSearchPanel: false,
      activeVehicleMarker: null,
      activeVehiclePopup: null,
      activeTrackPopup: null,
      vehicleMarkers: [],
      vehiclesWithTails: [],
      selectedVehicle: null,
      featureFetching: 0,
      timestampTracks: new Date().getTime(),
      timestampBlocks: new Date().getTime(),
      timestampVehicles: new Date().getTime(),
      abortController: new AbortController(),
      searchFarmValue: null,
      autoLoadTimer: null,
      autoLoadUpdated: new Date().getTime(),
      vehicleTimer: null
    };

    this.onMapLoad = this.onMapLoad.bind(this);
    this.setVehicleMap = this.setVehicleMap.bind(this);
    this.setVehicleTracksMap = this.setVehicleTracksMap.bind(this);
    this.setFarmMap = this.setFarmMap.bind(this);
    this.resetGeometry = this.resetGeometry.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterDelete = this.handleFilterDelete.bind(this);
    this.onMapFeatureClick = this.onMapFeatureClick.bind(this);
    this.onTrackHover = this.onTrackHover.bind(this);

    this.onSearch = this.onSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.onSearchFarm = this.onSearchFarm.bind(this);
    this.handleFarmSearchChange = this.handleFarmSearchChange.bind(this);

    this.onToggleSearch = this.onToggleSearch.bind(this);
    this.getFilterCount = this.getFilterCount.bind(this);
    this.toggleTails = this.toggleTails.bind(this);
    this.storeSettings = this.storeSettings.bind(this);
    this.getVehicleTracks = this.getVehicleTracks.bind(this);
    // this.abortRequests = this.abortRequests.bind(this);
    this.setVehicleTailsMap = this.setVehicleTailsMap.bind(this);
    this.reload = this.reload.bind(this);
    // this.getVehicleColour = getVehicleColour.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchVehicleTypes());
    this.props.dispatch(fetchVehicleStatuses());
    // this.props.dispatch(fetchVehicleOwners());
    this.props.dispatch(fetchInspectors());
    this.props.dispatch(fetchHarvestGroups());
    this.props.dispatch(fetchMills());

    // Start socket client for updating reducers
    if (process.env.REACT_APP_DISABLE_SOCKET_CONNECTION !== 'true') {
      this.socketReducer = new SocketReducer(this.props);
    }

    // Set vehicle update. The tracks are updated multiple times a second. Setting Vehicle Map sets
    // the whole layer of all vehicles which will probably be too much load when in full harvest mode.
    // This is not a server fetch but uses the track changes accummelated from SocketIO.
    // Trialing a interval timer to periodically apply the vehicle coordinate change. Bit clunky
    this.setState({vehicleTimer: setInterval(this.setVehicleMap, 15000)});
  }

  componentWillUnmount() {
    let {vehicleTimer} = this.state;
    clearInterval(vehicleTimer);
    this.setState({vehicleTimer});
  }

  componentDidUpdate(prevProps, prevState) {
    const { vehicles, fetching } = this.props.vehicles;

    if (vehicles.length > 0 && !fetching && prevProps.vehicles.vehicles.length !== this.props.vehicles.vehicles.length) {
      // This reloads all the vehicles when one changes position. Not great.
      this.setVehicleMap();
    }
  }

  storeSettings(field, value) {
    const { settings } = this.props.vehicles;

    settings[field] = value;
    this.props.dispatch({ type: 'UPDATE_SETTINGS_FULFILLED', payload: settings });

    // If its the autoloader
    if (field === 'autoLoad') {
      let { autoLoadTimer, params, filters } = this.state;

      if (value) {
        // Set interval for 20 minuts
        autoLoadTimer = setInterval(() => {
          this.reload(params, filters)
        }
        , 60000 * 20);
      } else {
        clearInterval(autoLoadTimer);
      }

      this.setState({autoLoadTimer})
    }
  }

  toggleTails(vehicleId) {
    const { vehiclesWithTails } = this.state;
    const { settings } = this.props.vehicles;

    if (vehiclesWithTails.indexOf(vehicleId) > -1) {
      vehiclesWithTails.splice(vehiclesWithTails.indexOf(vehicleId), 1);
    } else {
      vehiclesWithTails.push(vehicleId);
    }

    const fromDate = moment().subtract('minutes', settings.tailMinutes).format('YYYY-MM-DD HH:mm:ss');
    const params = {vehicle_id: vehiclesWithTails, created: fromDate};

    if (vehiclesWithTails.length > 0) {
      this.props.dispatch(fetchTracks(params)).then((tails) => this.setVehicleTailsMap(tails));
    } else {
      this.setVehicleTailsMap([]);
    }

    this.setState({vehiclesWithTails});
  }

  async getFarmBlocks(eventType) {
    const { map, mapSources, abortController } = this.state;
    const minZoomLevel = 12;

    if (map.getZoom() < minZoomLevel) {
      console.log("Blocks Too far away")
      return;
    }

    const bounds = map.getBounds();
    const params = {bounds: [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat]};
    const limit = 500;

    if (eventType === 'zoomend' || eventType === 'dragend') {
      // Get Farm Blocks
      const count = await this.props.dispatch(fetchFarmBlocks({...params, preflight: true}));
      const loops = parseInt(count / limit) + 1;

      // Clear blocks
      const idx = mapSources.findIndex((source) => source.id === 'blocks');
      mapSources[idx].source = buildFeatureCollection();
      this.setState({mapSources});

      const timestampBlocks = new Date().getTime();
      this.setState({timestampBlocks});
  
      for (let idx = 0; idx < loops; idx++) {
        this.setState({featureFetching: this.state.featureFetching + 1});

        const offset = idx * limit;
        this.props.dispatch(fetchFarmBlocks({...params, limit, offset}, true, abortController)).then((blocks) => this.setFarmBlockMap(blocks, timestampBlocks));
      }
    }
  }

  async getVehicleTracks(eventType) {
    const { map, mapSources, abortController } = this.state;
    const minZoomLevel = 13;

    if (map.getZoom() < minZoomLevel) {
      console.log("Tracks Too far away")
      return;
    }

    const bounds = map.getBounds();
    const params = {bounds: [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat]};
    const limit = 10000;

    if (eventType === 'zoomend' || eventType === 'dragend') {
      // Get Vehicle tracks
      const count = await this.props.dispatch(fetchVehicleTracks({...params, preflight: true})); 
      const loops = parseInt(count / limit) + 1;

      // Clear tracks
      const idx = mapSources.findIndex((source) => source.id === 'vehicle_tracks');
      mapSources[idx].source = buildFeatureCollection();
      this.setState({mapSources});

      const timestampTracks = new Date().getTime();
      this.setState({timestampTracks});

      for (let idx = 0; idx < loops; idx++) {
        this.setState({featureFetching: this.state.featureFetching + 1})

        const offset = idx * limit;
        this.props.dispatch(fetchVehicleTracks({...params, limit, offset}, true, abortController)).then((tracks) => this.setVehicleTracksMap(tracks, timestampTracks));
      }
    }
  }

  async refetch(event) {
    await abortRequests(this, 12);
    this.getVehicleTracks(event.type);
    getFarmBlockCrops(this, event.type);
    // this.getFarmBlocks(event.type);
  }

  async reload(params, filters ) {
    await this.props.dispatch(fetchVehicles(params, filters));
    this.setVehicleMap();
    
    const updated = new Date().getTime();
    this.setState({autoLoadUpdated: updated });
  }

  async onMapLoad(map) {
    const mapSources = await this.resetGeometry();
    const { params, filters } = this.props.vehicles;
  
    // map.showCollisionBoxes = true;

    // map.loadImage
    map.loadImage(markerIcon, (error, image) => {
      if (error) throw error;
      map.addImage('markerIcon', image, { 'sdf': true });
    });

    map.loadImage(stoppedIcon, (error, image) => {
      if (error) throw error;
      map.addImage('stoppedIcon', image, { 'sdf': true });
    });

    map.loadImage(arrowUpIcon, (error, image) => {
      if (error) throw error;
      map.addImage('arrowUpIcon', image, { 'sdf': true });
    });

    map.on('zoomend', (event) => {
      this.refetch(event);
    });

    map.on('dragend', (event) => {
      this.refetch(event);
    });

    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' },
        tolerance: 0,
        //maxzoom: 10
      });

      mapSource.layers = [];
      mapSource.markers = [];

      // Set layers
      const layer = getDefaultLayer(mapSource);
      map.addLayer(layer);

      mapSource.layers.push(layer);

      if (mapSource.onClick) {
        map.on('click', mapSource.layer_id, (event) => {
          mapSource.onClick(event.features[0]);
        });
      }

      // Load onMouseEnter event if defined
      if (mapSource.onMouseEnter) {
        map.on('mouseenter', mapSource.layer_id, (event) => {
          mapSource.onMouseEnter(event.features[0]);
        });
      }

      // Load onMouseLeave event if defined
      if (mapSource.onMouseLeave) {
        map.on('mouseleave', mapSource.layer_id, (event) => {
          mapSource.onMouseLeave(event.features[0]);
        });
      }

      // Load onMouseMove event if defined
      if (mapSource.onMouseOver) {
        map.on('mouseover', mapSource.layer_id, (event) => {
          mapSource.onMouseOver(event.features[0]);
        });
      }
    });

    this.setState({ mapSources, map });

    // Weird way to control layer order..
    // map.moveLayer('farm-boundaries', 'vehicle-symbols');
    // map.moveLayer('block-boundaries', 'vehicle-symbols');
    // map.moveLayer('farm-boundaries', 'vehicle-track-symbols');
    // map.moveLayer('block-boundaries', 'vehicle-track-symbols');
    map.moveLayer('farm-boundaries', 'track-symbols');
    map.moveLayer('block-boundaries', 'track-symbols');

    await this.props.dispatch(fetchVehicles(params, filters));
    this.setVehicleMap();

    await this.props.dispatch(fetchFarms());
    this.setFarmMap();
  };

  // eslint-disable-next-line class-methods-use-this
  resetGeometry() {
    const mapSources = [
      {
        id: 'tracks',
        layer_id: 'track-symbols',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Tails',
        style: 'Point',
        maxzoom: 24
        // onClick: this.onMapFeatureClick,
      },
      {
        id: 'vehicle_tracks',
        layer_id: 'vehicletrack-lines',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Vehicle Tracks',
        style: 'Line',
        maxzoom: 24,
        onMouseOver: this.onTrackHover
        // onClick: this.onMapFeatureClick,
      },
      {
        id: 'vehicles',
        layer_id: 'vehicle-symbols',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Vehicles',
        style: 'Point',
        showMarker: true,
        maxzoom: 24,
        onClick: this.onMapFeatureClick,
      },
      {
        id: 'vehicle_track_symbols',
        layer_id: 'vehicle-track-symbols',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Vehicle Track Symbols',
        style: 'PointSymbol',
        maxzoom: 24,
        minzoom: 16,
        // onClick: this.onMapFeatureClick,
      },
      {
        id: 'vehicle-labels',
        layer_id: 'vehicle-labels',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Vehicle Labels',
        style: 'PointLabel',
        maxzoom: 24,
        minzoom: 5,
        // onClick: this.onMapFeatureClick,
      },
      {
        id: 'farms',
        layer_id: 'farm-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farms',
        style: 'Polygon',
        onClick: null,
        minzoom: 5,
        maxzoom: 22
      },
      {
        id: 'farm-labels',
        layer_id: 'farm-labels',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farm Labels',
        style: 'PointLabel',
        onClick: null,
        minzoom: 12,
        maxzoom: 15
      },
      {
        id: 'blocks',
        layer_id: 'block-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farm Blocks',
        style: 'Polygon',
        onClick: null,
        minzoom: 11,
        maxzoom: 22
      },
      {
        id: 'block-labels',
        layer_id: 'block-labels',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Block Labels',
        style: 'PointLabel',
        onClick: null,
        minzoom: 15,
        maxzoom: 22
      },
    ];

    return mapSources;
  }

  // eslint-disable-next-line class-methods-use-this
  onMapFeatureClick(feature) {
    const { map } = this.state;
    let { activeVehiclePopup } = this.state;
    // zoomToBounds(map, [feature.geometry.coordinates]);

    // if (activeVehicleMarker) activeVehicleMarker.remove();
    if (activeVehiclePopup) activeVehiclePopup.remove();

    activeVehiclePopup = new mapboxgl.Popup({offset: 25, closeButton: false});
    if (feature.properties.title) {
      activeVehiclePopup.setText(`${feature.properties.title} - Speed: ${feature.properties.speed}`);
    }
    activeVehiclePopup.setLngLat(feature.geometry.coordinates)
      .addTo(map);

    // activeVehicleMarker = new mapboxgl.Marker({
    //   color: feature.properties.colour
    //   }).setLngLat(feature.geometry.coordinates)
    //   // .setPopup(popup)
    //   .addTo(map);

    this.setState({activeVehiclePopup, selectedVehicle: feature.properties.id});
  }

  onTrackHover(feature) {
    const { map } = this.state;
    let { activeTrackPopup } = this.state;
    // zoomToBounds(map, [feature.geometry.coordinates]);
    console.log("in tracks")
    // if (activeVehicleMarker) activeVehicleMarker.remove();
    if (activeTrackPopup) activeTrackPopup.remove();

    activeTrackPopup = new mapboxgl.Popup({offset: 25, closeButton: false});
    if (feature.properties.title) {
      activeTrackPopup.setText(`asdfasdfasdf`);
    }
    activeTrackPopup.setLngLat(feature.geometry.coordinates)
      .addTo(map);

    this.setState({activeTrackPopup});
  }

  async setVehicleMap() {
    const { vehicles, settings } = this.props.vehicles;
    const { mapSources, map, vehicleMarkers } = this.state;
    const coords = [];

    const featureAttributes = this.props.mapster.attributes;

    const idx = mapSources.findIndex((source) => source.id === 'vehicles');
    const labelIdx = mapSources.findIndex((source) => source.id === 'vehicle-labels');

    if (vehicles && vehicles.length > 0) {
      const featureCollection = buildFeatureCollection();
      const labelCollection = buildFeatureCollection();

      vehicles.forEach((vehicle) => {
        if (vehicle.last_track) {

          if (vehicle.last_track.coords.coordinates[0] !== 0) {
            const feature = buildFeature(vehicle.last_track.coords, {
              title: `${vehicle.name}`,
              speed: `${vehicle.last_track.attributes?.speed} km/h`,
              id: vehicle.id,
              fill: getVehicleColour(this, vehicle?.status?.tag)
            });
            featureCollection.features.push(feature);

            // Label
            const featureLabel = buildFeature(vehicle.last_track.coords, {
              labelCaption: (settings.showVehicleLabels ? vehicle.name : null),
              labelColour: featureAttributes.find(attribute => attribute.id === 'vehicle_label_colour').value,
              labelSize: 16,
              labelHaloColour: 'white'
            });
            labelCollection.features.push(featureLabel);
            
            if (mapSources[idx]?.showMarker) {
              const vehicleMarkerIdx = vehicleMarkers.findIndex(vehicleMarker => vehicleMarker.id === vehicle.id);

              if (!vehicle.last_track.attributes?.speed && vehicleMarkerIdx > -1) {
                vehicleMarkers[vehicleMarkerIdx].marker.remove();
              } else if (vehicle.last_track.attributes?.speed) {

                // Marker is pointing south by default
                let rotation = vehicle.last_track.attributes.direction + 180;
                if (rotation > 360 ) rotation -= 360;

                const markerOptions = {
                  rotation,
                  color: getVehicleColour(this, vehicle?.status?.tag),
                  scale: 0.8
                };

                const marker = new mapboxgl.Marker(markerOptions)
                  .setLngLat(vehicle.last_track.coords.coordinates)
                  .addTo(map);

                if (vehicleMarkerIdx === -1) {
                  vehicleMarkers.push({id: vehicle.id, marker});
                } else {
                  vehicleMarkers[vehicleMarkerIdx].marker.remove();
                  vehicleMarkers[vehicleMarkerIdx].marker = marker;
                }
              }
            }

            coords.push(feature.geometry.coordinates);
          }
        }
      });

      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);

      mapSources[labelIdx].source = labelCollection;
      map.getSource(mapSources[labelIdx].id).setData(mapSources[labelIdx].source);

      if (settings.zoomToBoundary) zoomToBounds(map, coords);
      
      this.setState({vehicleMarkers});
    }
  }

  async setVehicleTailsMap(tracks) {
    const { mapSources, map } = this.state;
    const featureColour = this.props.mapster.colours.tracks;
    
    const idx = mapSources.findIndex((source) => source.id === 'tracks');
    const featureCollection = buildFeatureCollection();

    if (tracks && tracks.length > 0) {
      tracks.forEach((track) => {
          const feature = buildFeature(track.coords, {
            id: track.id,
            outline: featureColour?.outline,
            fill: 'pink'
          });

          featureCollection.features.push(feature);
      });
    }

    mapSources[idx].source = featureCollection;
    map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
  }

  async setVehicleTracksMap(tracks, timestamp) {
    // Dont draw cancelled requests. Not sure if these are ignored if request is aborted
    if (this.state.timestampTracks !== timestamp) {
      this.setState({featureFetching: this.state.featureFetching - 1});
      return;
    }

    const { mapSources, map } = this.state;

    const idx = mapSources.findIndex((source) => source.id === 'vehicle_tracks');
    const symbolIdx = mapSources.findIndex((source) => source.id === 'vehicle_track_symbols');

    const featureCollection = buildFeatureCollection();
    const featureSymbolCollection = buildFeatureCollection();

    if (tracks && tracks.length > 0) {
      tracks.forEach((track) => {
          const feature = buildFeature(track.track, {
            id: track.id,
            outline: getVehicleColour(this, track.status_tag),
          });

          const symbolCoordinate = buildGeometryFromCoordinates(track.track.coordinates[0]);
          
          const featureSymbol = buildFeature(symbolCoordinate, {
            icon: 'arrowUpIcon',
            iconRotation: track.heading,
            iconSize: 0.3,
            iconColour: getVehicleColour(this, track.status_tag),
          });

          featureCollection.features.push(feature);
          featureSymbolCollection.features.push(featureSymbol);
      });
    }

    mapSources[idx].source.features = mapSources[idx].source.features.concat(featureCollection.features);
    map.getSource(mapSources[idx].id).setData(mapSources[idx].source);

    mapSources[symbolIdx].source.features = featureSymbolCollection.features;
    map.getSource(mapSources[symbolIdx].id).setData(mapSources[symbolIdx].source);

    this.setState({featureFetching: this.state.featureFetching - 1});
  }

  async setFarmMap() {
    const { farms } = this.props.season;
    const { mapSources, map } = this.state;
    let coords = [];
  
    const featureAttributes = this.props.mapster.attributes;

    if (farms && farms.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'farms');
      const labelIdx = mapSources.findIndex((source) => source.id === 'farm-labels');

      const polygonCollection = buildFeatureCollection();
      const labelCollection = buildFeatureCollection();
      
      farms.forEach((farm) => {
        // Polygon 
        const featurePolygon = buildFeature(farm.geom, {
          title: `${farm.name}`,
          colour: featureAttributes.find(attribute => attribute.id === 'farm_fill').value,
          outline: featureAttributes.find(attribute => attribute.id === 'farm_outline').value,
          strokeWidth: 5,
        });
        polygonCollection.features.push(featurePolygon);
        
        coords = coords.concat(featurePolygon.geometry.coordinates[0]);

        // Label
        const featureText = buildFeature(farm.geom, {
          labelCaption: farm.farm_no,
          labelColour: 'black',
          labelSize: 12
        });
        labelCollection.features.push(featureText);
      });

      mapSources[idx].source = polygonCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
      mapSources[labelIdx].source = labelCollection;
      map.getSource(mapSources[labelIdx].id).setData(mapSources[labelIdx].source);

      zoomToBounds(map, coords);
    }
  }

  async setFarmBlockMap(blocks, timestamp) {
    // Avoid cancelled requests
    if (this.state.timestampBlocks !== timestamp) {
      this.setState({featureFetching: this.state.featureFetching - 1});
      return;
    }
    
    const { mapSources, map } = this.state;

    // const featureColour = this.props.mapster.colours.farms;
    const featureAttributes = this.props.mapster.attributes;

    if (blocks && blocks.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'blocks');
      const labelIdx = mapSources.findIndex((source) => source.id === 'block-labels');

      const featureCollection = buildFeatureCollection();
      const labelCollection = buildFeatureCollection();

      blocks.forEach((block) => {
        const feature = buildFeature(block.geom, {
          title: `${block.name}`,
          colour: featureAttributes.find(attribute => attribute.id === 'block_fill').value,
          outline: featureAttributes.find(attribute => attribute.id === 'block_outline').value,
          strokeWidth: 5,
          // labelCaption: block.name,
          // labelColour: 'red'
        });
        featureCollection.features.push(feature);

        // Label
        const featureText = buildFeature(block.geom, {
          labelCaption: block.name,
          labelColour: 'white',
          labelSize: 14
        });
        labelCollection.features.push(featureText);
      });

      mapSources[idx].source.features = mapSources[idx].source.features.concat(featureCollection.features);
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
    
      mapSources[labelIdx].source.features = mapSources[idx].source.features.concat(labelCollection.features);
      map.getSource(mapSources[labelIdx].id).setData(mapSources[labelIdx].source);

      this.setState({featureFetching: this.state.featureFetching - 1});
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onToggleFilter() {
    this.setState({showFilterPanel: !this.state.showFilterPanel});
  }

  async onReload() {
    const { params, filters, settings} = this.props.vehicles;
    const { vehiclesWithTails } = this.state;

    await this.props.dispatch(fetchVehicles(params, filters));
    this.setVehicleMap();

    const fromDate = moment().subtract('minutes', settings.tailMinutes).format('YYYY-MM-DD HH:mm:ss');
    this.props.dispatch(fetchTracks({vehicle_id: vehiclesWithTails, created: fromDate})).then(this.setVehicleTailsMap());
  }

  onRowClick(row) {
    const { map } = this.state;
    let { activeVehiclePopup } = this.state;

    if (row.last_track) {
      zoomToBounds(map, [row.last_track.coords.coordinates], 30, 15);

      if (activeVehiclePopup) activeVehiclePopup.remove();

      activeVehiclePopup = new mapboxgl.Popup({offset: 25, closeButton: false});
      if (row.name) {
        activeVehiclePopup.setText(row.name);
      }
      activeVehiclePopup.setLngLat(row.last_track.coords.coordinates)
        .addTo(map);

      this.setState({activeVehiclePopup, selectedVehicle: null});
    }
  }

  async handleFilterChange(event) {
    const {
      filters,
      params
    } = this.props.vehicles;

    const field = event.target.id;

    if (event.target.type === 'checkbox') {
      filters[field] = (!event.target.checked ? null : event.target.checked);
    } else {
      const exists = filters[field].find(id => id === event.target.value);
      if (!exists) {
        filters[field].push(event.target.value);
      }
    }

    this.props.dispatch(setVehicleFilters(params, filters));

    // Reload
    await this.props.dispatch(fetchVehicles(params, filters));
  }

  async handleFilterDelete(id, field) {
    const {
      filters,
      params
    } = this.props.vehicles;

    const idx = filters[field].findIndex(fid => fid === id)
    filters[field].splice(idx, 1);

    this.props.dispatch(setVehicleFilters(params, filters));

    // Reload
    await this.props.dispatch(fetchVehicles(params, filters));
    this.setVehicleMap();
  }

  handleSearchChange(event) {
    this.props.dispatch({ type: 'UPDATE_VEHICLE_SEARCHVALUE_FULFILLED', payload: event.target.value });

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params,
      searchValue
    } = this.props.vehicles;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchVehicles(params, filters));
  }

  handleFarmSearchChange(event) {
    this.setState({searchFarmValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearchFarm();
    }
  }

  onSearchFarm() {
    const {
      searchFarmValue,
      map, 
      mapSources
    } = this.state;

    const { farms } = this.props.season;

    // exit if no search value
    if (!searchFarmValue) {
      this.setFarmMap();
      return;
    }

    // decode search string
    const searchArray = searchFarmValue.split(',');
    const farmNo = parseInt(searchArray[0]);
    let selectedFarm;
    let millId;

    if (searchArray.length === 2) {
      millId = searchArray[1].toUpperCase().trim();
      selectedFarm = farms.find(farm => farm.farm_no === farmNo && farm.external_mill === millId);
    } else {
      selectedFarm = farms.find(farm => farm.farm_no === farmNo);
    }
  
    if (selectedFarm) {
      const idx = mapSources.findIndex((source) => source.id === 'farms');

      const featureCollection = buildFeatureCollection();
      const feature = buildFeature(selectedFarm.geom, {
        title: `${selectedFarm.name}`,
        colour: 'pink',
        outline: 'red'
      });
      featureCollection.features.push(feature);
  
      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
  
      if (selectedFarm.geom.type === 'Polygon') {
        zoomToBounds(map, feature.geometry.coordinates[0]);
      } else {
        zoomToBounds(map, feature.geometry.coordinates[0][0]);
      }
    }
  }

  onToggleSearch() {
    this.setState({showSearchPanel: !this.state.showSearchPanel});
  }

  getFilterCount() {
    const { filters } = this.props.vehicles;
    const keys = Object.keys(filters);

    let cnt = 0;

    keys.flatMap(key => {
      if (Array.isArray(filters[key])) {
        cnt += filters[key].length;
      }

      if (typeof filters[key] === 'boolean') {
        cnt += (!filters[key] ? 0 : 1);
      }

      return false;
    });

    return cnt;
  }

  render() {
    const {
      mapSources,
      showFilterPanel,
      showSearchPanel,
      vehiclesWithTails,
      selectedVehicle,
      featureFetching,
      searchFarmValue,
      autoLoadUpdated
    } = this.state;

    const {
      vehicles,
      types,
      filters,
      statuses,
      owners,
      inspectors,
      settings,
      searchValue
    } = this.props.vehicles;

    const { fullMapScreen } = this.props.realm;

    const { harvestgroups, mills } = this.props.season;
    const expandMap = true;

    const refreshIcon = 'rotate';
    const filterIcon = 'filter';
    const searchIcon = 'magnifying-glass';
    const iconWidth = 16;

    const filterCount = this.getFilterCount();
    const filterIconClass = `${(filterCount > 0 ? 'text-danger' : 'text-white')} m-0 mr-2`;
    const filterLabel = `${filterCount} ${(filterCount === 1 ? 'filter set' : 'filters set')}`;

    const searchIconClass = `${(showSearchPanel ? 'text-success' : 'text-white')} m-0 mr-2`;

    return (
      <div className="h-100 bg-corporate">
        <Row className="m-0 p-0 h-100">
          <Col >
            <Mapster
              // expandMap={expandMap}
              // toggleMap={this.onToggleMap}
              mapSources={mapSources}
              onMapLoad={this.onMapLoad}
              featureFetching={featureFetching}
            />

            {fullMapScreen.display && (
              <div className="text-center" style={{position: 'absolute', bottom: 0, left:0, width: '100%', marginBottom: 30}}>
                <Statistics showTracks={false} autoLoadUpdated={autoLoadUpdated} key={autoLoadUpdated}/>
              </div>
            )}

          </Col>
          <Col sm={3} className="bg-black " style={fullMapScreen}>
            <div className="d-flex justify-content-between p-2 border-bottom border-secondary" style={{backgroundColor: '#333333'}}>
              <span className="text-secondary">{filterLabel}</span>
              <span>
                <Icon name={searchIcon} className={searchIconClass} style={{ width: iconWidth, marginRight: 20 }} onClick={this.onToggleSearch}/>
                <Icon name={filterIcon} className={filterIconClass} style={{ width: iconWidth, marginRight: 20 }} onClick={this.onToggleFilter}/>
                <Icon name={refreshIcon} className="m-0 text-white" style={{ width: iconWidth, marginRight: 5 }} onClick={this.onReload}/>
              </span>
            </div>

            {showSearchPanel && (
              <div className="p-1">
                <SingleSearch
                  handleSearchChange={this.handleSearchChange}
                  onSearch={this.onSearch}
                  width="100%"
                  placeholder="Partial Keyword (Group Name/IMEI)"
                  value={searchValue}
                  inputGroupClassName="m-1 mr-1"
                />
                <SingleSearch
                  handleSearchChange={this.handleFarmSearchChange}
                  onSearch={this.onSearchFarm}
                  width="100%"
                  placeholder="Goto Farm - Example: 50227,SJM"
                  value={searchFarmValue}
                  buttonColour="pink"
                  inputGroupClassName="m-1 mr-1"
                />
              </div>
            )}

            <div style={{overflowY: 'auto', height: '100vh'}}>
              <div>
                {!showFilterPanel && (
                  <TrackingVehiclesLsv
                    rows={vehicles}
                    onRowClick={this.onRowClick}
                    getVehicleColour={getVehicleColour}
                    toggleTails={this.toggleTails}
                    vehiclesWithTails={vehiclesWithTails}
                    selectedVehicle={selectedVehicle}
                    settings={settings}
                    component={this} // hmmm, will i go to hell?
                  />
                )}
                {showFilterPanel && (
                  <TrackingFilterPanel
                    handleFilterChange={this.handleFilterChange}
                    handleDelete={this.handleFilterDelete}
                    vehicleTypes={types}
                    vehicleStatuses={statuses}
                    vehicleOwners={owners}
                    mills={mills}
                    harvestGroups={harvestgroups}
                    inspectors={inspectors}
                    filters={filters}
                    settings={settings}
                    storeSettings={this.storeSettings}
                  />
                )}
              </div>
            </div>
            
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = ({ vehicles, mapster, season, realm }) => ({ vehicles, mapster, season, realm });
export default connect(mapStoreToProps)(withContainerError(Tracking));

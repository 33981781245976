import { LogLevel } from "@azure/msal-browser";

const redirectUri = `${window.location.protocol}//${window.location.host}/login`;
const azureClientId = process.env.REACT_APP_AZURE_CLIENT_ID;
const azureTenantId = process.env.REACT_APP_AZURE_TENANT_ID;
const azureScopeUrl = `api://${process.env.REACT_APP_AZURE_SCOPE_ID}/access_as_user`;
const endpoint = `${window.location.protocol}//${window.location.host}/api/v1/realm/authenticated`;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: azureClientId,
        authority: `https://login.microsoftonline.com/${azureTenantId}`,
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default: break;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read"]
};

export const protectedResources = {
  canecutter: {
    endpoint: endpoint,
    scopes: [azureScopeUrl], // e.g. api://xxxxxx/access_as_user
  },
}

import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { Nav, TabContent, TabPane } from 'reactstrap';
import PageTitle from '../../../core/form/components/PageTitle';
import FormTab from '../../../core/form/components/FormTab';
import RakesLsv from '../components/RakesLsv';
import RakesToolbar from '../components/RakesToolbar';
import HarvestManagement from './HarvestActivity';

import { fetchRakes, fetchRakeStatistics } from '../actions/rakes';

class Crops extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'rakes',
      params: {},
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    this.onReload(this.state.params);
  }

  onReload(params) {
    this.props.dispatch(fetchRakes(params));
    this.props.dispatch(fetchRakeStatistics(params));
  }

  onFilterChange(event) {
    const { params } = this.state;

    params[event.target.id] = event.target.value;
    if (event.target.value === '') {
      delete params[event.target.id];
      this.onReload(params);
    }

    if (event.keyCode === 13) {
      this.onReload(params);
    }

    this.setState({params});
  }

  async toggleTab(tab) {
    switch (tab) {
      case 'rakes':
        this.props.dispatch(fetchRakes(this.state.params));
        break;

      default: break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab, params } = this.state;
    const { rakes, statistics } = this.props.crop;

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="p-2">
          <div className="m-2"><PageTitle title="Crop/Crush" iconName="frame" /></div>
        </div>

        <Nav tabs>
          <FormTab
            caption="Rakes"
            tabId="rakes"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Farm Block Summary"
            tabId="blocksummary"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled
          />
          <FormTab
            caption="Harvester Operator Summary"
            tabId="harvestoperatorsummary"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled
          />
          <FormTab
            caption="Harvest Activity"
            tabId="harvestactivity"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Mill Status"
            tabId="millstatus"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled
          />
        </Nav>

        <TabContent activeTab={this.state.activeTab} className="flex-fill">
          <TabPane tabId="rakes" className="mb-2 p-1 flex-fill">
            <RakesToolbar onRefresh={() => this.onReload(params)} onFilterChange={this.onFilterChange} statistics={statistics}/>
            <RakesLsv rows={rakes} />
          </TabPane>
          <TabPane tabId="blocksummary" className="mb-2 p-1 flex-fill">
            Block summary for week
          </TabPane>
          <TabPane tabId="harvestoperatorsummary" className="mb-2 p-1 flex-fill">
            harvestoperatorsummary by week
          </TabPane>
          <TabPane tabId="harvestactivity" className="mb-2 p-1 flex-fill">
            <HarvestManagement />
          </TabPane>
          <TabPane tabId="millstatus" className="mb-2 p-1 flex-fill">
            millstatus tools including crush rates and lost time for mills
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ crop }) => ({ crop });
export default connect(mapStoreToProps)(withContainerError(Crops));

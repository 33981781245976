import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import TracksLsv from '../components/TracksLsv';
import TracksToolbar from '../components/TracksToolbar';

import { fetchTracks } from '../actions/logger';

class TracksPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: null,
      maxUpdated: null,
      liveUpdateChecked: true,
      visibleAttributes: []
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.loadTracks = this.loadTracks.bind(this);
    this.onLiveUpdateChange = this.onLiveUpdateChange.bind(this);
    this.toggleVisibleAttributes = this.toggleVisibleAttributes.bind(this);
  }

  async componentDidMount() {
    // this.loadTracks();
  }

  loadTracks() {
    this.props.dispatch(fetchTracks({limit: 500}));
  }

  onLiveUpdateChange(event) {
    // Stopping showing rows time of checkbox updated. Weird i know
    if (event.target.checked) {
      this.setState({maxUpdated: null, liveUpdateChecked: event.target.checked});
    } else {
      this.setState({maxUpdated: new Date(), liveUpdateChecked: event.target.checked});
    }
  }

  handleSearchChange(event) {
    this.setState({searchValue: event.target.value});

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params
    } = this.props.loggers;

    const {
      searchValue,
    } = this.state;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchTracks(params, filters));
  }

  toggleVisibleAttributes(rowId) {
    const { visibleAttributes } = this.state;

    const idx = visibleAttributes.findIndex(attribute => attribute === rowId);
    if (idx === -1) {
      visibleAttributes.push(rowId);
    } else {
      visibleAttributes.splice(idx, 1);
    }

    this.setState({visibleAttributes});
  };

  render() {
    const { tracks, vehicles } = this.props.vehicles;
    const { loggers } = this.props.loggers;
    const { searchValue, maxUpdated, liveUpdateChecked, visibleAttributes } = this.state;
    const toolbarLabel = `${tracks.length} ${(tracks.length === 1 ? 'track' : 'tracks')}`;

    return (
      <div className="p-3 form">
        <TracksToolbar
          toolbarLabel={toolbarLabel}
          onRefresh={this.loadTracks}
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          onLiveUpdateChange={this.onLiveUpdateChange}
          liveUpdateChecked = { liveUpdateChecked }
        />

        <TracksLsv
          rows={tracks}
          searchValue={searchValue}
          maxUpdated={maxUpdated}
          toggleVisibleAttributes={this.toggleVisibleAttributes}
          visibleAttributes = {visibleAttributes}
          loggers={loggers}
          vehicles={vehicles}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ loggers, vehicles }) => ({ loggers, vehicles });
export default connect(mapStoreToProps)(withContainerError(TracksPanel));

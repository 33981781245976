import { GATEWAY_API_HOST, GATEWAY_API_PORT, GATEWAY_API_PROTOCOL } from '../constants/api';

const errorResponse = (dispatch, err, type, url) => {
  let message;
  if (!err.response) {
    console.error(`ERROR RESPONSE - NETWORK ERROR for ${type}`);
    message = 'Network Error. Unable to connect to server.';
  } else {
    const { response } = err;

    switch (response.status) {
      case 403:
        console.error(`ERROR RESPONSE - PERMISSION ERROR for ${type}: `, response.data);
        type = `${type}_DENIED`;
        ({ message } = response.data);
        break;
      case 404:
        console.error(`ERROR RESPONSE - ROUTE ERROR for ${type}: `, response.data);
        message = 'Route Error. Unable to find route on server';
        break;
      case 401:
        console.error(`ERROR RESPONSE - AUTHENTICATION ERROR for ${type}: `, response.data);
        ({ message } = response.data);

        break;
      default:
        console.error(`ERROR RESPONSE - APPLICATION ERROR for ${type}: `, response.data);
        message =
          response.data.message !== undefined
            ? response.data.message
            : 'Application Error. Unknown failure occurred';
        break;
    }
  }

  dispatch({ type: type, payload: message });
  return {
    success: false,
    message,
  };
};

export default errorResponse;

import { Button } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

const FarmsToolbar = (props) => {
  const {
    onFilter
  } = props;

  return (
    <div className="bg-light rounded p-2 m-1 d-flex justify-content-end">
      <SingleSearch />
      <Button disabled size="sm" color="primary ml-2">Filter</Button>
      <Button disabled size="sm" color="warning ml-2">Export Geometry</Button>
    </div>
  );
};

export default FarmsToolbar;

import React from 'react';
import { Button, Label } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import Icon from 'jsx/components/core/icons/Icon';

const VehiclesToolbar = (props) => {
  const {
    filterCount,
    onRefresh,
    onToggleFilter,
    toolbarLabel,
    onAdd,
    handleSearchChange,
    onSearch,
    searchValue
  } = props;

  const filterButtonLabel = `Filter ( ${filterCount} )`;
  const filterButtonColour = (filterCount > 0 ? 'danger' : 'primary');

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-between">
        <Label >{toolbarLabel}</Label>
        <div className="d-flex justify-content-end">
          <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch} value={searchValue}/>
          <Button color={filterButtonColour} size="sm" onClick={onToggleFilter} className="ml-1">{filterButtonLabel}</Button>
          <Button color="success" size="sm" onClick={() => onAdd(null)} className="ml-1 mr-1"><Icon name="plus" className="m-0" /></Button>
          <Button color="success" size="sm" onClick={onRefresh} className="ml-1 mr-1">Refresh</Button>
          <Button color="primary" disabled size="sm" onClick={onRefresh}>Message Selected</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VehiclesToolbar;

import React from 'react';
import { connect } from 'react-redux';

import Mapster from 'jsx/components/core/form/components/Mapster';
import {
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  zoomToBounds
} from 'jsx/components/core/form/lib/mapster';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

class FarmMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      map: null,
      farm: {}
    };

    this.resetGeometry = this.resetGeometry.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.setFarmMap = this.setFarmMap.bind(this);
    this.setFarmBlockMap = this.setFarmBlockMap.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {selectedFarm} = this.props.season;

    if (prevProps.season.selectedFarm.id !== selectedFarm.id) {
      this.setFarmMap();
      this.setFarmBlockMap();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  resetGeometry() {
    const mapSources = [
      {
        id: 'farms',
        layer_id: 'farm-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farms',
        style: 'Polygon',
        onClick: null,
        minzoom: 5,
        maxzoom: 22
      },
      {
        id: 'blocks',
        layer_id: 'block-boundaries',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Farm Blocks',
        style: 'Polygon',
        onClick: null,
        minzoom: 11,
        maxzoom: 22
      }
    ];

    return mapSources;
  }

  async onMapLoad(map) {
    const mapSources = await this.resetGeometry();
  
    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' }
      });

      mapSource.layers = [];
      mapSource.markers = [];

      // Set layers
      const layer = getDefaultLayer(mapSource);
      map.addLayer(layer);

      mapSource.layers.push(layer);

      if (mapSource.onClick) {
        map.on('click', mapSource.layer_id, (event) => {
          mapSource.onClick(event.features[0]);
        });
      }

      // Load onMouseEnter event if defined
      if (mapSource.onMouseEnter) {
        map.on('mouseenter', mapSource.layer_id, (event) => {
          mapSource.onMouseEnter(event.features[0]);
        });
      }

      // Load onMouseLeave event if defined
      if (mapSource.onMouseLeave) {
        map.on('mouseleave', mapSource.layer_id, (event) => {
          mapSource.onMouseLeave(event.features[0]);
        });
      }
    });

    this.setState({ mapSources, map });
  };

  async setFarmMap() {
    const { selectedFarm } = this.props.season;
    const { mapSources, map } = this.state;

    // const featureColour = this.props.mapster.colours.farms;
    const featureAttributes = this.props.mapster.attributes;
    if (selectedFarm) {
      const idx = mapSources.findIndex((source) => source.id === 'farms');

      const featureCollection = buildFeatureCollection();
      
      // selectedFarm.forEach((farm) => {
        const feature = buildFeature(selectedFarm.geom, {
          title: `${selectedFarm.name}`,
          colour: featureAttributes.find(attribute => attribute.id === 'farm_fill').value,
          outline: featureAttributes.find(attribute => attribute.id === 'farm_outline').value,
          strokeWidth: 5,
          labelCaption: selectedFarm.name,
          labelColour: 'red'
        });
        featureCollection.features.push(feature);

      // });

      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);

      if (selectedFarm.geom.type === 'Polygon') {
        zoomToBounds(map, feature.geometry.coordinates[0]);
      } else {
        zoomToBounds(map, feature.geometry.coordinates[0][0]);
      }
      
    }
  }

  async setFarmBlockMap() {
    const { blocks } = this.props.season.selectedFarm;
    const { mapSources, map } = this.state;

    // const featureColour = this.props.mapster.colours.farms;
    const featureAttributes = this.props.mapster.attributes;

    if (blocks && blocks.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'blocks');

      const featureCollection = buildFeatureCollection();
      
      blocks.forEach((block) => {
        const feature = buildFeature(block.geom, {
          title: `${block.name}`,
          colour: featureAttributes.find(attribute => attribute.id === 'block_fill').value,
          outline: featureAttributes.find(attribute => attribute.id === 'block_outline').value,
          strokeWidth: 5,
          labelCaption: block.name,
          labelColour: 'red'
        });
        featureCollection.features.push(feature);

      });

      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
    }
  }

  render() {
    const { mapSources } = this.state;

    return (
      <Mapster
          mapSources={mapSources}
          onMapLoad={this.onMapLoad}
      />
    );
  }
}

const mapStoreToProps = ({ realm, season, mapster }) => ({ realm, season, mapster });
export default connect(mapStoreToProps)(withContainerError(FarmMap));

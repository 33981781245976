import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  active_season: {
    ...controlDefaults,
    placeholder: '',
    name: 'active_season',
    type: 'select',
    caption: 'Active Season',
    options: []
  }
};

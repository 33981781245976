import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import moment from 'moment';

import HarvestManagementToolbar from '../components/HarvestActivityToolbar';
import HarvestManagementLsv from '../components/HarvestActivityLsv';
import HarvestActivityMap from './HarvestActivityMap';

import { fetchMunch } from '../actions/harvesting';
import { fetchFarmBlock } from '../../season/actions';

const HEIGHT_OF_HEADER = '210px';

class HarvestActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        filter_from_date: moment().format('YYYY-MM-DD'),
        filter_to_date: moment().format('YYYY-MM-DD')
      },
      expandMap: true,
      showMunch: false,
      showIms: false,
      showLegend: true
    };

    this.runQuery = this.runQuery.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onDateFilterChange = this.onDateFilterChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
  }

  componentDidMount() {
    // this.props.dispatch(fetchSeasonInspectors());
  }

  onRowClick(block) {
    this.props.dispatch(fetchFarmBlock(block.id));
    // this.props.dispatch(fetchRakes({filter_farm_block_id: block.id}));
  }

  toggleMap() {
    this.setState({expandMap: !this.state.expandMap, showLegend: !this.state.showLegend,})
    this.toggleMunch();
    this.toggleIms();
    // this.state.map.resize();
  }

  toggleMunch() {
    this.setState({showMunch: !this.state.showMunch})
  }

  toggleIms() {
    this.setState({showIms: !this.state.showIms})
  }

  async runQuery(params) {
    const datediff = moment(params['filter_to_date']).diff(params['filter_from_date'], 'days');
    if (datediff > 7) {
      alert('Maximum date range is 7 days to preserve performance');
      return;
    }

    // Run Query
    this.props.dispatch(fetchMunch(params));
  }

  onFilterChange(event) {
    const { params } = this.state;

    params[event.target.id] = event.target.value;
    if (event.target.value === '') {
      delete params[event.target.id];
      // this.runQuery(params);
    }

    if (event.keyCode === 13) {
      // this.runQuery(params);
    }

    this.setState({params});
  }

  onDateFilterChange(id, date) {
    const { params } = this.state;

    params[id] = moment(date).format('YYYY-MM-DD');

    if (id === 'filter_from_date') params['filter_to_date'] = params[id];

    this.setState({params});
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { params, expandMap, showMunch, showIms, showLegend } = this.state;
    const { munch, rakes } = this.props.crop;
    const { selectedBlock, inspectors } = this.props.season;

    const statistics = {rows: munch.length};

    const mapCols = (expandMap ? 6 : 2);
    const lsvCols = (expandMap ? 6 : 10);

    return (
      <div className="p-0 h-100 d-flex flex-column">
        <HarvestManagementToolbar 
          onRun={() => this.runQuery(params)}
          onRowClick={this.onRowClick}
          onFilterChange={this.onFilterChange}
          onDateFilterChange={this.onDateFilterChange}
          toggleMap={this.toggleMap}
          expandMap={expandMap}
          params={params}
          statistics={statistics}
          inspectors={inspectors}
        />
        <Row className="m-0 p-0 h-100">
          <Col className="h-100" sm={lsvCols}>
            <div className="" style={{ overflowY: 'auto', height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} >
              <HarvestManagementLsv 
                rakes={[]}
                rows={munch}
                onRowClick={this.onRowClick}
                selectedBlock={selectedBlock}
                showMunch={showMunch}
                showIms={showIms}
              />
            </div>
          </Col>
          <Col style={{ height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} sm={mapCols}>
            <HarvestActivityMap showLegend={showLegend}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = ({ crop, season }) => ({ crop, season });
export default connect(mapStoreToProps)(withContainerError(HarvestActivity));

import React from 'react';

import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { Row, Col } from 'reactstrap';

import VehiclesLsv from '../components/VehiclesLsv';
import VehiclesToolbar from '../components/VehiclesToolbar';
import TrackingFilterPanel from '../components/TrackingFilterPanel';
import VehicleModal from './VehicleModal';

import { 
  fetchVehicles,
  fetchVehicleTypes,
  fetchVehicleStatuses,
  fetchVehicleOwners,
  setVehicleFilters,
  fetchInspectors
} from '../actions';

import { fetchHarvestGroups, fetchMills } from '../../season/actions';

const HEIGHT_OF_HEADER = '250px';

class VehiclePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilterPanel: false,
      isModalOpen: false,
      id: null,
      // searchValue: null
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterDelete = this.handleFilterDelete.bind(this);
    this.loadVehicles = this.loadVehicles.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.storeSettings = this.storeSettings.bind(this);
  }

  componentDidMount() {
    this.loadVehicles();

    this.props.dispatch(fetchVehicleTypes());
    this.props.dispatch(fetchVehicleStatuses());
    // this.props.dispatch(fetchVehicleOwners());
    this.props.dispatch(fetchInspectors());
    this.props.dispatch(fetchHarvestGroups());
    this.props.dispatch(fetchMills());
  }

  storeSettings(field, value) {
    const { settings } = this.props.vehicles;
    settings[field] = value;

    this.props.dispatch({ type: 'UPDATE_SETTINGS_FULFILLED', payload: settings });
  }

  setModal(row) {
    this.setState({isModalOpen: !this.state.isModalOpen, id: row?.id});
  }

  onToggleFilter() {
    this.setState({showFilterPanel: !this.state.showFilterPanel});
  }

  loadVehicles() {
    const { params, filters } = this.props.vehicles;
    this.props.dispatch(fetchVehicles(params, filters));
  }

  async handleFilterChange(event) {
    const {
      filters,
      params
    } = this.props.vehicles;

    const field = event.target.id;

    if (event.target.type === 'checkbox') {
      filters[field] = (!event.target.checked ? null : event.target.checked);
    } else {
      const exists = filters[field].find(id => id === event.target.value);
      if (!exists) {
        filters[field].push(event.target.value);
      }
    }

    this.props.dispatch(setVehicleFilters(params, filters));

    // Reload
    await this.props.dispatch(fetchVehicles(params, filters));
  }

  async handleFilterDelete(id, field) {
    const {
      filters,
      params
    } = this.props.vehicles;

    const idx = filters[field].findIndex(fid => fid === id);
    filters[field].splice(idx, 1);

    this.props.dispatch(setVehicleFilters(params, filters));

    // Reload
    await this.props.dispatch(fetchVehicles(params, filters));
  }

  handleSearchChange(event) {
    // this.setState({searchValue: event.target.value});
    this.props.dispatch({ type: 'UPDATE_VEHICLE_SEARCHVALUE_FULFILLED', payload: event.target.value });

    if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const {
      filters,
      params,
      searchValue
    } = this.props.vehicles;

    // Add search string to parameters
    params['search_value'] = searchValue;

    // Reload
    this.props.dispatch(fetchVehicles(params, filters));
  }

  getFilterCount() {
    const { filters } = this.props.vehicles;
    const keys = Object.keys(filters);

    let cnt = 0;

    keys.flatMap(key => {
      if (Array.isArray(filters[key])) {
        cnt += filters[key].length;
      }

      if (typeof filters[key] === 'boolean') {
        cnt += (!filters[key] ? 0 : 1);
      }

      return false;
    });

    return cnt;
  }

  render() {
    const { vehicles, types, filters, statuses, owners, inspectors, settings, searchValue } = this.props.vehicles;
    const { showFilterPanel, isModalOpen, id } = this.state;
    const { harvestgroups, mills } = this.props.season;
    const { orphansByLogger } = this.props.loggers;

    const filterCount = this.getFilterCount();
    const filterLabel = `${filterCount} ${(filterCount === 1 ? 'filter' : 'filters')}`;
    const vehicleLabel = `${vehicles.length} ${(vehicles.length === 1 ? 'vehicle' : 'vehicles')}`;
    const toolbarLabel = `${vehicleLabel} (${filterLabel})`;

    return (
      <>
        <VehiclesToolbar
          filterCount={filterCount}
          toolbarLabel={toolbarLabel}
          onRefresh={this.loadVehicles}
          onToggleFilter={this.onToggleFilter}
          onAdd={this.setModal}
          handleSearchChange={this.handleSearchChange}
          onSearch={this.onSearch}
          searchValue={searchValue}
        />

        <VehicleModal setModal={this.setModal} id={id} isOpen={isModalOpen} />

        <Row className="">
          <Col className="">
            <div style={{overflowY: 'auto', height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} >
              <VehiclesLsv orphansByLogger={orphansByLogger} rows={vehicles} onRowClick={this.setModal} />
            </div>
          </Col>
          {showFilterPanel && (
            <Col sm={2} className="p-0 border-left border-corporate border-2">
              <TrackingFilterPanel
                handleFilterChange={this.handleFilterChange}
                handleDelete={this.handleFilterDelete}
                vehicleTypes={types}
                vehicleStatuses={statuses}
                vehicleOwners={owners}
                harvestGroups={harvestgroups}
                mills={mills}
                inspectors={inspectors}
                filters={filters}
                settings={settings}
                storeSettings={this.storeSettings}
              />
            </Col>
          )}
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers, season }) => ({ vehicles, loggers, season });
export default connect(mapStoreToProps)(withContainerError(VehiclePanel));

/* eslint-disable import/no-duplicates */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';

// Regular icons
import { faHouse } from '@fortawesome/pro-regular-svg-icons';

// Light icons
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faDiagramProject } from '@fortawesome/pro-light-svg-icons';
import { faLocationPin } from '@fortawesome/pro-light-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { faFarm } from '@fortawesome/pro-light-svg-icons';
import { faSadTear } from '@fortawesome/pro-light-svg-icons';
import { faVectorSquare } from '@fortawesome/pro-light-svg-icons';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { faLeaf } from '@fortawesome/pro-light-svg-icons';
import { faVial } from '@fortawesome/pro-light-svg-icons';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import { faBookOpenReader } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import { faTruckPickup } from '@fortawesome/pro-light-svg-icons';
import { faFlask } from '@fortawesome/pro-light-svg-icons';
import { faRotate } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { faTasks } from '@fortawesome/pro-light-svg-icons';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import { faDrawPolygon } from '@fortawesome/pro-light-svg-icons';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faBezierCurve } from '@fortawesome/pro-light-svg-icons';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { faObjectGroup } from '@fortawesome/pro-light-svg-icons';
import { faLaptopCode } from '@fortawesome/pro-light-svg-icons';
import { faIndustry } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { faSpa } from '@fortawesome/pro-light-svg-icons';
import { faSnowplow } from '@fortawesome/pro-regular-svg-icons';
import { faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { faCircleDot } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { faArrowsMaximize } from '@fortawesome/pro-light-svg-icons';
import { faExpand } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';
import { faChartMixed } from '@fortawesome/pro-light-svg-icons';
import { faReceipt } from '@fortawesome/pro-light-svg-icons';
import { faTractor } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons';
import { faRightLeft } from '@fortawesome/pro-light-svg-icons';
import { faFence } from '@fortawesome/pro-light-svg-icons';
import { faWeightHanging } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPersonDigging } from '@fortawesome/pro-light-svg-icons';
import { faHandshake } from '@fortawesome/pro-light-svg-icons';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faSheep } from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faInfo } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons';
import { faShredder } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faBookOpen } from '@fortawesome/pro-light-svg-icons';
import { faChartLine } from '@fortawesome/pro-light-svg-icons';
import { faArrowsMinimize } from '@fortawesome/pro-regular-svg-icons';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import { faUserClock } from '@fortawesome/pro-light-svg-icons';
import { faHousePersonLeave } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faStopwatch } from '@fortawesome/pro-light-svg-icons';
import { faTag } from '@fortawesome/pro-light-svg-icons';
import { faTags } from '@fortawesome/pro-light-svg-icons';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { faUserTag } from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { faRotateRight } from '@fortawesome/pro-light-svg-icons';
import { faShare } from '@fortawesome/pro-light-svg-icons';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faSortUp } from '@fortawesome/pro-light-svg-icons';
import { faSortDown } from '@fortawesome/pro-light-svg-icons';
import { faRam } from '@fortawesome/pro-light-svg-icons';
import { faHeartPulse } from '@fortawesome/pro-light-svg-icons';
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons';
import { faCow } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheck } from '@fortawesome/pro-light-svg-icons';
import { faRestroom } from '@fortawesome/pro-light-svg-icons';
import { faMask } from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faWavePulse } from '@fortawesome/pro-light-svg-icons';
import { faVials } from '@fortawesome/pro-light-svg-icons';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { faShapes } from '@fortawesome/free-solid-svg-icons';
import { faChartScatter3d } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faPeopleGroup } from '@fortawesome/pro-light-svg-icons';
import { faStop } from '@fortawesome/pro-light-svg-icons';
import { faEngine } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons';
import { faBoneBreak } from '@fortawesome/pro-light-svg-icons';
import { faBinoculars } from '@fortawesome/pro-light-svg-icons';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons';
import { faRuler } from '@fortawesome/pro-light-svg-icons';
import { faFileChartColumn } from '@fortawesome/pro-light-svg-icons';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons';
import { faCode } from '@fortawesome/pro-light-svg-icons';
import { faGaugeCircleBolt } from '@fortawesome/pro-light-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass as faSolidMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faFunction } from '@fortawesome/pro-light-svg-icons';
import { faMinus } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation as faTriangleExclamationSolid } from '@fortawesome/pro-solid-svg-icons';
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';
import { faUserHelmetSafety } from '@fortawesome/pro-light-svg-icons';
import { faBorderNone } from '@fortawesome/pro-light-svg-icons';
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons';
import { faGripDots } from '@fortawesome/pro-regular-svg-icons';
import { faBusinessTime } from '@fortawesome/pro-regular-svg-icons';
import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons';
import { faRadio } from '@fortawesome/pro-light-svg-icons';
import { faTowerBroadcast } from '@fortawesome/pro-light-svg-icons';
import { faFrame } from '@fortawesome/pro-light-svg-icons';
import { faTrain } from '@fortawesome/pro-light-svg-icons';
import { faTruckFlatbed } from '@fortawesome/pro-light-svg-icons';
import { faTrailer } from '@fortawesome/pro-light-svg-icons';
import { faEmptySet } from '@fortawesome/pro-light-svg-icons';
import { faWheat } from '@fortawesome/pro-light-svg-icons';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { faLineHeight } from '@fortawesome/pro-light-svg-icons';
import { faSquareChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';

const mapToIcon = (name) => {
  switch (name) {
    case 'arrows-maximize':
      return faArrowsMaximize;
    case 'arrows-minimize':
      return faArrowsMinimize;
    case 'arrow-down':
      return faArrowDown;
    case 'arrow-right':
      return faArrowRight;
    case 'arrow-up':
      return faArrowUp;
    case 'arrow-left':
      return faArrowLeft;
    case 'barcode-read':
      return faBarcodeRead;
    case 'bars':
      return faBars;
    case 'bars-sort':
      return faBarsSort;
    case 'bone-break':
      return faBoneBreak;
    case 'border-none':
      return faBorderNone;
    case 'binoculars':
      return faBinoculars;
    case 'book-open':
      return faBookOpen;
    case 'book-open-reader':
      return faBookOpenReader;
    case 'bezier-curve':
      return faBezierCurve;
    case 'business-time':
      return faBusinessTime;
    case 'calendar':
      return faCalendar;
    case 'calendar-days':
      return faCalendarDays;
    case 'chart-line':
      return faChartLine;
    case 'chart-mixed':
      return faChartMixed;
    case 'chart-network':
      return faChartNetwork;
    case 'chart-scatter-3d':
      return faChartScatter3d;
    case 'chevron-up':
      return faChevronUp;
    case 'chevron-right':
      return faChevronRight;
    case 'chevron-down':
      return faChevronDown;
    case 'chevron-left':
      return faChevronLeft;
    case 'chrome':
      return faChrome;
    case 'circle-plus':
      return faCirclePlus;
    case 'circle-minus':
      return faCircleMinus;
    case 'cloud-arrow-down':
      return faCloudArrowDown;
    case 'code-branch':
      return faCodeBranch;
    case 'code':
      return faCode;
    case 'comments':
      return faComments;
    case 'comment':
      return faComment;
    case 'copy':
      return faCopy;
    case 'cow':
      return faCow;
    case 'clock':
      return faClock;
    case 'clipboard-list':
      return faClipboardList;
    case 'camera':
      return faCamera;
    case 'circle-user':
      return faCircleUser;
    case 'circle-dot':
      return faCircleDot;
    case 'circle-notch':
      return faCircleNotch;
    case 'check':
      return faCheck;
    case 'chevron-double-down':
      return faChevronDoubleDown;
    case 'draw-polygon':
      return faDrawPolygon;
    case 'diagram-project':
      return faDiagramProject;
    case 'download':
      return faDownload;
    case 'dollar-sign':
      return faDollarSign;
    case 'edit':
      return faEdit;
    case 'ellipsis':
      return faEllipsis;
    case 'ellipsis-vertical':
      return faEllipsisVertical;
    case 'empty-set':
      return faEmptySet;
    case 'engine':
      return faEngine;
    case 'exclamation-circle':
      return faExclamationCircle;
    case 'expand':
      return faExpand;
    case 'eye':
      return faEye;
    case 'eye-slash':
      return faEyeSlash;
    case 'farm':
      return faFarm;
    case 'face-sad-tear':
      return faSadTear;
    case 'fence':
      return faFence;
    case 'file':
      return faFile;
    case 'file-arrow-down':
      return faFileArrowDown;
    case 'file-chart-column':
      return faFileChartColumn;
    case 'file-pdf':
      return faFilePdf;
    case 'file-excel':
      return faFileExcel;
    case 'filter':
      return faFilter;
    case 'filter-solid':
      return faFilterSolid;
    case 'filter-slash':
      return faFilterSlash;
    case 'frame':
      return faFrame;
    case 'flask':
      return faFlask;
    case 'function':
      return faFunction;
    case 'gauge-circle-bolt':
      return faGaugeCircleBolt;
    case 'gear':
      return faGear;
    case 'grip-dots':
      return faGripDots;
    case 'handshake':
      return faHandshake;
    case 'heart-pulse':
      return faHeartPulse;
    case 'house':
      return faHouse;
    case 'house-person-leave':
      return faHousePersonLeave;
    case 'industry':
      return faIndustry;
    case 'info':
      return faInfo;
    case 'info-circle':
      return faInfoCircle;
    case 'info-circle-solid':
      return faInfoCircleSolid;
    case 'key':
      return faKey;
    case 'laptop-code':
      return faLaptopCode;
    case 'leaf':
      return faLeaf;
    case 'list':
      return faList;
    case 'line-height':
      return faLineHeight;
    case 'lighbulb-on':
      return faLightbulbOn;
    case 'layer-group':
      return faLayerGroup;
    case 'location-pin':
      return faLocationPin;
    case 'location-dot':
      return faLocationDot;
    case 'mug-saucer':
      return faMugSaucer;
    case 'mask':
      return faMask;
    case 'magnifying-glass':
      return faMagnifyingGlass;
    case 'magnifying-glass-solid':
      return faSolidMagnifyingGlass;
    case 'map-marker-alt':
      return faMapMarkerAlt;
    case 'microsoft':
      return faMicrosoft;
    case 'minus':
      return faMinus;
    case 'money-check':
      return faMoneyCheck;
    case 'object-group':
      return faObjectGroup;
    case 'paw':
      return faPaw;
    case 'pen-to-square':
      return faPenToSquare;
    case 'play':
      return faPlay;
    case 'plus':
      return faPlus;
    case 'person-digging':
      return faPersonDigging;
    case 'people-group':
      return faPeopleGroup;
    case 'puzzle-piece':
      return faPuzzlePiece;
    case 'ram':
      return faRam;
    case 'radio':
      return faRadio;
    case 'rotate':
      return faRotate;
    case 'rotate-right':
      return faRotateRight;
    case 'receipt':
      return faReceipt;
    case 'restroom':
      return faRestroom;
    case 'right-from-bracket':
      return faRightFromBracket;
    case 'right-left':
      return faRightLeft;
    case 'seedling':
      return faSeedling;
    case 'ruler':
      return faRuler;
    case 'snowplow':
      return faSnowplow;
    case 'spa':
      return faSpa;
    case 'shapes':
      return faShapes;
    case 'share':
      return faShare;
    case 'shield-check':
      return faShieldCheck;
    case 'sheep':
      return faSheep;
    case 'shredder':
      return faShredder;
    case 'screwdriver-wrench':
      return faScrewdriverWrench;
    case 'star':
      return faStar;
    case 'stop':
      return faStop;
    case 'stopwatch':
      return faStopwatch;
    case 'sort-up':
      return faSortUp;
    case 'sort-down':
      return faSortDown;
    case 'square-chevron-down':
      return faSquareChevronDown;
    case 'table':
      return faTable;
    case 'tag':
      return faTag;
    case 'tags':
      return faTags;
    case 'tasks':
      return faTasks;
    case 'times-circle':
      return faTimesCircle;
    case 'tower-broadcast':
      return faTowerBroadcast;
    case 'tractor':
      return faTractor;
    case 'trailer':
      return faTrailer;
    case 'train':
      return faTrain;
    case 'truck-flatbed':
      return faTruckFlatbed;
    case 'truck-pickup':
      return faTruckPickup;
    case 'trash':
      return faTrash;
    case 'triangle-exclamation':
      return faTriangleExclamation;
    case 'triangle-exclamation-solid':
      return faTriangleExclamationSolid;
    case 'upload':
      return faUpload;
    case 'user':
      return faUser;
    case 'user-tag':
      return faUserTag;
    case 'user-clock':
      return faUserClock;
    case 'user-group':
      return faUserGroup;
    case 'user-helmet':
      return faUserHelmetSafety;
    case 'users':
      return faUsers;
    case 'vector-square':
      return faVectorSquare;
    case 'vial':
      return faVial;
    case 'vials':
      return faVials;
    case 'wave-square':
      return faWaveSquare;
    case 'wave-pulse':
      return faWavePulse;
    case 'weight-hanging':
      return faWeightHanging;
    case 'wheat':
      return faWheat;
    case 'xmark':
    case 'x-mark':
      return faXmark;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown icon name: ${name}`);
  }
  return null;
};

/**
 * Wrapper for our icon implementation. This allows us to make future changes to the
 * icon library without changing implementation details everywhere in the app.
 */
const Icon = ({
  name,
  size = '1x',
  className = '',
  pointer = false,
  title = '',
  as = 'icon',
  isDisabled = false,
  ...props
}) => {
  if (!name) return null;

  const icon = mapToIcon(name);
  if (!icon) return null;

  const preferredTitle = title === '' ? name : title;

  const style = pointer
    ? {
        cursor: 'pointer',
        ...props.style,
      }
    : props.style;

  switch (as) {
    case 'button':
      return (
        <button
          type="button"
          className={`btn btn-link ${className}`}
          aria-label={preferredTitle}
          onClick={props.onClick}
          disabled={isDisabled}
        >
          <FontAwesomeIcon
            icon={icon}
            size={size}
            title={preferredTitle}
            style={style}
            {...props}
            onClick={undefined}
          />
        </button>
      );
    case 'icon':
    default:
      return (
        <FontAwesomeIcon
          icon={icon}
          size={size}
          className={className}
          title={preferredTitle}
          style={style}
          {...props}
        />
      );
  }
};

export default Icon;

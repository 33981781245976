import React from 'react';
import { Button, Label, Input } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const LoggerToolbar = (props) => {
  const {
    onRefresh,
    onFilterChange,
    statistics
  } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-1 d-flex justify-content-between">
        <div>
          <Label size="sm" className="bg-success p-1 pl-2 pr-2 text-white border border-success rounded-left">Total Tonnes</Label>
          <Label size="sm" className="text-success bg-white p-1 pl-3 pr-3 border border-success rounded-right mr-3">{parseInt(statistics?.tonnes).toLocaleString()}</Label>
          <Label size="sm" className="bg-orange p-1 pl-2 pr-2 text-white border border-orange rounded-left">Total Bins</Label>
          <Label size="sm" className="text-orange bg-white p-1 pl-3 pr-3 border border-orange rounded-right">{parseInt(statistics?.bins).toLocaleString()}</Label>
        </div>
        <span className="">
          <Label size="sm" className="mr-2 float-left">Filter:</Label>
          <Input size="sm" className="mr-2 float-left" id="filter_group_no" placeholder='Group No' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_farm_no" placeholder='Farm No' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_mill_id" placeholder='Mill' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_payment_week_no" placeholder='Pay Week' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_week_no" placeholder='Crush Week' onKeyUp={onFilterChange} style={{width: 100}}/>
          <Input size="sm" className="mr-2 float-left" id="filter_crushday" placeholder='Crush Day' onKeyUp={onFilterChange} style={{width: 100}} />
          <Input size="sm" className="mr-2 float-left" id="filter_connote" placeholder='Connote' onKeyUp={onFilterChange} style={{width: 100}} />
          <Input size="sm" className="mr-2 float-left" id="filter_sample_no" placeholder='Sample No' onKeyUp={onFilterChange} style={{width: 100}} />
          <Button color="success" size="sm" onClick={onRefresh} className="ml-1 mr-1 float-left"><Icon name="rotate" className="text-white" /></Button>
        </span>

      </div>
    </React.Fragment>
  );
};

export default LoggerToolbar;

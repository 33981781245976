import { fetchFarmBlockCrops } from '../../season/actions';
import {
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  zoomToBounds
} from 'jsx/components/core/form/lib/mapster';

/**
 * Get Crop colour from reducer
 */
export const getCropColour = (component, status_tag) => {
  const featureAttributes = component.props.mapster.attributes;

  switch(status_tag) {
    case 'h': return featureAttributes.find(attribute => attribute.id === 'block_fill_harvested').value;
    case 'f': return featureAttributes.find(attribute => attribute.id === 'block_fill_fallow').value;
    case 'c': 
    default: return featureAttributes.find(attribute => attribute.id === 'block_fill_crop').value;
  }
}

/**
 * Get farmblock crops using geowindow
 */
export const getFarmBlockCrops = async (component, eventType) => {
  const { map, mapSources, abortController } = component.state;
  const minZoomLevel = 12;

  if (map.getZoom() < minZoomLevel) {
    console.log("Crop Too far away")
    return;
  }

  const bounds = map.getBounds();
  const params = {bounds: [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat]};
  const limit = 500;

  if (eventType === 'zoomend' || eventType === 'dragend') {
    // Get Farm Blocks
    const count = await component.props.dispatch(fetchFarmBlockCrops({...params, preflight: true}));
    const loops = parseInt(count / limit) + 1;

    // Clear blocks
    const idx = mapSources.findIndex((source) => source.id === 'blocks');
    mapSources[idx].source = buildFeatureCollection();
    component.setState({mapSources});

    const timestampBlocks = new Date().getTime();
    component.setState({timestampBlocks});

    for (let idx = 0; idx < loops; idx++) {
      component.setState({featureFetching: component.state.featureFetching + 1});

      const offset = idx * limit;
      component.props.dispatch(fetchFarmBlockCrops({...params, limit, offset}, true, abortController)).then((crops) => setFarmBlockCropMap(component, crops, timestampBlocks));
    }
  }
}

/**
 * Set farmblock crops using geowindow
 */
export const setFarmBlockCropMap = async (component, crops, timestamp) => {
  // Avoid cancelled requests
  if (component.state.timestampBlocks !== timestamp) {
    component.setState({featureFetching: component.state.featureFetching - 1});
    return;
  }

  const { mapSources, map } = component.state;
  const { selectedBlock } = component.props.season;

  const featureAttributes = component.props.mapster.attributes;

  if (crops && crops.length > 0) {
    const idx = mapSources.findIndex((source) => source.id === 'blocks');
    const labelIdx = mapSources.findIndex((source) => source.id === 'block-labels');

    const featureCollection = buildFeatureCollection();
    const labelCollection = buildFeatureCollection();

    crops.forEach((block) => {
      const outline = (selectedBlock && selectedBlock.id === block.block_id ? 'red' : featureAttributes.find(attribute => attribute.id === 'block_outline').value);
      const colour = (selectedBlock && selectedBlock.id === block.block_id ? 'pink' : getCropColour(component, block.status_tag));
      const feature = buildFeature(block.geom, {
        colour,
        outline,
      });
      featureCollection.features.push(feature);

      // Label
      const featureText = buildFeature(block.geom, {
        labelCaption: block.block_name,
        labelColour: 'red',
        labelSize: 14
      });
      labelCollection.features.push(featureText);
    });

    mapSources[idx].source.features = mapSources[idx].source.features.concat(featureCollection.features);
    map.getSource(mapSources[idx].id).setData(mapSources[idx].source);
  
    mapSources[labelIdx].source.features = mapSources[idx].source.features.concat(labelCollection.features);
    map.getSource(mapSources[labelIdx].id).setData(mapSources[labelIdx].source);

    component.setState({featureFetching: component.state.featureFetching - 1});
  }
}

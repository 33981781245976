import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Vehicle Name',
    validationRules: {
      isRequired: true
    }
  },
  harvestgroup_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'harvestgroup_id',
    type: 'select',
    caption: 'Harvest Group',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Owner of Vehicle. This is a required field'
  },
  logger_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'logger_id',
    type: 'select',
    caption: 'Logger',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Assigned vehicle logger. This is a required field'
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_id',
    type: 'select',
    caption: 'Vehicle Type',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    },
    description: 'Type of vehicle (Harvester, Loco, MudTruck etc). This is a required field'
  },
  enabled: {
    ...controlDefaults,
    placeholder: '',
    name: 'enabled',
    type: 'select',
    caption: 'Enabled Vehicle',
    options:[
      {id: 0, name: 'No'},
      {id: 1, name: 'Yes'}
    ]
  },
  attach_orphaned: {
    ...controlDefaults,
    placeholder: '',
    name: 'attach_orphaned',
    type: 'select',
    caption: 'Attach Logger orphaned tracks',
    options:[
      {id: 0, name: 'No'},
      {id: 1, name: 'Yes'}
    ]
  }
};

import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';

const LoggersLsv = (props) => {
  const {
    rows,
    onRowClick
  } = props;

  const renderRows = (headers, row) => {

    const logger_type = row?.logger_type?.name;
    const vehicleName = (row.vehicle?.name ? row.vehicle?.name : 'ORPHANED LOGGER, No Vehicle');
    const vehicleColour = (row.vehicle?.name ? 'green' : 'red');

    const enabled = (row.enabled ? 'Yes' : 'No');
    const enabledColour = (row.enabled ? 'Green' : 'Red');

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'logger_type': return  (
          <td key={index} className={header.classes}>{logger_type}</td>
        );
        case 'vehicle': return  (
          <td key={index} className={header.classes} style={{color: vehicleColour}}>{vehicleName}</td>
        );
        case 'enabled': return  (
          <td key={index} className={header.classes} style={{color: 'white', backgroundColor: enabledColour}}>{enabled}</td>
        );
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const headers = [
    {caption: 'Logger Imei', field: 'imei', classes:'text-left'},
    {caption: 'Model', field: 'logger_type', classes:'text-left'},
    {caption: 'Mobile No', field: 'mobile_no', classes:'text-left'},
    {caption: 'SIM Imei', field: 'sim_imei', classes:'text-left'},
    {caption: 'Vehicle', field: 'vehicle', classes:'text-left'},
    {caption: 'Created', field: 'created', classes:'text-left'},
    {caption: 'Enabled', field: 'enabled', classes:'text-right'}
  ];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onRowClick(row)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='radio';
  const emptyCaption='No Loggers found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
};

export default LoggersLsv;

import React from 'react';
import { FormGroup, Label, Col } from 'reactstrap';

const FormInputCheckbox = (props) => {
  const { control, className, handleChange } = props;

  return (
    <>
      <FormGroup className="mt-0 mb-0 d-flex justify-content-start">
        {control.caption && (
          <Label for={control.name} className="font-weight-bold mr-3 mt-2">
            {control.caption}
          </Label>
        )}
        <input
          type="checkbox"
          id={control.id ? control.id : control.name}
          name={control.name}
          checked={control.value}
          className={className}
          onChange={handleChange}
        />

      </FormGroup>
    </>
  );
};

export default FormInputCheckbox;

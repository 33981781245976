import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Mapster from 'jsx/components/core/form/components/Mapster';
import {
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  zoomToBounds
} from 'jsx/components/core/form/lib/mapster';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import markerIcon from '../../../../../images/canecutter/map-marker-2-64.png';
import stoppedIcon from '../../../../../images/canecutter/empty-circle-64.png';

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      map: null,
      farm: {},
      vehicle: {}
    };

    this.resetGeometry = this.resetGeometry.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.setVehicleMap = this.setVehicleMap.bind(this);
  }

  componentDidUpdate() {
    const { vehicle } = this.state;

    if (vehicle.id !== this.props.vehicle.id) {
      this.setState({vehicle: this.props.vehicle});
    }

    if (vehicle?.current_track_id !== this.props.vehicle?.current_track_id) {
      this.setVehicleMap();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  resetGeometry() {
    const mapSources = [
      {
        id: 'vehicles',
        layer_id: 'vehicle-symbols',
        source: { type: 'FeatureCollection', features: [] },
        title: 'Vehicles',
        style: 'PointSymbol',
        showMarker: true,
      }
    ];

    return mapSources;
  }

  async onMapLoad(map) {
    const mapSources = await this.resetGeometry();
  
    // map.loadImage
    map.loadImage(markerIcon, (error, image) => {
      if (error) throw error;
      map.addImage('markerIcon', image, { 'sdf': true });
    });
    
    map.loadImage(stoppedIcon, (error, image) => {
      if (error) throw error;
      map.addImage('stoppedIcon', image, { 'sdf': true });
    });

    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' }
      });

      mapSource.layers = [];
      mapSource.markers = [];

      // Set layers
      const layer = getDefaultLayer(mapSource);
      map.addLayer(layer);

      mapSource.layers.push(layer);

      if (mapSource.onClick) {
        map.on('click', mapSource.layer_id, (event) => {
          mapSource.onClick(event.features[0]);
        });
      }

      // Load onMouseEnter event if defined
      if (mapSource.onMouseEnter) {
        map.on('mouseenter', mapSource.layer_id, (event) => {
          mapSource.onMouseEnter(event.features[0]);
        });
      }

      // Load onMouseLeave event if defined
      if (mapSource.onMouseLeave) {
        map.on('mouseleave', mapSource.layer_id, (event) => {
          mapSource.onMouseLeave(event.features[0]);
        });
      }
    });

    this.setState({ mapSources, map });
  };

  async setVehicleMap() {
    const { mapSources, map } = this.state;
    const { vehicle } = this.props;
    const { settings } = this.props.vehicles;

    if (vehicle?.last_track) {
      const featureAttributes = this.props.mapster.attributes;
      const idx = mapSources.findIndex((source) => source.id === 'vehicles');

      const featureCollection = buildFeatureCollection();

      const { status } = vehicle;
      const feature = buildFeature(vehicle.last_track.coords, {
        title: `${vehicle.name}`,
        id: vehicle.id,
        iconColour: status?.colour,
        icon: (vehicle.last_track.attributes?.speed ? 'markerIcon' : 'stoppedIcon'),
        iconSize: 0.5,
        strokeWidth: 5,
        labelCaption: (settings.showVehicleLabels ? vehicle.name : null),
        iconRotation: vehicle.last_track.attributes.direction,
        labelColour: featureAttributes.find(attribute => attribute.id === 'vehicle_label_colour').value,
      });

      featureCollection.features.push(feature);
      mapSources[idx].source = featureCollection;
      map.getSource(mapSources[idx].id).setData(mapSources[idx].source);

      zoomToBounds(map, [feature.geometry.coordinates]);
    }
  }

  render() {
    const { mapSources } = this.state;
    const { vehicle } = this.props;
  
    const updated_ago = moment.utc(vehicle?.updated).local().fromNow();
    const attributesLeft = `Status: ${vehicle?.status?.name}`;
    const attributesRight = `Speed: ${vehicle?.last_track?.attributes.speed}km/h, Heading: ${vehicle?.last_track?.attributes.direction}, Last Seen: ${updated_ago}`;

    return (
      <>
        <div className="d-flex justify-content-between bg-light p-1">
          <small style={{color: vehicle?.status?.colour}}>{attributesLeft}</small>
          <small className="text-black">{attributesRight}</small>
        </div>
        
        <div style={{position: 'relative'}} className="h-100" >Current Location
          <Mapster
              mapSources={mapSources}
              onMapLoad={this.onMapLoad}
          />
        <div>asdfasdf</div>
        
        </div>

      </>

    );
  }
}

const mapStoreToProps = ({ realm, season, mapster, vehicles }) => ({ realm, season, mapster, vehicles });
export default connect(mapStoreToProps)(withContainerError(VehicleMap));

import socketIOClient from 'socket.io-client';
import { ioUrl, ioOptions } from '../constants/api';

class SocketReducer {
  constructor(props) {
    this.props = props;

    this.socket = socketIOClient(ioUrl, ioOptions);
    this.setTopics();
  }

  setTopics() {
    this.socket.on('topicTest', (data) => this.handleTest(data));
    this.socket.on('topicTrackUpdate', (data) => this.handleTrackUpdate(data));
  }

  handleTest(data) {
    try {
      console.log('got some data', data);
    } catch (_) {
      console.log(_);
      console.error(`SOCKETREDUCER Error(handlePipelineUpdate): ${data}`);
    }
  }

  handleTrackUpdate(data) {
    try {
      this.props.dispatch({ type: 'UPDATE_CURRENT_TRACK_TOPIC', payload: data });
      console.log("Socket", data)
    } catch (_) {
      console.log(_);
      console.error(`SOCKETREDUCER Error(handleTrackUpdate): ${data}`);
    }
  }
}

export default SocketReducer;

import React from 'react';

import { Button, Label, Input } from 'reactstrap';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';

const TracksToolbar = (props) => {
  const {
    onRefresh,
    toolbarLabel,
    handleSearchChange,
    onSearch,
    onLiveUpdateChange,
    liveUpdateChecked
  } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-between">
        <Label >{toolbarLabel}</Label>
        <div className="d-flex justify-content-end">
          <div className="mr-2">
            <Input type="checkbox" onChange={onLiveUpdateChange} checked={liveUpdateChecked}/>
            <small>Live Update</small>
          </div>
          <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch}/>
          <Button color="success" size="sm" onClick={onRefresh} className="ml-1 mr-1">Refresh</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TracksToolbar;

import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  firstname: {
    ...controlDefaults,
    name: 'firstname',
    type: 'text',
    caption: 'First Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  lastname: {
    ...controlDefaults,
    name: 'lastname',
    type: 'text',
    caption: 'Last Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  email: {
    ...controlDefaults,
    name: 'email',
    type: 'text',
    caption: 'Email',
    disabled: false,
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
  },
  mobile: {
    ...controlDefaults,
    name: 'mobile',
    type: 'text',
    caption: 'Mobile',
    disabled: false,
    validationRules: {
      isRequired: false,
    },
  },
  enabled: {
    ...controlDefaults,
    name: 'enabled',
    type: 'switch',
    caption: 'User Status',
    disabled: false,
    validationRules: {},
    description: 'Users can not be deleted, just disabled',
    descriptionStatus: { switchOn: 'Enabled. Can login', switchOff: 'Disabled. Cannot login' },
  },

  is_locked: {
    ...controlDefaults,
    name: 'is_locked',
    type: 'select',
    caption: 'Lock Status',
    disabled: false,
    validationRules: {},
    description:
      'Prevents login access for user. Automatically set if too many unsuccessful attempts.',
    descriptionStatus: {
      switchOn: 'Locked. User can not login',
      switchOff: 'Unlocked. User can login',
    },
  },
  login_attempts: {
    ...controlDefaults,
    name: 'login_attempts',
    type: 'number',
    caption: 'Unsuccessful Login Attempts',
    description: 'Maximum 4 consecutive login allowed before account is locked.',
    disabled: false,
    validationRules: {
      isRequired: false,
    },
  },
  token: {
    ...controlDefaults,
    name: 'token',
    type: 'text',
    caption: 'API Access Token',
    disabled: true,
    allow_copy: true,
    validationRules: {
      isRequired: false,
    },
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FarmsLsv from '../components/FarmsLsv';
import FarmMap from './FarmMap';
import { fetchFarms, fetchFarm } from '../actions';
import FarmsToolbar from '../components/FarmsToolbar';

const HEIGHT_OF_HEADER = '210px';

class Farms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    // this.props.dispatch(fetchFarms({exclude: ['geom']}));
  }

  onRowClick(farm) {
    this.props.dispatch(fetchFarm(farm.id));
  }

  render() {
    const { farms, selectedFarm } = this.props.season;

    return (
      <>
        <FarmsToolbar />
        <Row className="m-0 p-0 h-100">
          <Col className="h-100">
            <div className="" style={{overflowY: 'auto', height: `calc(100vh - ${HEIGHT_OF_HEADER})`}} >
              <FarmsLsv rows={farms} onRowClick={this.onRowClick} selectedFarm={selectedFarm}  />
            </div>
          </Col>
          <Col sm={4}>
            <FarmMap />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ realm, season }) => ({ realm, season });
export default connect(mapStoreToProps)(withContainerError(Farms));

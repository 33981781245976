import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  parent_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'owner_id',
    type: 'select',
    caption: 'Owner',
    options:[
      {id: null, name: 'None Selected'}
    ],
    validationRules: {
      isRequired: true
    }
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true
    }
  },
};

import { combineReducers } from 'redux';

import loggers from 'jsx/components/modules/vehicles/reducers/logger';
import vehicles from 'jsx/components/modules/vehicles/reducers';
import season from 'jsx/components/modules/season/reducers';
import crop from 'jsx/components/modules/cropping/reducers';

import ajaxRequests from '../components/core/loader/reducers';
import auth from '../components/core/authentication/reducers';
import manage from '../components/manage/reducers';
import profile from '../components/manage/reducers/profile';
import realm from './realm';
import mapster from './mapster';
import activities from '../components/core/activity/reducers';

// Top level reducers
const appReducer = combineReducers({
  ajaxRequests,
  auth,
  manage,
  profile,
  realm,
  mapster,
  activities,
  loggers,
  vehicles,
  season,
  crop
});

const rootReducer = (state, action) => {
  // Setting undefined state reinitializes each combined reducers default state.
  if (action.type === 'SET_LOGOUT_FULFILLED') return appReducer(undefined, action);

  return appReducer(state, action);
};

export default rootReducer;

import { noop } from 'lodash';
import { Button, Label } from 'reactstrap';
import PageTitleH5 from '../../core/form/components/PageTitleH5';
import SingleSearch from '../../core/form/components/SingleSearch';

const UsersToolbar = (props) => {
  const { filterClick = noop, handleSearchChange, openModal = noop, searchValue, users } = props;

  const count = `${users?.length} users`;
  const iconName = 'users';
  const title = 'Users';

  return (
    <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
      <PageTitleH5 iconName={iconName} title={title} />
      <div className="m-1 d-flex justify-content-end">
        <Label className="m-1 mr-2 text-secondary">{count}</Label>
        <SingleSearch
          handleSearchChange={handleSearchChange}
          placeholder="Filter by keyword"
          value={searchValue}
        />
        {filterClick && (
          <Button
            id="toggler"
            size="sm"
            color="warning"
            className="text-white mr-2"
            onClick={() => filterClick()}
          >
            Filter
          </Button>
        )}
        <Button color="success" size="sm" onClick={() => openModal(true)}>
          Invite New User
        </Button>
      </div>
    </div>
  );
};

export default UsersToolbar;

import {
    apiFarms,
    apiFarmBlocks,
    apiDefaults,
    apiSeasons,
    apiSeasonInspectors,
    apiGroupAssocs,
    apiHarvestGroups,
    apiMills
  } from 'jsx/constants/api';
  
  import { get, put, post } from 'jsx/lib/genericAction';
  
  export function fetchFarms(params) {
    return async (dispatch) => get(dispatch, 'FETCH_FARMS', apiFarms, params);
  }

  export function fetchFarm(id) {
    return async (dispatch) => get(dispatch, 'FETCH_FARM', `${apiFarms}/${id}`);
  }

  export function fetchFarmBlock(id) {
    return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCK', `${apiFarmBlocks}/${id}`);
  }

  export function fetchFarmAssocs(params = {}) {
    params.exclude = 'geom';
    return async (dispatch) => get(dispatch, 'FETCH_FARM_ASSOCS', `${apiFarms}/assocs`, params);
  }

  export function fetchFarmAssoc(id) {
    return async (dispatch) => get(dispatch, 'FETCH_FARM_ASSOC', `${apiFarms}/assocs/${id}`);
  }

  export function updateFarmAssoc(data) {
    return async (dispatch) =>
      put(dispatch, 'UPDATE_FARM_ASSOC', `${apiFarms}/assocs/${data.id}`, data);
  }

  export function createFarmAssoc(data) {
    return async (dispatch) => post(dispatch, 'CREATE_FARM_ASSOC', `${apiFarms}/assocs`, data);
  }

  export function fetchFarmBlocks(params, inBackground = true, abortController = null) {
    return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCKS', apiFarmBlocks, params, null, inBackground, abortController);
  }

  export function fetchFarmBlockCrops(params, inBackground = true, abortController = null) {
    return async (dispatch) => get(dispatch, 'FETCH_FARM_BLOCK_CROPS', `${apiFarmBlocks}/crops`, params, null, inBackground, abortController);
  }

  export function fetchHarvestGroups() {
    return async (dispatch) => get(dispatch, 'FETCH_HARVEST_GROUPS', apiHarvestGroups);
  }

  export function fetchGroupAssocs() {
    return async (dispatch) => get(dispatch, 'FETCH_GROUP_ASSOCS', apiGroupAssocs);
  }

  export function fetchSeasonDefaults() {
    return async (dispatch) => get(dispatch, 'FETCH_SEASON_DEFAULTS', apiDefaults);
  }

  export function updateSeasonDefaults(data) {
    return async (dispatch) =>
      put(dispatch, 'UPDATE_SEASON_DEFAULTS', `${apiDefaults}/${data.id}`, data);
  }

  export function fetchSeasons() {
    return async (dispatch) => get(dispatch, 'FETCH_SEASONS', apiSeasons);
  }

  export function fetchMills() {
    return async (dispatch) => get(dispatch, 'FETCH_MILLS', apiMills);
  }

  export function fetchSeasonInspectors() {
    return async (dispatch) => get(dispatch, 'FETCH_SEASON_INSPECTORS', apiSeasonInspectors);
  }

import React from 'react';
import { 
  Input
} from 'reactstrap';

const FormInputSmall = (props) => {
  const {
    handleChange,
    control,
    className,
    style
  } = props;

  const createOptions = (options) => {
    if (options) {
      return options.map((option, index) => 
        <option key={index} value={option.id}>{option.name}</option>
      )
    } else {
      return null
    }
  }

  const textRows = control.textRows || 3;

  return (
    <React.Fragment>
      <Input
        bsSize="sm"
        type={control.type}
        name={control.name}
        className={className}
        style={style}
        id={control.name}
        value={control.value || ''}
        placeholder={control.placeholder}
        onChange={handleChange}
        rows={textRows}
        disabled={control.disabled}
        checked={control.value}
      >
        {createOptions(control.options)}
      </Input>
      { control.touched && !control.valid && (<small className="text-danger">{control.message}</small>)}
      { control.description && (<small className="text-success ml-1">{control.description}</small>)}
    </React.Fragment>
  )
}

export default FormInputSmall;

import axios from "axios";
import {
  currentUserUrl
} from '../../../constants/api.js';

import errorResponse from '../../../lib/errorResponse.js';

export function fetchCurrentInvitations() {
  return function(dispatch) {
    dispatch({type: "FETCH_CURRENTINVITATIONS_PENDING"});

    axios({
      method: 'GET',
      url: `${currentUserUrl}/invitations`
    })
    .then((response) => {
      dispatch({type: "FETCH_CURRENTINVITATIONS_FULFILLED", payload: response.data.result});
    })
    .catch((err) => {
      errorResponse(dispatch, err, 'FETCH_CURRENTINVITATIONS_REJECTED');
    })
  }
}

export function fetchCurrentUser() {
  return function(dispatch) {
    dispatch({type: "FETCH_CURRENTUSER_PENDING"});

    axios({
      method: 'GET',
      url: `${currentUserUrl}`
    })
    .then((response) => {
      dispatch({type: "FETCH_CURRENTUSER_FULFILLED", payload: response.data.result});
    })
    .catch((err) => {
      errorResponse(dispatch, err, 'FETCH_CURRENTUSER_REJECTED');
    })
  }
}

export function updateCurrentUser(user) {
  return function(dispatch) {
    dispatch({type: "UPDATE_CURRENTUSER_PENDING"});

    axios({
      method: 'PUT',
      url: currentUserUrl,
      data: user,
    })
    .then((response) => {
      dispatch({type: "UPDATE_CURRENTUSER_FULFILLED"})
    })
    .catch((err) => {
      errorResponse(dispatch, err, 'UPDATE_CURRENTUSER_REJECTED');
    })
  }
}

export function updateCurrentInvite(inviteId, newStatus) {
  return function(dispatch) {
    dispatch({type: "UPDATE_CURRENTINVITE_PENDING"});

    const data = {'status': newStatus}
    axios({
      method: 'PUT',
      url: `${currentUserUrl}/invitations/${inviteId}`,
      data
    })
    .then((response) => {
      dispatch({type: "UPDATE_CURRENTINVITE_FULFILLED"})
      dispatch(fetchCurrentInvitations())
    })
    .catch((err) => {
      errorResponse(dispatch, err, 'UPDATE_CURRENTINVITE_REJECTED');
    })
  }
}
import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';
import { Input } from 'reactstrap';
import RakesLsv from './RakesLsv';
import Icon from 'jsx/components/core/icons/Icon';

const HarvestActivityLsv = (props) => {
  const {
    rows,
    rakes,
    onRowClick,
    selectedBlock,
    showMunch,
    showIms
  } = props;

  const renderRows = (headers, row) => {
    const block_name = row?.farm_block?.name;
    const mill = row?.farm?.assocs[0]?.assigned_mill?.tag.toUpperCase();
    const group = row?.farm?.assocs[0]?.harvestgroup?.external_id;
    const inspector = row?.farm?.assocs[0]?.harvestgroup?.inspector_assoc?.inspector?.firstname;

    const tracks_area = row?.crop_tracks.reduce((accumulator, item) => accumulator + item.area_ha, 0);
    const ims_complete = (row.ims_complete ? 'Yes' : 'No')
    const ims_complete_class = (!row.ims_complete && parseFloat(row.munch_ha_crop) === 0 ? 'border border-danger bg-danger-20' : (row.ims_complete ? 'text-success' : ''));
    const ims_completed_title = (ims_complete_class ? 'Block has zero remaining hectares but not complete in IMS' : '');

    // Check if block have remaining ha but flagged as complete in IMS
    let area_class = (parseFloat(row.munch_ha_crop) > 0 && row.ims_complete ? 'border border-danger bg-danger-20' : '');
    let area_title = (area_class ? 'Block have remaining hectares but flagged as complete in IMS' : '');

    area_class = (parseFloat(row.munch_ha_crop) === 0 ? 'bg-success-20 border border-success' : '');
    area_title = (area_class ? 'No hectares remain on block' : '');

    const harvest_diff = row.ims_tons_estimate - row.ims_tons_harvested;
    const harvested_class = (harvest_diff < 2  && !row.ims_complete && harvest_diff !== 0 ? 'bg-orange-20 border border-orange ' : '');
    const harvested_title = (harvested_class ? 'Tonnes harvested are larger than IMS estimate' : '');

    const ims_estha_class = (row.ims_estha > 140 ? 'border border-danger bg-danger-20' : '');
    const ims_estha_title = (row.ims_estha > 140 ? 'IMS Hectare estimate above 140 t/ha' : '');

    const munch_pct_complete_class = (row.munch_pct_complete > 0 && row.munch_pct_complete < 100 ? 'text-primary' : '');
    const munch_pct_complete_title = (row.munch_pct_complete > 0 && row.munch_pct_complete < 100 ? 'Block in Progress' : '');

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'checkbox': return  (
          <td key={index} className={header.classes}><input type="checkbox" /></td>
        );
        case 'tracks_area': return  (
          <td key={index} className={header.classes}>{tracks_area}</td>
        );
        case 'ims_estha': return  (
          <td key={index} className={`${header.classes} ${ims_estha_class}`} title={ims_estha_title}>{row.ims_estha}</td>
        );
        case 'ims_complete': return  (
          <td key={index} title={ims_completed_title} className={`${header.classes} ${ims_complete_class}`}>{ims_complete}</td>
        );
        case 'munch_ha_harvested': return  (
          <td key={index} title={area_title} className={`${header.classes} ${area_class}`}>{row.munch_ha_harvested}</td>
        );
        case 'ims_tons_estimate': return  (
          <td key={index} title={harvested_title} className={`${header.classes} ${harvested_class}`}>{row.ims_tons_estimate.toFixed(2)}</td>
        );
        case 'ims_tons_harvested': return  (
          <td key={index} className={`${header.classes} ${harvested_class}`}>{row.ims_tons_harvested}</td>
        );
        case 'inspector': return  (
          <td key={index} className={header.classes}>{inspector}</td>
        );
        case 'group': return  (
            <td key={index} className={header.classes} data-sort-value={row[header.sortColumn]}>{group}</td>
        );
        case 'block_name': return  (
          <td key={index} className={header.classes}>{block_name}</td>
        );
        case 'mill': return  (
          <td key={index} className={header.classes}>{mill}</td>
        );
        case 'munch_tha': return  (
          <td key={index} className={header.classes}>{row?.munch_tha || 0}</td>
        );
        case 'munch_tha_actual': return  (
          <td key={index} className={header.classes}>{row?.munch_tha_actual?.toFixed(2)}</td>
        );
        case 'munch_tonnes_remaining': return  (
          <td key={index} className={header.classes}>{row?.munch_tonnes_remaining?.toFixed(2)}</td>
        );
        case 'munch_pct_complete': return  (
          <td key={index} className={`${header.classes} ${munch_pct_complete_class}`} title={munch_pct_complete_title}>{`${row?.munch_pct_complete?.toFixed(0)}%`}</td>
        );
        default: return  (
          <td key={index} className={`${header.classes}`}>{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const upperHeaders = [
    { caption: null, cells: 7, classes: 'border-bottom-0' },
    { caption: 'Area (Hectares)', cells: 3, classes: 'text-center bg-orange border border-lightgray text-white' },
    // { caption: 'Munch', cells: 5, classes: 'text-center bg-primary border border-lightgray text-white' },
    // { caption: 'IMS', cells: 7, classes: 'text-center border-bottom-0 bg-pink text-white' },
  ];


  let headers = [
    {caption: '-', field: 'checkbox'},
    {caption: 'Block', field: 'name', classes:'text-left'},
    {caption: 'Variety', field: 'variety_code', classes:'text-left'},
    {caption: 'Class', field: 'class_code', classes:'text-center'},
    {caption: 'Group No', field: 'group', classes:'text-center', sortColumn: 'group'},
    {caption: 'Inspector', field: 'inspector', classes:'text-center'},
    {caption: 'Mill', field: 'mill', classes:'text-center'},
  
    {caption: 'Block', field: 'block_area_ha', classes:'text-right'},
    {caption: 'Harvested', field: 'munch_ha_harvested', classes:'text-right'},
    {caption: 'Remaining', field: 'munch_ha_crop', classes:'text-right'},
    // {caption: 'Tracks', field: 'tracks_area', classes:'text-right'},
  ];

  if (showMunch) {
    upperHeaders.push({ caption: 'Munch', cells: 5, classes: 'text-center bg-primary border border-lightgray text-white' });
    headers = headers.concat([
      {caption: 'Actual (t/ha)', field: 'munch_tha_actual', classes:'text-right'},
      {caption: 'Remaining (t)', field: 'munch_tonnes_remaining', classes:'text-right'},
      {caption: 'Complete (%)', field: 'munch_pct_complete', classes:'text-right'},
      {caption: 't/ha', field: 'munch_tha', classes:'text-right'},
      {caption: 'Estimate (t)', field: 'munch_tonnes_estimate', classes:'text-right'},
    ]);
  }

  if (showIms) {
    upperHeaders.push({ caption: 'IMS', cells: 7, classes: 'text-center border-bottom-0 bg-pink text-white' });
    headers = headers.concat([
      // {caption: 'Transfer Cane', field: 'class_code', classes:'text-left'},
      {caption: 'Harvested (t)', field: 'ims_tons_harvested', classes:'text-right'},
      {caption: 'Estimate (t)', field: 'ims_tons_estimate', classes:'text-right'},
      {caption: 'Estimate (t/ha)', field: 'ims_estha', classes:'text-right'},
      {caption: 'Area (ha)', field: 'ims_area', classes:'text-right'},
      // {caption: 'Harvested (ha)', field: 'ims_area_harvested', classes:'text-right'},
      {caption: 'Remaining (ha)', field: 'ims_area_available', classes:'text-right'},
      {caption: 'Complete', field: 'ims_complete', classes:'text-right'}
    ]);
  }

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      const rowClass = (selectedBlock && selectedBlock.id === row.id ? 'bg-success-40 border-right border-left border-success text-weight-bold' : null);
      return (
        <tr key={index} onClick={() => onRowClick(row)} style={{cursor: 'pointer'}} className={rowClass}>
          {renderRows(headers, row)}
        </tr>
      );
    });
  }

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  const iconName='line-height';
  const emptyCaption='Select a Activity date range above together with any other filter and click Query to retrieve results. Once the list of blocks is showing, click on row to see tracks.';

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh} 
      tableBodyTr={tableBodyTr} 
      iconName={iconName}
      emptyCaption={emptyCaption}
      // onLoadMore={onLoadMore}
      isVerticalScrollEnabled
      upperHeadTh={upperHeadTh}
    />
  );
};

export default HarvestActivityLsv;

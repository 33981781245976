import React from 'react';
import { connect } from 'react-redux';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { Nav, TabContent, TabPane } from 'reactstrap';
import PageTitle from '../../../core/form/components/PageTitle';
import FormTab from '../../../core/form/components/FormTab';

import VehiclePanel from './VehiclePanel';
import LoggerPanel from './LoggerPanel';
import TracksPanel from './TracksPanel';

import { fetchTracks, fetchTrackOrphanCountByLogger } from '../actions/logger';

class Vehicles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'vehicles'
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount() {
    // this.props.dispatch(fetchTrackOrphanCountByLogger());
  }

  async toggleTab(tab) {
    switch (tab) {
      case 'tracks':
        this.props.dispatch(fetchTracks({limit: 500}));
        break;

      default: break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="m-2"><PageTitle title="Vehicle/Logger Management" iconName="tractor" /></div>

        <Nav tabs>
          <FormTab
            caption="Vehicles"
            tabId="vehicles"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Loggers"
            tabId="loggers"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Message History"
            tabId="history"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
            disabled
          />
          <FormTab
            caption="Logger Tracks"
            tabId="tracks"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={this.state.activeTab} className="flex-fill">
          <TabPane tabId="vehicles" className="mb-2 p-1 flex-fill">
            <VehiclePanel />
          </TabPane>
          <TabPane tabId="loggers" className="mb-2 p-1 flex-fill">
            <LoggerPanel />
          </TabPane>
          <TabPane tabId="tracks" className="mb-2 p-1 flex-fill">
            <TracksPanel />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ vehicles, loggers }) => ({ vehicles, loggers });
export default connect(mapStoreToProps)(withContainerError(Vehicles));
